@import url("https://fonts.googleapis.com/css?family=Inter:900|Karla:400,700,500,800");
:root {
    --alto: #dddddd;
    --alto-2: #d9d9d9;
    --black: #000000;
    --blue-marguerite: #34e3a2;
    --deep-bronze: #463701;
    --eerie-black: #1a171a;
    --gray: #808080;
    --inchworm: #34e3a2;
    --inchworm-2: #89eb5b0d;
    --licorice: #151515;
    --porsche: #34e3a2;
    --shark: #1b2721;
    --shuttle-gray: #5e6b7e;
    --supernova: #34e3a2;
    --font-size-l: 24px;
    --font-size-m: 18px;
    --font-size-s: 16px;
    --font-size-xl: 32px;
    --font-size-xs: 14px;
    --font-size-xxl: 60px;
    --font-size-xxxl: 80px;
    --font-family-inter: "Inter", Helvetica;
    --font-family-karla: "Karla", Helvetica;
}

.inter-black-alto-32px {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 900;
}

.karla-normal-shuttle-gray-18px {
    color: var(--shuttle-gray);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.karla-normal-alto-18px {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.karla-extra-bold-alto-16px {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 800;
}

.karla-extra-bold-alto-14px {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 800;
}

.inter-black-alto-60px {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 900;
}

.karla-normal-alto-24px {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
}

.inter-black-white-32px {
    font-family: var(--font-family-karla);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 900;
}

.karla-normal-gray-24px {
    color: var(--gray);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
}
.ki8mm30 {
  display: flex;
}
.ki8mm31 {
  width: 100%;
  margin-bottom: 0;
}
.ki8mm32 {
  max-width: 100%;
}
.ki8mm33 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 10px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
}
.ki8mm34 {
  border-radius: 12px;
}
.ki8mm35 {
  background: #7645D9;
  opacity: 0.5;
}
.ki8mm36 {
  width: 50px;
  height: 50px;
  border-radius: 12px;
}
@media screen and (min-width: 852px) {
  .ki8mm31 {
    height: 490px;
  }
}
@media screen and (min-width: 576px) {
  .ki8mm32 {
    max-width: 360px;
  }
  .ki8mm33 {
    row-gap: 24px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 968px) {
  .ki8mm31 {
    width: 792px;
  }
  .ki8mm32 {
    max-width: 408px;
  }
  .ki8mm33 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
:root {
  --colors-light-white: white;
  --colors-light-failure: #ED4B9E;
  --colors-light-failure33: #ED4B9E33;
  --colors-light-primary: #34e3a2;
  --colors-light-primary0f: #1FC7D40f;
  --colors-light-primary3D: #1FC7D43D;
  --colors-light-primaryBright: #53DEE9;
  --colors-light-primaryDark: #0098A1;
  --colors-light-success: #31D0AA;
  --colors-light-success19: #31D0AA19;
  --colors-light-warning: #e3bc34;
  --colors-light-warning2D: #ED4B9E2D;
  --colors-light-warning33: #ED4B9E33;
  --colors-light-linear: linear-gradient(90deg, #09FF8D 0%, #79FE64 47%, #EDFE3A 100%);
  --colors-light-binance: #F0B90B;
  --colors-light-overlay: #452a7a;
  --colors-light-gold: #34e3a2;
  --colors-light-silver: #B2B2B2;
  --colors-light-bronze: #E7974D;
  --colors-light-secondary: #7645D9;
  --colors-light-secondary80: #7645D980;
  --colors-light-background: #edfaf1;
  --colors-light-backgroundDisabled: #E9EAEB;
  --colors-light-backgroundAlt: #FFFFFF;
  --colors-light-backgroundAlt2: rgba(255, 255, 255, 0.7);
  --colors-light-cardBorder: #E7E3EB;
  --colors-light-contrast: #191326;
  --colors-light-dropdown: #F6F6F6;
  --colors-light-dropdownDeep: #EEEEEE;
  --colors-light-invertedContrast: #FFFFFF;
  --colors-light-input: #e7faf0;
  --colors-light-inputSecondary: #d7caec;
  --colors-light-tertiary: #EFF4F5;
  --colors-light-text: #3E3E3E;
  --colors-light-text99: #a0a6a499;
  --colors-light-textDisabled: #BDC2C4;
  --colors-light-textSubtle: #34e3a2;
  --colors-light-disabled: #E9EAEB;
  --colors-light-gradientBubblegum: linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%);
  --colors-light-gradientInverseBubblegum: linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%);
  --colors-light-gradientCardHeader: linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%);
  --colors-light-gradientBlue: linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%);
  --colors-light-gradientViolet: linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%);
  --colors-light-gradientVioletAlt: linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%);
  --colors-light-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-light-backgroundImage: url(/img/Group11.png);
  --colors-light-backgroundBtn: #E7E7E7;
  --colors-light-textcard: #FFFFFF;
  --colors-light-bgrcard: rgb(254 255 255);
  --colors-dark-white: white;
  --colors-dark-failure: #ED4B9E;
  --colors-dark-failure33: #ED4B9E33;
  --colors-dark-primary: #34e3a2;
  --colors-dark-primary0f: #1FC7D40f;
  --colors-dark-primary3D: #1FC7D43D;
  --colors-dark-primaryBright: #53DEE9;
  --colors-dark-primaryDark: #0098A1;
  --colors-dark-success: #31D0AA;
  --colors-dark-success19: #31D0AA19;
  --colors-dark-warning: #e3bc34;
  --colors-dark-warning2D: #ED4B9E2D;
  --colors-dark-warning33: #ED4B9E33;
  --colors-dark-linear: linear-gradient(90deg, #09FF8D 0%, #79FE64 47%, #EDFE3A 100%);
  --colors-dark-binance: #F0B90B;
  --colors-dark-overlay: #452a7a;
  --colors-dark-gold: #34e3a2;
  --colors-dark-silver: #B2B2B2;
  --colors-dark-bronze: #E7974D;
  --colors-dark-secondary: #EDFE3A;
  --colors-dark-secondary80: #9A6AFF80;
  --colors-dark-background: #000000;
  --colors-dark-backgroundDisabled: #3c3742;
  --colors-dark-backgroundAlt: #27262c;
  --colors-dark-backgroundAlt2: rgba(39, 38, 44, 0.7);
  --colors-dark-cardBorder: #383241;
  --colors-dark-contrast: #FFFFFF;
  --colors-dark-dropdown: #1E1D20;
  --colors-dark-dropdownDeep: #100C18;
  --colors-dark-invertedContrast: #191326;
  --colors-dark-input: #01190e;
  --colors-dark-inputSecondary: #262130;
  --colors-dark-tertiary: #353547;
  --colors-dark-text: #F4EEFF;
  --colors-dark-text99: #F4EEFF99;
  --colors-dark-textDisabled: #666171;
  --colors-dark-textSubtle: #B8ADD2;
  --colors-dark-disabled: #524B63;
  --colors-dark-gradientBubblegum: linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%);
  --colors-dark-gradientInverseBubblegum: linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%);
  --colors-dark-gradientCardHeader: linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%);
  --colors-dark-gradientBlue: linear-gradient(180deg, #00707F 0%, #19778C 100%);
  --colors-dark-gradientViolet: linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%);
  --colors-dark-gradientVioletAlt: linear-gradient(180deg, #434575 0%, #66578D 100%);
  --colors-dark-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-dark-backgroundImage: url(/img/Group.png);
  --colors-dark-backgroundBtn: linear-gradient(90deg, #09FF8D 0%, #79FE64 47%, #EDFE3A 100%);
  --colors-dark-textcard: #3E3E3E;
  --colors-dark-bgrcard: #0B2029;
  --fonts-normal: 'Karla', Helvetica;
  --fonts-mono: SFMono, ui-monospace, monospace;
  --space-0: 0px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 16px;
  --space-4: 24px;
  --space-5: 32px;
  --space-6: 48px;
  --space-7: 64px;
  --space-px: 1px;
  --space-4px: 4px;
  --space-8px: 8px;
  --space-12px: 12px;
  --space-16px: 16px;
  --space-24px: 24px;
  --space-32px: 32px;
  --space-48px: 48px;
  --space-56px: 56px;
  --space-64px: 64px;
  --borderWidths-0: 0px;
  --borderWidths-1: 1px;
  --borderWidths-2: 2px;
  --radii-0: 0px;
  --radii-small: 4px;
  --radii-default: 16px;
  --radii-card: 24px;
  --radii-circle: 50%;
  --fontSizes-10px: 10px;
  --fontSizes-12px: 12px;
  --fontSizes-16px: 16px;
  --fontSizes-14px: 14px;
  --fontSizes-20px: 20px;
  --fontSizes-40px: 40px;
  --shadows-level1: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  --shadows-active: 0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4);
  --shadows-success: 0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2);
  --shadows-warning: 0px 0px 0px 1px #ED4B9E, 0px 0px 0px 4px rgba(237, 75, 158, 0.2);
  --shadows-focus: 0px 0px 0px 1px #34e3a2, 0px 0px 0px 4px rgba(52, 227, 162, 0.6);
  --shadows-inset: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
  --shadows-tooltip: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 4px 12px -8px rgba(14, 14, 44, 0.1);
}
[data-theme="light"] {
  --colors-white: white;
  --colors-failure: #ED4B9E;
  --colors-failure33: #ED4B9E33;
  --colors-primary: #34e3a2;
  --colors-primary0f: #1FC7D40f;
  --colors-primary3D: #1FC7D43D;
  --colors-primaryBright: #53DEE9;
  --colors-primaryDark: #0098A1;
  --colors-success: #31D0AA;
  --colors-success19: #31D0AA19;
  --colors-warning: #e3bc34;
  --colors-warning2D: #ED4B9E2D;
  --colors-warning33: #ED4B9E33;
  --colors-linear: linear-gradient(90deg, #09FF8D 0%, #79FE64 47%, #EDFE3A 100%);
  --colors-binance: #F0B90B;
  --colors-overlay: #452a7a;
  --colors-gold: #34e3a2;
  --colors-silver: #B2B2B2;
  --colors-bronze: #E7974D;
  --colors-secondary: #7645D9;
  --colors-secondary80: #7645D980;
  --colors-background: #edfaf1;
  --colors-backgroundDisabled: #E9EAEB;
  --colors-backgroundAlt: #FFFFFF;
  --colors-backgroundAlt2: rgba(255, 255, 255, 0.7);
  --colors-cardBorder: #E7E3EB;
  --colors-contrast: #191326;
  --colors-dropdown: #F6F6F6;
  --colors-dropdownDeep: #EEEEEE;
  --colors-invertedContrast: #FFFFFF;
  --colors-input: #e7faf0;
  --colors-inputSecondary: #d7caec;
  --colors-tertiary: #EFF4F5;
  --colors-text: #3E3E3E;
  --colors-text99: #a0a6a499;
  --colors-textDisabled: #BDC2C4;
  --colors-textSubtle: #34e3a2;
  --colors-disabled: #E9EAEB;
  --colors-gradientBubblegum: linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%);
  --colors-gradientInverseBubblegum: linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%);
  --colors-gradientCardHeader: linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%);
  --colors-gradientBlue: linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%);
  --colors-gradientViolet: linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%);
  --colors-gradientVioletAlt: linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%);
  --colors-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-backgroundImage: url(/img/Group11.png);
  --colors-backgroundBtn: #E7E7E7;
  --colors-textcard: #FFFFFF;
  --colors-bgrcard: rgb(254 255 255);
}
[data-theme="dark"] {
  --colors-white: white;
  --colors-failure: #ED4B9E;
  --colors-failure33: #ED4B9E33;
  --colors-primary: #34e3a2;
  --colors-primary0f: #1FC7D40f;
  --colors-primary3D: #1FC7D43D;
  --colors-primaryBright: #53DEE9;
  --colors-primaryDark: #0098A1;
  --colors-success: #31D0AA;
  --colors-success19: #31D0AA19;
  --colors-warning: #e3bc34;
  --colors-warning2D: #ED4B9E2D;
  --colors-warning33: #ED4B9E33;
  --colors-linear: linear-gradient(90deg, #09FF8D 0%, #79FE64 47%, #EDFE3A 100%);
  --colors-binance: #F0B90B;
  --colors-overlay: #452a7a;
  --colors-gold: #34e3a2;
  --colors-silver: #B2B2B2;
  --colors-bronze: #E7974D;
  --colors-secondary: #EDFE3A;
  --colors-secondary80: #9A6AFF80;
  --colors-background: #000000;
  --colors-backgroundDisabled: #3c3742;
  --colors-backgroundAlt: #27262c;
  --colors-backgroundAlt2: rgba(39, 38, 44, 0.7);
  --colors-cardBorder: #383241;
  --colors-contrast: #FFFFFF;
  --colors-dropdown: #1E1D20;
  --colors-dropdownDeep: #100C18;
  --colors-invertedContrast: #191326;
  --colors-input: #01190e;
  --colors-inputSecondary: #262130;
  --colors-tertiary: #353547;
  --colors-text: #F4EEFF;
  --colors-text99: #F4EEFF99;
  --colors-textDisabled: #666171;
  --colors-textSubtle: #B8ADD2;
  --colors-disabled: #524B63;
  --colors-gradientBubblegum: linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%);
  --colors-gradientInverseBubblegum: linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%);
  --colors-gradientCardHeader: linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%);
  --colors-gradientBlue: linear-gradient(180deg, #00707F 0%, #19778C 100%);
  --colors-gradientViolet: linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%);
  --colors-gradientVioletAlt: linear-gradient(180deg, #434575 0%, #66578D 100%);
  --colors-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-backgroundImage: url(/img/Group.png);
  --colors-backgroundBtn: linear-gradient(90deg, #09FF8D 0%, #79FE64 47%, #EDFE3A 100%);
  --colors-textcard: #3E3E3E;
  --colors-bgrcard: #0B2029;
}
.dqxueu0 {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.dqxueu1 {
  background: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dqxueu2 {
  display: block;
}
.dqxueu3 {
  line-height: 1;
}
.dqxueu4 {
  list-style: none;
}
.dqxueu5 {
  quotes: none;
}
.dqxueu5:before, .dqxueu5:after {
  content: '';
}
.dqxueu6 {
  border-collapse: collapse;
  border-spacing: 0;
}
.dqxueu7 {
  background-color: transparent;
  color: inherit;
}
.dqxueu8 {
  -webkit-appearance: none;
          appearance: none;
}
.dqxueu8::-ms-expand {
  display: none;
}
._1sbkoz60 {
  display: block;
}
._1sbkoz66 {
  display: flex;
}
._1sbkoz6c {
  display: grid;
}
._1sbkoz6i {
  display: inline;
}
._1sbkoz6o {
  display: inline-flex;
}
._1sbkoz6u {
  display: inline-block;
}
._1sbkoz610 {
  display: none;
}
._1sbkoz616 {
  display: contents;
}
._1sbkoz61c {
  flex-direction: column;
}
._1sbkoz61i {
  flex-direction: row;
}
._1sbkoz61o {
  flex-direction: column-reverse;
}
._1sbkoz61u {
  align-items: center;
}
._1sbkoz620 {
  align-items: end;
}
._1sbkoz626 {
  align-items: baseLine;
}
._1sbkoz62c {
  align-items: inherit;
}
._1sbkoz62i {
  align-items: flex-start;
}
._1sbkoz62o {
  align-items: center;
}
._1sbkoz62u {
  align-items: start;
}
._1sbkoz630 {
  align-items: flex-end;
}
._1sbkoz636 {
  align-items: stretch;
}
._1sbkoz63c {
  flex-wrap: wrap;
}
._1sbkoz63i {
  flex-wrap: nowrap;
}
._1sbkoz63o {
  overflow: auto;
}
._1sbkoz63u {
  overflow: hidden;
}
._1sbkoz640 {
  overflow: scroll;
}
._1sbkoz646 {
  overflow: unset;
}
._1sbkoz64c {
  overflow-y: auto;
}
._1sbkoz64i {
  overflow-y: hidden;
}
._1sbkoz64o {
  overflow-y: scroll;
}
._1sbkoz64u {
  overflow-x: auto;
}
._1sbkoz650 {
  overflow-x: hidden;
}
._1sbkoz656 {
  overflow-x: scroll;
}
._1sbkoz65c {
  position: absolute;
}
._1sbkoz65i {
  position: fixed;
}
._1sbkoz65o {
  position: relative;
}
._1sbkoz65u {
  position: -webkit-sticky;
  position: sticky;
}
._1sbkoz660 {
  text-align: center;
}
._1sbkoz666 {
  text-align: left;
}
._1sbkoz66c {
  text-align: right;
}
._1sbkoz66i {
  justify-content: flex-start;
}
._1sbkoz66o {
  justify-content: center;
}
._1sbkoz66u {
  justify-content: start;
}
._1sbkoz670 {
  justify-content: flex-end;
}
._1sbkoz676 {
  justify-content: stretch;
}
._1sbkoz67c {
  justify-content: space-around;
}
._1sbkoz67i {
  justify-content: space-between;
}
._1sbkoz67o {
  justify-items: flex-start;
}
._1sbkoz67u {
  justify-items: center;
}
._1sbkoz680 {
  justify-items: start;
}
._1sbkoz686 {
  justify-items: flex-end;
}
._1sbkoz68c {
  justify-items: stretch;
}
._1sbkoz68i {
  justify-self: flex-start;
}
._1sbkoz68o {
  justify-self: center;
}
._1sbkoz68u {
  justify-self: start;
}
._1sbkoz690 {
  justify-self: flex-end;
}
._1sbkoz696 {
  justify-self: stretch;
}
._1sbkoz69c {
  inset: var(--space-0);
}
._1sbkoz69i {
  inset: var(--space-1);
}
._1sbkoz69o {
  inset: var(--space-2);
}
._1sbkoz69u {
  inset: var(--space-3);
}
._1sbkoz6a0 {
  inset: var(--space-4);
}
._1sbkoz6a6 {
  inset: var(--space-5);
}
._1sbkoz6ac {
  inset: var(--space-6);
}
._1sbkoz6ai {
  inset: var(--space-7);
}
._1sbkoz6ao {
  inset: var(--space-px);
}
._1sbkoz6au {
  inset: var(--space-4px);
}
._1sbkoz6b0 {
  inset: var(--space-8px);
}
._1sbkoz6b6 {
  inset: var(--space-12px);
}
._1sbkoz6bc {
  inset: var(--space-16px);
}
._1sbkoz6bi {
  inset: var(--space-24px);
}
._1sbkoz6bo {
  inset: var(--space-32px);
}
._1sbkoz6bu {
  inset: var(--space-48px);
}
._1sbkoz6c0 {
  inset: var(--space-56px);
}
._1sbkoz6c6 {
  inset: var(--space-64px);
}
._1sbkoz6cc {
  inset: calc(var(--space-px) * -1);
}
._1sbkoz6ci {
  inset: calc(var(--space-1) * -1);
}
._1sbkoz6co {
  inset: calc(var(--space-2) * -1);
}
._1sbkoz6cu {
  inset: calc(var(--space-3) * -1);
}
._1sbkoz6d0 {
  inset: calc(var(--space-4) * -1);
}
._1sbkoz6d6 {
  inset: calc(var(--space-5) * -1);
}
._1sbkoz6dc {
  inset: calc(var(--space-6) * -1);
}
._1sbkoz6di {
  inset: calc(var(--space-7) * -1);
}
._1sbkoz6do {
  height: var(--space-0);
}
._1sbkoz6du {
  height: var(--space-1);
}
._1sbkoz6e0 {
  height: var(--space-2);
}
._1sbkoz6e6 {
  height: var(--space-3);
}
._1sbkoz6ec {
  height: var(--space-4);
}
._1sbkoz6ei {
  height: var(--space-5);
}
._1sbkoz6eo {
  height: var(--space-6);
}
._1sbkoz6eu {
  height: var(--space-7);
}
._1sbkoz6f0 {
  height: var(--space-px);
}
._1sbkoz6f6 {
  height: var(--space-4px);
}
._1sbkoz6fc {
  height: var(--space-8px);
}
._1sbkoz6fi {
  height: var(--space-12px);
}
._1sbkoz6fo {
  height: var(--space-16px);
}
._1sbkoz6fu {
  height: var(--space-24px);
}
._1sbkoz6g0 {
  height: var(--space-32px);
}
._1sbkoz6g6 {
  height: var(--space-48px);
}
._1sbkoz6gc {
  height: var(--space-56px);
}
._1sbkoz6gi {
  height: var(--space-64px);
}
._1sbkoz6go {
  height: 100%;
}
._1sbkoz6gu {
  height: 100%;
}
._1sbkoz6h0 {
  height: auto;
}
._1sbkoz6h6 {
  height: 576px;
}
._1sbkoz6hc {
  height: 852px;
}
._1sbkoz6hi {
  height: 968px;
}
._1sbkoz6ho {
  height: 1080px;
}
._1sbkoz6hu {
  left: var(--space-0);
}
._1sbkoz6i0 {
  left: var(--space-1);
}
._1sbkoz6i6 {
  left: var(--space-2);
}
._1sbkoz6ic {
  left: var(--space-3);
}
._1sbkoz6ii {
  left: var(--space-4);
}
._1sbkoz6io {
  left: var(--space-5);
}
._1sbkoz6iu {
  left: var(--space-6);
}
._1sbkoz6j0 {
  left: var(--space-7);
}
._1sbkoz6j6 {
  left: var(--space-px);
}
._1sbkoz6jc {
  left: var(--space-4px);
}
._1sbkoz6ji {
  left: var(--space-8px);
}
._1sbkoz6jo {
  left: var(--space-12px);
}
._1sbkoz6ju {
  left: var(--space-16px);
}
._1sbkoz6k0 {
  left: var(--space-24px);
}
._1sbkoz6k6 {
  left: var(--space-32px);
}
._1sbkoz6kc {
  left: var(--space-48px);
}
._1sbkoz6ki {
  left: var(--space-56px);
}
._1sbkoz6ko {
  left: var(--space-64px);
}
._1sbkoz6ku {
  left: calc(var(--space-px) * -1);
}
._1sbkoz6l0 {
  left: calc(var(--space-1) * -1);
}
._1sbkoz6l6 {
  left: calc(var(--space-2) * -1);
}
._1sbkoz6lc {
  left: calc(var(--space-3) * -1);
}
._1sbkoz6li {
  left: calc(var(--space-4) * -1);
}
._1sbkoz6lo {
  left: calc(var(--space-5) * -1);
}
._1sbkoz6lu {
  left: calc(var(--space-6) * -1);
}
._1sbkoz6m0 {
  left: calc(var(--space-7) * -1);
}
._1sbkoz6m6 {
  margin-bottom: var(--space-0);
}
._1sbkoz6mc {
  margin-bottom: var(--space-1);
}
._1sbkoz6mi {
  margin-bottom: var(--space-2);
}
._1sbkoz6mo {
  margin-bottom: var(--space-3);
}
._1sbkoz6mu {
  margin-bottom: var(--space-4);
}
._1sbkoz6n0 {
  margin-bottom: var(--space-5);
}
._1sbkoz6n6 {
  margin-bottom: var(--space-6);
}
._1sbkoz6nc {
  margin-bottom: var(--space-7);
}
._1sbkoz6ni {
  margin-bottom: var(--space-px);
}
._1sbkoz6no {
  margin-bottom: var(--space-4px);
}
._1sbkoz6nu {
  margin-bottom: var(--space-8px);
}
._1sbkoz6o0 {
  margin-bottom: var(--space-12px);
}
._1sbkoz6o6 {
  margin-bottom: var(--space-16px);
}
._1sbkoz6oc {
  margin-bottom: var(--space-24px);
}
._1sbkoz6oi {
  margin-bottom: var(--space-32px);
}
._1sbkoz6oo {
  margin-bottom: var(--space-48px);
}
._1sbkoz6ou {
  margin-bottom: var(--space-56px);
}
._1sbkoz6p0 {
  margin-bottom: var(--space-64px);
}
._1sbkoz6p6 {
  margin-bottom: auto;
}
._1sbkoz6pc {
  margin-bottom: calc(var(--space-px) * -1);
}
._1sbkoz6pi {
  margin-bottom: calc(var(--space-1) * -1);
}
._1sbkoz6po {
  margin-bottom: calc(var(--space-2) * -1);
}
._1sbkoz6pu {
  margin-bottom: calc(var(--space-3) * -1);
}
._1sbkoz6q0 {
  margin-bottom: calc(var(--space-4) * -1);
}
._1sbkoz6q6 {
  margin-bottom: calc(var(--space-5) * -1);
}
._1sbkoz6qc {
  margin-bottom: calc(var(--space-6) * -1);
}
._1sbkoz6qi {
  margin-bottom: calc(var(--space-7) * -1);
}
._1sbkoz6qo {
  margin-left: var(--space-0);
}
._1sbkoz6qu {
  margin-left: var(--space-1);
}
._1sbkoz6r0 {
  margin-left: var(--space-2);
}
._1sbkoz6r6 {
  margin-left: var(--space-3);
}
._1sbkoz6rc {
  margin-left: var(--space-4);
}
._1sbkoz6ri {
  margin-left: var(--space-5);
}
._1sbkoz6ro {
  margin-left: var(--space-6);
}
._1sbkoz6ru {
  margin-left: var(--space-7);
}
._1sbkoz6s0 {
  margin-left: var(--space-px);
}
._1sbkoz6s6 {
  margin-left: var(--space-4px);
}
._1sbkoz6sc {
  margin-left: var(--space-8px);
}
._1sbkoz6si {
  margin-left: var(--space-12px);
}
._1sbkoz6so {
  margin-left: var(--space-16px);
}
._1sbkoz6su {
  margin-left: var(--space-24px);
}
._1sbkoz6t0 {
  margin-left: var(--space-32px);
}
._1sbkoz6t6 {
  margin-left: var(--space-48px);
}
._1sbkoz6tc {
  margin-left: var(--space-56px);
}
._1sbkoz6ti {
  margin-left: var(--space-64px);
}
._1sbkoz6to {
  margin-left: auto;
}
._1sbkoz6tu {
  margin-left: calc(var(--space-px) * -1);
}
._1sbkoz6u0 {
  margin-left: calc(var(--space-1) * -1);
}
._1sbkoz6u6 {
  margin-left: calc(var(--space-2) * -1);
}
._1sbkoz6uc {
  margin-left: calc(var(--space-3) * -1);
}
._1sbkoz6ui {
  margin-left: calc(var(--space-4) * -1);
}
._1sbkoz6uo {
  margin-left: calc(var(--space-5) * -1);
}
._1sbkoz6uu {
  margin-left: calc(var(--space-6) * -1);
}
._1sbkoz6v0 {
  margin-left: calc(var(--space-7) * -1);
}
._1sbkoz6v6 {
  margin-right: var(--space-0);
}
._1sbkoz6vc {
  margin-right: var(--space-1);
}
._1sbkoz6vi {
  margin-right: var(--space-2);
}
._1sbkoz6vo {
  margin-right: var(--space-3);
}
._1sbkoz6vu {
  margin-right: var(--space-4);
}
._1sbkoz6w0 {
  margin-right: var(--space-5);
}
._1sbkoz6w6 {
  margin-right: var(--space-6);
}
._1sbkoz6wc {
  margin-right: var(--space-7);
}
._1sbkoz6wi {
  margin-right: var(--space-px);
}
._1sbkoz6wo {
  margin-right: var(--space-4px);
}
._1sbkoz6wu {
  margin-right: var(--space-8px);
}
._1sbkoz6x0 {
  margin-right: var(--space-12px);
}
._1sbkoz6x6 {
  margin-right: var(--space-16px);
}
._1sbkoz6xc {
  margin-right: var(--space-24px);
}
._1sbkoz6xi {
  margin-right: var(--space-32px);
}
._1sbkoz6xo {
  margin-right: var(--space-48px);
}
._1sbkoz6xu {
  margin-right: var(--space-56px);
}
._1sbkoz6y0 {
  margin-right: var(--space-64px);
}
._1sbkoz6y6 {
  margin-right: auto;
}
._1sbkoz6yc {
  margin-right: calc(var(--space-px) * -1);
}
._1sbkoz6yi {
  margin-right: calc(var(--space-1) * -1);
}
._1sbkoz6yo {
  margin-right: calc(var(--space-2) * -1);
}
._1sbkoz6yu {
  margin-right: calc(var(--space-3) * -1);
}
._1sbkoz6z0 {
  margin-right: calc(var(--space-4) * -1);
}
._1sbkoz6z6 {
  margin-right: calc(var(--space-5) * -1);
}
._1sbkoz6zc {
  margin-right: calc(var(--space-6) * -1);
}
._1sbkoz6zi {
  margin-right: calc(var(--space-7) * -1);
}
._1sbkoz6zo {
  margin-top: var(--space-0);
}
._1sbkoz6zu {
  margin-top: var(--space-1);
}
._1sbkoz6100 {
  margin-top: var(--space-2);
}
._1sbkoz6106 {
  margin-top: var(--space-3);
}
._1sbkoz610c {
  margin-top: var(--space-4);
}
._1sbkoz610i {
  margin-top: var(--space-5);
}
._1sbkoz610o {
  margin-top: var(--space-6);
}
._1sbkoz610u {
  margin-top: var(--space-7);
}
._1sbkoz6110 {
  margin-top: var(--space-px);
}
._1sbkoz6116 {
  margin-top: var(--space-4px);
}
._1sbkoz611c {
  margin-top: var(--space-8px);
}
._1sbkoz611i {
  margin-top: var(--space-12px);
}
._1sbkoz611o {
  margin-top: var(--space-16px);
}
._1sbkoz611u {
  margin-top: var(--space-24px);
}
._1sbkoz6120 {
  margin-top: var(--space-32px);
}
._1sbkoz6126 {
  margin-top: var(--space-48px);
}
._1sbkoz612c {
  margin-top: var(--space-56px);
}
._1sbkoz612i {
  margin-top: var(--space-64px);
}
._1sbkoz612o {
  margin-top: auto;
}
._1sbkoz612u {
  margin-top: calc(var(--space-px) * -1);
}
._1sbkoz6130 {
  margin-top: calc(var(--space-1) * -1);
}
._1sbkoz6136 {
  margin-top: calc(var(--space-2) * -1);
}
._1sbkoz613c {
  margin-top: calc(var(--space-3) * -1);
}
._1sbkoz613i {
  margin-top: calc(var(--space-4) * -1);
}
._1sbkoz613o {
  margin-top: calc(var(--space-5) * -1);
}
._1sbkoz613u {
  margin-top: calc(var(--space-6) * -1);
}
._1sbkoz6140 {
  margin-top: calc(var(--space-7) * -1);
}
._1sbkoz6146 {
  margin: var(--space-0);
}
._1sbkoz614c {
  margin: var(--space-1);
}
._1sbkoz614i {
  margin: var(--space-2);
}
._1sbkoz614o {
  margin: var(--space-3);
}
._1sbkoz614u {
  margin: var(--space-4);
}
._1sbkoz6150 {
  margin: var(--space-5);
}
._1sbkoz6156 {
  margin: var(--space-6);
}
._1sbkoz615c {
  margin: var(--space-7);
}
._1sbkoz615i {
  margin: var(--space-px);
}
._1sbkoz615o {
  margin: var(--space-4px);
}
._1sbkoz615u {
  margin: var(--space-8px);
}
._1sbkoz6160 {
  margin: var(--space-12px);
}
._1sbkoz6166 {
  margin: var(--space-16px);
}
._1sbkoz616c {
  margin: var(--space-24px);
}
._1sbkoz616i {
  margin: var(--space-32px);
}
._1sbkoz616o {
  margin: var(--space-48px);
}
._1sbkoz616u {
  margin: var(--space-56px);
}
._1sbkoz6170 {
  margin: var(--space-64px);
}
._1sbkoz6176 {
  margin: auto;
}
._1sbkoz617c {
  margin: calc(var(--space-px) * -1);
}
._1sbkoz617i {
  margin: calc(var(--space-1) * -1);
}
._1sbkoz617o {
  margin: calc(var(--space-2) * -1);
}
._1sbkoz617u {
  margin: calc(var(--space-3) * -1);
}
._1sbkoz6180 {
  margin: calc(var(--space-4) * -1);
}
._1sbkoz6186 {
  margin: calc(var(--space-5) * -1);
}
._1sbkoz618c {
  margin: calc(var(--space-6) * -1);
}
._1sbkoz618i {
  margin: calc(var(--space-7) * -1);
}
._1sbkoz618o {
  padding: var(--space-0);
}
._1sbkoz618u {
  padding: var(--space-1);
}
._1sbkoz6190 {
  padding: var(--space-2);
}
._1sbkoz6196 {
  padding: var(--space-3);
}
._1sbkoz619c {
  padding: var(--space-4);
}
._1sbkoz619i {
  padding: var(--space-5);
}
._1sbkoz619o {
  padding: var(--space-6);
}
._1sbkoz619u {
  padding: var(--space-7);
}
._1sbkoz61a0 {
  padding: var(--space-px);
}
._1sbkoz61a6 {
  padding: var(--space-4px);
}
._1sbkoz61ac {
  padding: var(--space-8px);
}
._1sbkoz61ai {
  padding: var(--space-12px);
}
._1sbkoz61ao {
  padding: var(--space-16px);
}
._1sbkoz61au {
  padding: var(--space-24px);
}
._1sbkoz61b0 {
  padding: var(--space-32px);
}
._1sbkoz61b6 {
  padding: var(--space-48px);
}
._1sbkoz61bc {
  padding: var(--space-56px);
}
._1sbkoz61bi {
  padding: var(--space-64px);
}
._1sbkoz61bo {
  padding: auto;
}
._1sbkoz61bu {
  padding: calc(var(--space-px) * -1);
}
._1sbkoz61c0 {
  padding: calc(var(--space-1) * -1);
}
._1sbkoz61c6 {
  padding: calc(var(--space-2) * -1);
}
._1sbkoz61cc {
  padding: calc(var(--space-3) * -1);
}
._1sbkoz61ci {
  padding: calc(var(--space-4) * -1);
}
._1sbkoz61co {
  padding: calc(var(--space-5) * -1);
}
._1sbkoz61cu {
  padding: calc(var(--space-6) * -1);
}
._1sbkoz61d0 {
  padding: calc(var(--space-7) * -1);
}
._1sbkoz61d6 {
  max-height: var(--space-0);
}
._1sbkoz61dc {
  max-height: var(--space-1);
}
._1sbkoz61di {
  max-height: var(--space-2);
}
._1sbkoz61do {
  max-height: var(--space-3);
}
._1sbkoz61du {
  max-height: var(--space-4);
}
._1sbkoz61e0 {
  max-height: var(--space-5);
}
._1sbkoz61e6 {
  max-height: var(--space-6);
}
._1sbkoz61ec {
  max-height: var(--space-7);
}
._1sbkoz61ei {
  max-height: var(--space-px);
}
._1sbkoz61eo {
  max-height: var(--space-4px);
}
._1sbkoz61eu {
  max-height: var(--space-8px);
}
._1sbkoz61f0 {
  max-height: var(--space-12px);
}
._1sbkoz61f6 {
  max-height: var(--space-16px);
}
._1sbkoz61fc {
  max-height: var(--space-24px);
}
._1sbkoz61fi {
  max-height: var(--space-32px);
}
._1sbkoz61fo {
  max-height: var(--space-48px);
}
._1sbkoz61fu {
  max-height: var(--space-56px);
}
._1sbkoz61g0 {
  max-height: var(--space-64px);
}
._1sbkoz61g6 {
  max-width: var(--space-0);
}
._1sbkoz61gc {
  max-width: var(--space-1);
}
._1sbkoz61gi {
  max-width: var(--space-2);
}
._1sbkoz61go {
  max-width: var(--space-3);
}
._1sbkoz61gu {
  max-width: var(--space-4);
}
._1sbkoz61h0 {
  max-width: var(--space-5);
}
._1sbkoz61h6 {
  max-width: var(--space-6);
}
._1sbkoz61hc {
  max-width: var(--space-7);
}
._1sbkoz61hi {
  max-width: var(--space-px);
}
._1sbkoz61ho {
  max-width: var(--space-4px);
}
._1sbkoz61hu {
  max-width: var(--space-8px);
}
._1sbkoz61i0 {
  max-width: var(--space-12px);
}
._1sbkoz61i6 {
  max-width: var(--space-16px);
}
._1sbkoz61ic {
  max-width: var(--space-24px);
}
._1sbkoz61ii {
  max-width: var(--space-32px);
}
._1sbkoz61io {
  max-width: var(--space-48px);
}
._1sbkoz61iu {
  max-width: var(--space-56px);
}
._1sbkoz61j0 {
  max-width: var(--space-64px);
}
._1sbkoz61j6 {
  max-width: 100%;
}
._1sbkoz61jc {
  max-width: 100%;
}
._1sbkoz61ji {
  max-width: auto;
}
._1sbkoz61jo {
  max-width: 576px;
}
._1sbkoz61ju {
  max-width: 852px;
}
._1sbkoz61k0 {
  max-width: 968px;
}
._1sbkoz61k6 {
  max-width: 1080px;
}
._1sbkoz61kc {
  max-width: none;
}
._1sbkoz61ki {
  min-height: var(--space-0);
}
._1sbkoz61ko {
  min-height: var(--space-1);
}
._1sbkoz61ku {
  min-height: var(--space-2);
}
._1sbkoz61l0 {
  min-height: var(--space-3);
}
._1sbkoz61l6 {
  min-height: var(--space-4);
}
._1sbkoz61lc {
  min-height: var(--space-5);
}
._1sbkoz61li {
  min-height: var(--space-6);
}
._1sbkoz61lo {
  min-height: var(--space-7);
}
._1sbkoz61lu {
  min-height: var(--space-px);
}
._1sbkoz61m0 {
  min-height: var(--space-4px);
}
._1sbkoz61m6 {
  min-height: var(--space-8px);
}
._1sbkoz61mc {
  min-height: var(--space-12px);
}
._1sbkoz61mi {
  min-height: var(--space-16px);
}
._1sbkoz61mo {
  min-height: var(--space-24px);
}
._1sbkoz61mu {
  min-height: var(--space-32px);
}
._1sbkoz61n0 {
  min-height: var(--space-48px);
}
._1sbkoz61n6 {
  min-height: var(--space-56px);
}
._1sbkoz61nc {
  min-height: var(--space-64px);
}
._1sbkoz61ni {
  min-width: var(--space-0);
}
._1sbkoz61no {
  min-width: var(--space-1);
}
._1sbkoz61nu {
  min-width: var(--space-2);
}
._1sbkoz61o0 {
  min-width: var(--space-3);
}
._1sbkoz61o6 {
  min-width: var(--space-4);
}
._1sbkoz61oc {
  min-width: var(--space-5);
}
._1sbkoz61oi {
  min-width: var(--space-6);
}
._1sbkoz61oo {
  min-width: var(--space-7);
}
._1sbkoz61ou {
  min-width: var(--space-px);
}
._1sbkoz61p0 {
  min-width: var(--space-4px);
}
._1sbkoz61p6 {
  min-width: var(--space-8px);
}
._1sbkoz61pc {
  min-width: var(--space-12px);
}
._1sbkoz61pi {
  min-width: var(--space-16px);
}
._1sbkoz61po {
  min-width: var(--space-24px);
}
._1sbkoz61pu {
  min-width: var(--space-32px);
}
._1sbkoz61q0 {
  min-width: var(--space-48px);
}
._1sbkoz61q6 {
  min-width: var(--space-56px);
}
._1sbkoz61qc {
  min-width: var(--space-64px);
}
._1sbkoz61qi {
  padding-bottom: var(--space-0);
}
._1sbkoz61qo {
  padding-bottom: var(--space-1);
}
._1sbkoz61qu {
  padding-bottom: var(--space-2);
}
._1sbkoz61r0 {
  padding-bottom: var(--space-3);
}
._1sbkoz61r6 {
  padding-bottom: var(--space-4);
}
._1sbkoz61rc {
  padding-bottom: var(--space-5);
}
._1sbkoz61ri {
  padding-bottom: var(--space-6);
}
._1sbkoz61ro {
  padding-bottom: var(--space-7);
}
._1sbkoz61ru {
  padding-bottom: var(--space-px);
}
._1sbkoz61s0 {
  padding-bottom: var(--space-4px);
}
._1sbkoz61s6 {
  padding-bottom: var(--space-8px);
}
._1sbkoz61sc {
  padding-bottom: var(--space-12px);
}
._1sbkoz61si {
  padding-bottom: var(--space-16px);
}
._1sbkoz61so {
  padding-bottom: var(--space-24px);
}
._1sbkoz61su {
  padding-bottom: var(--space-32px);
}
._1sbkoz61t0 {
  padding-bottom: var(--space-48px);
}
._1sbkoz61t6 {
  padding-bottom: var(--space-56px);
}
._1sbkoz61tc {
  padding-bottom: var(--space-64px);
}
._1sbkoz61ti {
  padding-left: var(--space-0);
}
._1sbkoz61to {
  padding-left: var(--space-1);
}
._1sbkoz61tu {
  padding-left: var(--space-2);
}
._1sbkoz61u0 {
  padding-left: var(--space-3);
}
._1sbkoz61u6 {
  padding-left: var(--space-4);
}
._1sbkoz61uc {
  padding-left: var(--space-5);
}
._1sbkoz61ui {
  padding-left: var(--space-6);
}
._1sbkoz61uo {
  padding-left: var(--space-7);
}
._1sbkoz61uu {
  padding-left: var(--space-px);
}
._1sbkoz61v0 {
  padding-left: var(--space-4px);
}
._1sbkoz61v6 {
  padding-left: var(--space-8px);
}
._1sbkoz61vc {
  padding-left: var(--space-12px);
}
._1sbkoz61vi {
  padding-left: var(--space-16px);
}
._1sbkoz61vo {
  padding-left: var(--space-24px);
}
._1sbkoz61vu {
  padding-left: var(--space-32px);
}
._1sbkoz61w0 {
  padding-left: var(--space-48px);
}
._1sbkoz61w6 {
  padding-left: var(--space-56px);
}
._1sbkoz61wc {
  padding-left: var(--space-64px);
}
._1sbkoz61wi {
  padding-right: var(--space-0);
}
._1sbkoz61wo {
  padding-right: var(--space-1);
}
._1sbkoz61wu {
  padding-right: var(--space-2);
}
._1sbkoz61x0 {
  padding-right: var(--space-3);
}
._1sbkoz61x6 {
  padding-right: var(--space-4);
}
._1sbkoz61xc {
  padding-right: var(--space-5);
}
._1sbkoz61xi {
  padding-right: var(--space-6);
}
._1sbkoz61xo {
  padding-right: var(--space-7);
}
._1sbkoz61xu {
  padding-right: var(--space-px);
}
._1sbkoz61y0 {
  padding-right: var(--space-4px);
}
._1sbkoz61y6 {
  padding-right: var(--space-8px);
}
._1sbkoz61yc {
  padding-right: var(--space-12px);
}
._1sbkoz61yi {
  padding-right: var(--space-16px);
}
._1sbkoz61yo {
  padding-right: var(--space-24px);
}
._1sbkoz61yu {
  padding-right: var(--space-32px);
}
._1sbkoz61z0 {
  padding-right: var(--space-48px);
}
._1sbkoz61z6 {
  padding-right: var(--space-56px);
}
._1sbkoz61zc {
  padding-right: var(--space-64px);
}
._1sbkoz61zi {
  padding-top: var(--space-0);
}
._1sbkoz61zo {
  padding-top: var(--space-1);
}
._1sbkoz61zu {
  padding-top: var(--space-2);
}
._1sbkoz6200 {
  padding-top: var(--space-3);
}
._1sbkoz6206 {
  padding-top: var(--space-4);
}
._1sbkoz620c {
  padding-top: var(--space-5);
}
._1sbkoz620i {
  padding-top: var(--space-6);
}
._1sbkoz620o {
  padding-top: var(--space-7);
}
._1sbkoz620u {
  padding-top: var(--space-px);
}
._1sbkoz6210 {
  padding-top: var(--space-4px);
}
._1sbkoz6216 {
  padding-top: var(--space-8px);
}
._1sbkoz621c {
  padding-top: var(--space-12px);
}
._1sbkoz621i {
  padding-top: var(--space-16px);
}
._1sbkoz621o {
  padding-top: var(--space-24px);
}
._1sbkoz621u {
  padding-top: var(--space-32px);
}
._1sbkoz6220 {
  padding-top: var(--space-48px);
}
._1sbkoz6226 {
  padding-top: var(--space-56px);
}
._1sbkoz622c {
  padding-top: var(--space-64px);
}
._1sbkoz622i {
  font-size: var(--fontSizes-10px);
}
._1sbkoz622o {
  font-size: var(--fontSizes-12px);
}
._1sbkoz622u {
  font-size: var(--fontSizes-16px);
}
._1sbkoz6230 {
  font-size: var(--fontSizes-14px);
}
._1sbkoz6236 {
  font-size: var(--fontSizes-20px);
}
._1sbkoz623c {
  font-size: var(--fontSizes-40px);
}
._1sbkoz623i {
  font-size: inherit;
}
._1sbkoz623o {
  right: var(--space-0);
}
._1sbkoz623u {
  right: var(--space-1);
}
._1sbkoz6240 {
  right: var(--space-2);
}
._1sbkoz6246 {
  right: var(--space-3);
}
._1sbkoz624c {
  right: var(--space-4);
}
._1sbkoz624i {
  right: var(--space-5);
}
._1sbkoz624o {
  right: var(--space-6);
}
._1sbkoz624u {
  right: var(--space-7);
}
._1sbkoz6250 {
  right: var(--space-px);
}
._1sbkoz6256 {
  right: var(--space-4px);
}
._1sbkoz625c {
  right: var(--space-8px);
}
._1sbkoz625i {
  right: var(--space-12px);
}
._1sbkoz625o {
  right: var(--space-16px);
}
._1sbkoz625u {
  right: var(--space-24px);
}
._1sbkoz6260 {
  right: var(--space-32px);
}
._1sbkoz6266 {
  right: var(--space-48px);
}
._1sbkoz626c {
  right: var(--space-56px);
}
._1sbkoz626i {
  right: var(--space-64px);
}
._1sbkoz626o {
  right: calc(var(--space-px) * -1);
}
._1sbkoz626u {
  right: calc(var(--space-1) * -1);
}
._1sbkoz6270 {
  right: calc(var(--space-2) * -1);
}
._1sbkoz6276 {
  right: calc(var(--space-3) * -1);
}
._1sbkoz627c {
  right: calc(var(--space-4) * -1);
}
._1sbkoz627i {
  right: calc(var(--space-5) * -1);
}
._1sbkoz627o {
  right: calc(var(--space-6) * -1);
}
._1sbkoz627u {
  right: calc(var(--space-7) * -1);
}
._1sbkoz6280 {
  top: var(--space-0);
}
._1sbkoz6286 {
  top: var(--space-1);
}
._1sbkoz628c {
  top: var(--space-2);
}
._1sbkoz628i {
  top: var(--space-3);
}
._1sbkoz628o {
  top: var(--space-4);
}
._1sbkoz628u {
  top: var(--space-5);
}
._1sbkoz6290 {
  top: var(--space-6);
}
._1sbkoz6296 {
  top: var(--space-7);
}
._1sbkoz629c {
  top: var(--space-px);
}
._1sbkoz629i {
  top: var(--space-4px);
}
._1sbkoz629o {
  top: var(--space-8px);
}
._1sbkoz629u {
  top: var(--space-12px);
}
._1sbkoz62a0 {
  top: var(--space-16px);
}
._1sbkoz62a6 {
  top: var(--space-24px);
}
._1sbkoz62ac {
  top: var(--space-32px);
}
._1sbkoz62ai {
  top: var(--space-48px);
}
._1sbkoz62ao {
  top: var(--space-56px);
}
._1sbkoz62au {
  top: var(--space-64px);
}
._1sbkoz62b0 {
  top: calc(var(--space-px) * -1);
}
._1sbkoz62b6 {
  top: calc(var(--space-1) * -1);
}
._1sbkoz62bc {
  top: calc(var(--space-2) * -1);
}
._1sbkoz62bi {
  top: calc(var(--space-3) * -1);
}
._1sbkoz62bo {
  top: calc(var(--space-4) * -1);
}
._1sbkoz62bu {
  top: calc(var(--space-5) * -1);
}
._1sbkoz62c0 {
  top: calc(var(--space-6) * -1);
}
._1sbkoz62c6 {
  top: calc(var(--space-7) * -1);
}
._1sbkoz62cc {
  flex: 1 1;
}
._1sbkoz62ci {
  flex: 1 1 auto;
}
._1sbkoz62co {
  flex: 0 1 auto;
}
._1sbkoz62cu {
  flex: none;
}
._1sbkoz62d0 {
  width: var(--space-0);
}
._1sbkoz62d6 {
  width: var(--space-1);
}
._1sbkoz62dc {
  width: var(--space-2);
}
._1sbkoz62di {
  width: var(--space-3);
}
._1sbkoz62do {
  width: var(--space-4);
}
._1sbkoz62du {
  width: var(--space-5);
}
._1sbkoz62e0 {
  width: var(--space-6);
}
._1sbkoz62e6 {
  width: var(--space-7);
}
._1sbkoz62ec {
  width: var(--space-px);
}
._1sbkoz62ei {
  width: var(--space-4px);
}
._1sbkoz62eo {
  width: var(--space-8px);
}
._1sbkoz62eu {
  width: var(--space-12px);
}
._1sbkoz62f0 {
  width: var(--space-16px);
}
._1sbkoz62f6 {
  width: var(--space-24px);
}
._1sbkoz62fc {
  width: var(--space-32px);
}
._1sbkoz62fi {
  width: var(--space-48px);
}
._1sbkoz62fo {
  width: var(--space-56px);
}
._1sbkoz62fu {
  width: var(--space-64px);
}
._1sbkoz62g0 {
  width: 100%;
}
._1sbkoz62g6 {
  width: 100%;
}
._1sbkoz62gc {
  width: auto;
}
._1sbkoz62gi {
  width: 576px;
}
._1sbkoz62go {
  width: 852px;
}
._1sbkoz62gu {
  width: 968px;
}
._1sbkoz62h0 {
  width: 1080px;
}
._1sbkoz62h6 {
  z-index: 0;
}
._1sbkoz62hc {
  z-index: 10;
}
._1sbkoz62hi {
  z-index: 20;
}
._1sbkoz62ho {
  z-index: 30;
}
._1sbkoz62hu {
  z-index: 40;
}
._1sbkoz62i0 {
  z-index: 50;
}
._1sbkoz62i6 {
  z-index: 75;
}
._1sbkoz62ic {
  z-index: 99;
}
._1sbkoz62ii {
  z-index: 100;
}
._1sbkoz62io {
  z-index: 9;
}
._1sbkoz62iu {
  z-index: 10;
}
._1sbkoz62j0 {
  z-index: 100;
}
._1sbkoz62j6 {
  z-index: auto;
}
._1sbkoz62jc {
  border-top: 1px solid var(--colors-cardBorder);
}
._1sbkoz62ji {
  border-radius: var(--radii-0);
}
._1sbkoz62jo {
  border-radius: var(--radii-small);
}
._1sbkoz62ju {
  border-radius: var(--radii-default);
}
._1sbkoz62k0 {
  border-radius: var(--radii-card);
}
._1sbkoz62k6 {
  border-radius: var(--radii-circle);
}
._1sbkoz62kc {
  border-top-left-radius: var(--radii-0);
}
._1sbkoz62ki {
  border-top-left-radius: var(--radii-small);
}
._1sbkoz62ko {
  border-top-left-radius: var(--radii-default);
}
._1sbkoz62ku {
  border-top-left-radius: var(--radii-card);
}
._1sbkoz62l0 {
  border-top-left-radius: var(--radii-circle);
}
._1sbkoz62l6 {
  border-bottom-right-radius: var(--radii-0);
}
._1sbkoz62lc {
  border-bottom-right-radius: var(--radii-small);
}
._1sbkoz62li {
  border-bottom-right-radius: var(--radii-default);
}
._1sbkoz62lo {
  border-bottom-right-radius: var(--radii-card);
}
._1sbkoz62lu {
  border-bottom-right-radius: var(--radii-circle);
}
._1sbkoz62m0 {
  border-top-right-radius: var(--radii-0);
}
._1sbkoz62m6 {
  border-top-right-radius: var(--radii-small);
}
._1sbkoz62mc {
  border-top-right-radius: var(--radii-default);
}
._1sbkoz62mi {
  border-top-right-radius: var(--radii-card);
}
._1sbkoz62mo {
  border-top-right-radius: var(--radii-circle);
}
._1sbkoz62mu {
  border-bottom-left-radius: var(--radii-0);
}
._1sbkoz62n0 {
  border-bottom-left-radius: var(--radii-small);
}
._1sbkoz62n6 {
  border-bottom-left-radius: var(--radii-default);
}
._1sbkoz62nc {
  border-bottom-left-radius: var(--radii-card);
}
._1sbkoz62ni {
  border-bottom-left-radius: var(--radii-circle);
}
._1sbkoz62no {
  isolation: isolate;
}
._1sbkoz62np {
  object-fit: contain;
}
._1sbkoz62nq {
  object-fit: cover;
}
._1sbkoz62nr {
  object-fit: none;
}
._1sbkoz62ns {
  pointer-events: none;
}
._1sbkoz62nt {
  text-transform: capitalize;
}
._1sbkoz62nu {
  text-transform: lowercase;
}
._1sbkoz62nv {
  text-transform: uppercase;
}
._1sbkoz62nw {
  cursor: default;
}
._1sbkoz62nx {
  cursor: pointer;
}
._1sbkoz62ny {
  cursor: not-allowed;
}
._1sbkoz62nz {
  visibility: hidden;
}
._1sbkoz62o0 {
  visibility: visible;
}
._1sbkoz62o1 {
  white-space: normal;
}
._1sbkoz62o2 {
  white-space: nowrap;
}
._1sbkoz62o3 {
  white-space: pre;
}
._1sbkoz62o4 {
  white-space: pre-line;
}
._1sbkoz62o5 {
  white-space: pre-wrap;
}
._1sbkoz62o6 {
  word-break: break-word;
}
._1sbkoz62o7 {
  word-wrap: normal;
}
._1sbkoz62o8 {
  word-wrap: break-word;
}
._1sbkoz62o9 {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62oa:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62ob:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62oc:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62od {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62oe:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62of:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62og:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62oh {
  background: var(--colors-white);
}
._1sbkoz62oi:hover {
  background: var(--colors-white);
}
._1sbkoz62oj:focus {
  background: var(--colors-white);
}
._1sbkoz62ok:active {
  background: var(--colors-white);
}
._1sbkoz62ol {
  background: var(--colors-failure);
}
._1sbkoz62om:hover {
  background: var(--colors-failure);
}
._1sbkoz62on:focus {
  background: var(--colors-failure);
}
._1sbkoz62oo:active {
  background: var(--colors-failure);
}
._1sbkoz62op {
  background: var(--colors-failure33);
}
._1sbkoz62oq:hover {
  background: var(--colors-failure33);
}
._1sbkoz62or:focus {
  background: var(--colors-failure33);
}
._1sbkoz62os:active {
  background: var(--colors-failure33);
}
._1sbkoz62ot {
  background: var(--colors-primary);
}
._1sbkoz62ou:hover {
  background: var(--colors-primary);
}
._1sbkoz62ov:focus {
  background: var(--colors-primary);
}
._1sbkoz62ow:active {
  background: var(--colors-primary);
}
._1sbkoz62ox {
  background: var(--colors-primary0f);
}
._1sbkoz62oy:hover {
  background: var(--colors-primary0f);
}
._1sbkoz62oz:focus {
  background: var(--colors-primary0f);
}
._1sbkoz62p0:active {
  background: var(--colors-primary0f);
}
._1sbkoz62p1 {
  background: var(--colors-primary3D);
}
._1sbkoz62p2:hover {
  background: var(--colors-primary3D);
}
._1sbkoz62p3:focus {
  background: var(--colors-primary3D);
}
._1sbkoz62p4:active {
  background: var(--colors-primary3D);
}
._1sbkoz62p5 {
  background: var(--colors-primaryBright);
}
._1sbkoz62p6:hover {
  background: var(--colors-primaryBright);
}
._1sbkoz62p7:focus {
  background: var(--colors-primaryBright);
}
._1sbkoz62p8:active {
  background: var(--colors-primaryBright);
}
._1sbkoz62p9 {
  background: var(--colors-primaryDark);
}
._1sbkoz62pa:hover {
  background: var(--colors-primaryDark);
}
._1sbkoz62pb:focus {
  background: var(--colors-primaryDark);
}
._1sbkoz62pc:active {
  background: var(--colors-primaryDark);
}
._1sbkoz62pd {
  background: var(--colors-success);
}
._1sbkoz62pe:hover {
  background: var(--colors-success);
}
._1sbkoz62pf:focus {
  background: var(--colors-success);
}
._1sbkoz62pg:active {
  background: var(--colors-success);
}
._1sbkoz62ph {
  background: var(--colors-success19);
}
._1sbkoz62pi:hover {
  background: var(--colors-success19);
}
._1sbkoz62pj:focus {
  background: var(--colors-success19);
}
._1sbkoz62pk:active {
  background: var(--colors-success19);
}
._1sbkoz62pl {
  background: var(--colors-warning);
}
._1sbkoz62pm:hover {
  background: var(--colors-warning);
}
._1sbkoz62pn:focus {
  background: var(--colors-warning);
}
._1sbkoz62po:active {
  background: var(--colors-warning);
}
._1sbkoz62pp {
  background: var(--colors-warning2D);
}
._1sbkoz62pq:hover {
  background: var(--colors-warning2D);
}
._1sbkoz62pr:focus {
  background: var(--colors-warning2D);
}
._1sbkoz62ps:active {
  background: var(--colors-warning2D);
}
._1sbkoz62pt {
  background: var(--colors-warning33);
}
._1sbkoz62pu:hover {
  background: var(--colors-warning33);
}
._1sbkoz62pv:focus {
  background: var(--colors-warning33);
}
._1sbkoz62pw:active {
  background: var(--colors-warning33);
}
._1sbkoz62px {
  background: var(--colors-linear);
}
._1sbkoz62py:hover {
  background: var(--colors-linear);
}
._1sbkoz62pz:focus {
  background: var(--colors-linear);
}
._1sbkoz62q0:active {
  background: var(--colors-linear);
}
._1sbkoz62q1 {
  background: var(--colors-binance);
}
._1sbkoz62q2:hover {
  background: var(--colors-binance);
}
._1sbkoz62q3:focus {
  background: var(--colors-binance);
}
._1sbkoz62q4:active {
  background: var(--colors-binance);
}
._1sbkoz62q5 {
  background: var(--colors-overlay);
}
._1sbkoz62q6:hover {
  background: var(--colors-overlay);
}
._1sbkoz62q7:focus {
  background: var(--colors-overlay);
}
._1sbkoz62q8:active {
  background: var(--colors-overlay);
}
._1sbkoz62q9 {
  background: var(--colors-gold);
}
._1sbkoz62qa:hover {
  background: var(--colors-gold);
}
._1sbkoz62qb:focus {
  background: var(--colors-gold);
}
._1sbkoz62qc:active {
  background: var(--colors-gold);
}
._1sbkoz62qd {
  background: var(--colors-silver);
}
._1sbkoz62qe:hover {
  background: var(--colors-silver);
}
._1sbkoz62qf:focus {
  background: var(--colors-silver);
}
._1sbkoz62qg:active {
  background: var(--colors-silver);
}
._1sbkoz62qh {
  background: var(--colors-bronze);
}
._1sbkoz62qi:hover {
  background: var(--colors-bronze);
}
._1sbkoz62qj:focus {
  background: var(--colors-bronze);
}
._1sbkoz62qk:active {
  background: var(--colors-bronze);
}
._1sbkoz62ql {
  background: var(--colors-secondary);
}
._1sbkoz62qm:hover {
  background: var(--colors-secondary);
}
._1sbkoz62qn:focus {
  background: var(--colors-secondary);
}
._1sbkoz62qo:active {
  background: var(--colors-secondary);
}
._1sbkoz62qp {
  background: var(--colors-secondary80);
}
._1sbkoz62qq:hover {
  background: var(--colors-secondary80);
}
._1sbkoz62qr:focus {
  background: var(--colors-secondary80);
}
._1sbkoz62qs:active {
  background: var(--colors-secondary80);
}
._1sbkoz62qt {
  background: var(--colors-background);
}
._1sbkoz62qu:hover {
  background: var(--colors-background);
}
._1sbkoz62qv:focus {
  background: var(--colors-background);
}
._1sbkoz62qw:active {
  background: var(--colors-background);
}
._1sbkoz62qx {
  background: var(--colors-backgroundDisabled);
}
._1sbkoz62qy:hover {
  background: var(--colors-backgroundDisabled);
}
._1sbkoz62qz:focus {
  background: var(--colors-backgroundDisabled);
}
._1sbkoz62r0:active {
  background: var(--colors-backgroundDisabled);
}
._1sbkoz62r1 {
  background: var(--colors-backgroundAlt);
}
._1sbkoz62r2:hover {
  background: var(--colors-backgroundAlt);
}
._1sbkoz62r3:focus {
  background: var(--colors-backgroundAlt);
}
._1sbkoz62r4:active {
  background: var(--colors-backgroundAlt);
}
._1sbkoz62r5 {
  background: var(--colors-backgroundAlt2);
}
._1sbkoz62r6:hover {
  background: var(--colors-backgroundAlt2);
}
._1sbkoz62r7:focus {
  background: var(--colors-backgroundAlt2);
}
._1sbkoz62r8:active {
  background: var(--colors-backgroundAlt2);
}
._1sbkoz62r9 {
  background: var(--colors-cardBorder);
}
._1sbkoz62ra:hover {
  background: var(--colors-cardBorder);
}
._1sbkoz62rb:focus {
  background: var(--colors-cardBorder);
}
._1sbkoz62rc:active {
  background: var(--colors-cardBorder);
}
._1sbkoz62rd {
  background: var(--colors-contrast);
}
._1sbkoz62re:hover {
  background: var(--colors-contrast);
}
._1sbkoz62rf:focus {
  background: var(--colors-contrast);
}
._1sbkoz62rg:active {
  background: var(--colors-contrast);
}
._1sbkoz62rh {
  background: var(--colors-dropdown);
}
._1sbkoz62ri:hover {
  background: var(--colors-dropdown);
}
._1sbkoz62rj:focus {
  background: var(--colors-dropdown);
}
._1sbkoz62rk:active {
  background: var(--colors-dropdown);
}
._1sbkoz62rl {
  background: var(--colors-dropdownDeep);
}
._1sbkoz62rm:hover {
  background: var(--colors-dropdownDeep);
}
._1sbkoz62rn:focus {
  background: var(--colors-dropdownDeep);
}
._1sbkoz62ro:active {
  background: var(--colors-dropdownDeep);
}
._1sbkoz62rp {
  background: var(--colors-invertedContrast);
}
._1sbkoz62rq:hover {
  background: var(--colors-invertedContrast);
}
._1sbkoz62rr:focus {
  background: var(--colors-invertedContrast);
}
._1sbkoz62rs:active {
  background: var(--colors-invertedContrast);
}
._1sbkoz62rt {
  background: var(--colors-input);
}
._1sbkoz62ru:hover {
  background: var(--colors-input);
}
._1sbkoz62rv:focus {
  background: var(--colors-input);
}
._1sbkoz62rw:active {
  background: var(--colors-input);
}
._1sbkoz62rx {
  background: var(--colors-inputSecondary);
}
._1sbkoz62ry:hover {
  background: var(--colors-inputSecondary);
}
._1sbkoz62rz:focus {
  background: var(--colors-inputSecondary);
}
._1sbkoz62s0:active {
  background: var(--colors-inputSecondary);
}
._1sbkoz62s1 {
  background: var(--colors-tertiary);
}
._1sbkoz62s2:hover {
  background: var(--colors-tertiary);
}
._1sbkoz62s3:focus {
  background: var(--colors-tertiary);
}
._1sbkoz62s4:active {
  background: var(--colors-tertiary);
}
._1sbkoz62s5 {
  background: var(--colors-text);
}
._1sbkoz62s6:hover {
  background: var(--colors-text);
}
._1sbkoz62s7:focus {
  background: var(--colors-text);
}
._1sbkoz62s8:active {
  background: var(--colors-text);
}
._1sbkoz62s9 {
  background: var(--colors-text99);
}
._1sbkoz62sa:hover {
  background: var(--colors-text99);
}
._1sbkoz62sb:focus {
  background: var(--colors-text99);
}
._1sbkoz62sc:active {
  background: var(--colors-text99);
}
._1sbkoz62sd {
  background: var(--colors-textDisabled);
}
._1sbkoz62se:hover {
  background: var(--colors-textDisabled);
}
._1sbkoz62sf:focus {
  background: var(--colors-textDisabled);
}
._1sbkoz62sg:active {
  background: var(--colors-textDisabled);
}
._1sbkoz62sh {
  background: var(--colors-textSubtle);
}
._1sbkoz62si:hover {
  background: var(--colors-textSubtle);
}
._1sbkoz62sj:focus {
  background: var(--colors-textSubtle);
}
._1sbkoz62sk:active {
  background: var(--colors-textSubtle);
}
._1sbkoz62sl {
  background: var(--colors-disabled);
}
._1sbkoz62sm:hover {
  background: var(--colors-disabled);
}
._1sbkoz62sn:focus {
  background: var(--colors-disabled);
}
._1sbkoz62so:active {
  background: var(--colors-disabled);
}
._1sbkoz62sp {
  background: var(--colors-gradientBubblegum);
}
._1sbkoz62sq:hover {
  background: var(--colors-gradientBubblegum);
}
._1sbkoz62sr:focus {
  background: var(--colors-gradientBubblegum);
}
._1sbkoz62ss:active {
  background: var(--colors-gradientBubblegum);
}
._1sbkoz62st {
  background: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62su:hover {
  background: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62sv:focus {
  background: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62sw:active {
  background: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62sx {
  background: var(--colors-gradientCardHeader);
}
._1sbkoz62sy:hover {
  background: var(--colors-gradientCardHeader);
}
._1sbkoz62sz:focus {
  background: var(--colors-gradientCardHeader);
}
._1sbkoz62t0:active {
  background: var(--colors-gradientCardHeader);
}
._1sbkoz62t1 {
  background: var(--colors-gradientBlue);
}
._1sbkoz62t2:hover {
  background: var(--colors-gradientBlue);
}
._1sbkoz62t3:focus {
  background: var(--colors-gradientBlue);
}
._1sbkoz62t4:active {
  background: var(--colors-gradientBlue);
}
._1sbkoz62t5 {
  background: var(--colors-gradientViolet);
}
._1sbkoz62t6:hover {
  background: var(--colors-gradientViolet);
}
._1sbkoz62t7:focus {
  background: var(--colors-gradientViolet);
}
._1sbkoz62t8:active {
  background: var(--colors-gradientViolet);
}
._1sbkoz62t9 {
  background: var(--colors-gradientVioletAlt);
}
._1sbkoz62ta:hover {
  background: var(--colors-gradientVioletAlt);
}
._1sbkoz62tb:focus {
  background: var(--colors-gradientVioletAlt);
}
._1sbkoz62tc:active {
  background: var(--colors-gradientVioletAlt);
}
._1sbkoz62td {
  background: var(--colors-gradientGold);
}
._1sbkoz62te:hover {
  background: var(--colors-gradientGold);
}
._1sbkoz62tf:focus {
  background: var(--colors-gradientGold);
}
._1sbkoz62tg:active {
  background: var(--colors-gradientGold);
}
._1sbkoz62th {
  background: var(--colors-backgroundImage);
}
._1sbkoz62ti:hover {
  background: var(--colors-backgroundImage);
}
._1sbkoz62tj:focus {
  background: var(--colors-backgroundImage);
}
._1sbkoz62tk:active {
  background: var(--colors-backgroundImage);
}
._1sbkoz62tl {
  background: var(--colors-backgroundBtn);
}
._1sbkoz62tm:hover {
  background: var(--colors-backgroundBtn);
}
._1sbkoz62tn:focus {
  background: var(--colors-backgroundBtn);
}
._1sbkoz62to:active {
  background: var(--colors-backgroundBtn);
}
._1sbkoz62tp {
  background: var(--colors-textcard);
}
._1sbkoz62tq:hover {
  background: var(--colors-textcard);
}
._1sbkoz62tr:focus {
  background: var(--colors-textcard);
}
._1sbkoz62ts:active {
  background: var(--colors-textcard);
}
._1sbkoz62tt {
  background: var(--colors-bgrcard);
}
._1sbkoz62tu:hover {
  background: var(--colors-bgrcard);
}
._1sbkoz62tv:focus {
  background: var(--colors-bgrcard);
}
._1sbkoz62tw:active {
  background: var(--colors-bgrcard);
}
._1sbkoz62tx {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62ty:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62tz:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62u0:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62u1 {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62u2:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62u3:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62u4:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62u5 {
  background-color: var(--colors-white);
}
._1sbkoz62u6:hover {
  background-color: var(--colors-white);
}
._1sbkoz62u7:focus {
  background-color: var(--colors-white);
}
._1sbkoz62u8:active {
  background-color: var(--colors-white);
}
._1sbkoz62u9 {
  background-color: var(--colors-failure);
}
._1sbkoz62ua:hover {
  background-color: var(--colors-failure);
}
._1sbkoz62ub:focus {
  background-color: var(--colors-failure);
}
._1sbkoz62uc:active {
  background-color: var(--colors-failure);
}
._1sbkoz62ud {
  background-color: var(--colors-failure33);
}
._1sbkoz62ue:hover {
  background-color: var(--colors-failure33);
}
._1sbkoz62uf:focus {
  background-color: var(--colors-failure33);
}
._1sbkoz62ug:active {
  background-color: var(--colors-failure33);
}
._1sbkoz62uh {
  background-color: var(--colors-primary);
}
._1sbkoz62ui:hover {
  background-color: var(--colors-primary);
}
._1sbkoz62uj:focus {
  background-color: var(--colors-primary);
}
._1sbkoz62uk:active {
  background-color: var(--colors-primary);
}
._1sbkoz62ul {
  background-color: var(--colors-primary0f);
}
._1sbkoz62um:hover {
  background-color: var(--colors-primary0f);
}
._1sbkoz62un:focus {
  background-color: var(--colors-primary0f);
}
._1sbkoz62uo:active {
  background-color: var(--colors-primary0f);
}
._1sbkoz62up {
  background-color: var(--colors-primary3D);
}
._1sbkoz62uq:hover {
  background-color: var(--colors-primary3D);
}
._1sbkoz62ur:focus {
  background-color: var(--colors-primary3D);
}
._1sbkoz62us:active {
  background-color: var(--colors-primary3D);
}
._1sbkoz62ut {
  background-color: var(--colors-primaryBright);
}
._1sbkoz62uu:hover {
  background-color: var(--colors-primaryBright);
}
._1sbkoz62uv:focus {
  background-color: var(--colors-primaryBright);
}
._1sbkoz62uw:active {
  background-color: var(--colors-primaryBright);
}
._1sbkoz62ux {
  background-color: var(--colors-primaryDark);
}
._1sbkoz62uy:hover {
  background-color: var(--colors-primaryDark);
}
._1sbkoz62uz:focus {
  background-color: var(--colors-primaryDark);
}
._1sbkoz62v0:active {
  background-color: var(--colors-primaryDark);
}
._1sbkoz62v1 {
  background-color: var(--colors-success);
}
._1sbkoz62v2:hover {
  background-color: var(--colors-success);
}
._1sbkoz62v3:focus {
  background-color: var(--colors-success);
}
._1sbkoz62v4:active {
  background-color: var(--colors-success);
}
._1sbkoz62v5 {
  background-color: var(--colors-success19);
}
._1sbkoz62v6:hover {
  background-color: var(--colors-success19);
}
._1sbkoz62v7:focus {
  background-color: var(--colors-success19);
}
._1sbkoz62v8:active {
  background-color: var(--colors-success19);
}
._1sbkoz62v9 {
  background-color: var(--colors-warning);
}
._1sbkoz62va:hover {
  background-color: var(--colors-warning);
}
._1sbkoz62vb:focus {
  background-color: var(--colors-warning);
}
._1sbkoz62vc:active {
  background-color: var(--colors-warning);
}
._1sbkoz62vd {
  background-color: var(--colors-warning2D);
}
._1sbkoz62ve:hover {
  background-color: var(--colors-warning2D);
}
._1sbkoz62vf:focus {
  background-color: var(--colors-warning2D);
}
._1sbkoz62vg:active {
  background-color: var(--colors-warning2D);
}
._1sbkoz62vh {
  background-color: var(--colors-warning33);
}
._1sbkoz62vi:hover {
  background-color: var(--colors-warning33);
}
._1sbkoz62vj:focus {
  background-color: var(--colors-warning33);
}
._1sbkoz62vk:active {
  background-color: var(--colors-warning33);
}
._1sbkoz62vl {
  background-color: var(--colors-linear);
}
._1sbkoz62vm:hover {
  background-color: var(--colors-linear);
}
._1sbkoz62vn:focus {
  background-color: var(--colors-linear);
}
._1sbkoz62vo:active {
  background-color: var(--colors-linear);
}
._1sbkoz62vp {
  background-color: var(--colors-binance);
}
._1sbkoz62vq:hover {
  background-color: var(--colors-binance);
}
._1sbkoz62vr:focus {
  background-color: var(--colors-binance);
}
._1sbkoz62vs:active {
  background-color: var(--colors-binance);
}
._1sbkoz62vt {
  background-color: var(--colors-overlay);
}
._1sbkoz62vu:hover {
  background-color: var(--colors-overlay);
}
._1sbkoz62vv:focus {
  background-color: var(--colors-overlay);
}
._1sbkoz62vw:active {
  background-color: var(--colors-overlay);
}
._1sbkoz62vx {
  background-color: var(--colors-gold);
}
._1sbkoz62vy:hover {
  background-color: var(--colors-gold);
}
._1sbkoz62vz:focus {
  background-color: var(--colors-gold);
}
._1sbkoz62w0:active {
  background-color: var(--colors-gold);
}
._1sbkoz62w1 {
  background-color: var(--colors-silver);
}
._1sbkoz62w2:hover {
  background-color: var(--colors-silver);
}
._1sbkoz62w3:focus {
  background-color: var(--colors-silver);
}
._1sbkoz62w4:active {
  background-color: var(--colors-silver);
}
._1sbkoz62w5 {
  background-color: var(--colors-bronze);
}
._1sbkoz62w6:hover {
  background-color: var(--colors-bronze);
}
._1sbkoz62w7:focus {
  background-color: var(--colors-bronze);
}
._1sbkoz62w8:active {
  background-color: var(--colors-bronze);
}
._1sbkoz62w9 {
  background-color: var(--colors-secondary);
}
._1sbkoz62wa:hover {
  background-color: var(--colors-secondary);
}
._1sbkoz62wb:focus {
  background-color: var(--colors-secondary);
}
._1sbkoz62wc:active {
  background-color: var(--colors-secondary);
}
._1sbkoz62wd {
  background-color: var(--colors-secondary80);
}
._1sbkoz62we:hover {
  background-color: var(--colors-secondary80);
}
._1sbkoz62wf:focus {
  background-color: var(--colors-secondary80);
}
._1sbkoz62wg:active {
  background-color: var(--colors-secondary80);
}
._1sbkoz62wh {
  background-color: var(--colors-background);
}
._1sbkoz62wi:hover {
  background-color: var(--colors-background);
}
._1sbkoz62wj:focus {
  background-color: var(--colors-background);
}
._1sbkoz62wk:active {
  background-color: var(--colors-background);
}
._1sbkoz62wl {
  background-color: var(--colors-backgroundDisabled);
}
._1sbkoz62wm:hover {
  background-color: var(--colors-backgroundDisabled);
}
._1sbkoz62wn:focus {
  background-color: var(--colors-backgroundDisabled);
}
._1sbkoz62wo:active {
  background-color: var(--colors-backgroundDisabled);
}
._1sbkoz62wp {
  background-color: var(--colors-backgroundAlt);
}
._1sbkoz62wq:hover {
  background-color: var(--colors-backgroundAlt);
}
._1sbkoz62wr:focus {
  background-color: var(--colors-backgroundAlt);
}
._1sbkoz62ws:active {
  background-color: var(--colors-backgroundAlt);
}
._1sbkoz62wt {
  background-color: var(--colors-backgroundAlt2);
}
._1sbkoz62wu:hover {
  background-color: var(--colors-backgroundAlt2);
}
._1sbkoz62wv:focus {
  background-color: var(--colors-backgroundAlt2);
}
._1sbkoz62ww:active {
  background-color: var(--colors-backgroundAlt2);
}
._1sbkoz62wx {
  background-color: var(--colors-cardBorder);
}
._1sbkoz62wy:hover {
  background-color: var(--colors-cardBorder);
}
._1sbkoz62wz:focus {
  background-color: var(--colors-cardBorder);
}
._1sbkoz62x0:active {
  background-color: var(--colors-cardBorder);
}
._1sbkoz62x1 {
  background-color: var(--colors-contrast);
}
._1sbkoz62x2:hover {
  background-color: var(--colors-contrast);
}
._1sbkoz62x3:focus {
  background-color: var(--colors-contrast);
}
._1sbkoz62x4:active {
  background-color: var(--colors-contrast);
}
._1sbkoz62x5 {
  background-color: var(--colors-dropdown);
}
._1sbkoz62x6:hover {
  background-color: var(--colors-dropdown);
}
._1sbkoz62x7:focus {
  background-color: var(--colors-dropdown);
}
._1sbkoz62x8:active {
  background-color: var(--colors-dropdown);
}
._1sbkoz62x9 {
  background-color: var(--colors-dropdownDeep);
}
._1sbkoz62xa:hover {
  background-color: var(--colors-dropdownDeep);
}
._1sbkoz62xb:focus {
  background-color: var(--colors-dropdownDeep);
}
._1sbkoz62xc:active {
  background-color: var(--colors-dropdownDeep);
}
._1sbkoz62xd {
  background-color: var(--colors-invertedContrast);
}
._1sbkoz62xe:hover {
  background-color: var(--colors-invertedContrast);
}
._1sbkoz62xf:focus {
  background-color: var(--colors-invertedContrast);
}
._1sbkoz62xg:active {
  background-color: var(--colors-invertedContrast);
}
._1sbkoz62xh {
  background-color: var(--colors-input);
}
._1sbkoz62xi:hover {
  background-color: var(--colors-input);
}
._1sbkoz62xj:focus {
  background-color: var(--colors-input);
}
._1sbkoz62xk:active {
  background-color: var(--colors-input);
}
._1sbkoz62xl {
  background-color: var(--colors-inputSecondary);
}
._1sbkoz62xm:hover {
  background-color: var(--colors-inputSecondary);
}
._1sbkoz62xn:focus {
  background-color: var(--colors-inputSecondary);
}
._1sbkoz62xo:active {
  background-color: var(--colors-inputSecondary);
}
._1sbkoz62xp {
  background-color: var(--colors-tertiary);
}
._1sbkoz62xq:hover {
  background-color: var(--colors-tertiary);
}
._1sbkoz62xr:focus {
  background-color: var(--colors-tertiary);
}
._1sbkoz62xs:active {
  background-color: var(--colors-tertiary);
}
._1sbkoz62xt {
  background-color: var(--colors-text);
}
._1sbkoz62xu:hover {
  background-color: var(--colors-text);
}
._1sbkoz62xv:focus {
  background-color: var(--colors-text);
}
._1sbkoz62xw:active {
  background-color: var(--colors-text);
}
._1sbkoz62xx {
  background-color: var(--colors-text99);
}
._1sbkoz62xy:hover {
  background-color: var(--colors-text99);
}
._1sbkoz62xz:focus {
  background-color: var(--colors-text99);
}
._1sbkoz62y0:active {
  background-color: var(--colors-text99);
}
._1sbkoz62y1 {
  background-color: var(--colors-textDisabled);
}
._1sbkoz62y2:hover {
  background-color: var(--colors-textDisabled);
}
._1sbkoz62y3:focus {
  background-color: var(--colors-textDisabled);
}
._1sbkoz62y4:active {
  background-color: var(--colors-textDisabled);
}
._1sbkoz62y5 {
  background-color: var(--colors-textSubtle);
}
._1sbkoz62y6:hover {
  background-color: var(--colors-textSubtle);
}
._1sbkoz62y7:focus {
  background-color: var(--colors-textSubtle);
}
._1sbkoz62y8:active {
  background-color: var(--colors-textSubtle);
}
._1sbkoz62y9 {
  background-color: var(--colors-disabled);
}
._1sbkoz62ya:hover {
  background-color: var(--colors-disabled);
}
._1sbkoz62yb:focus {
  background-color: var(--colors-disabled);
}
._1sbkoz62yc:active {
  background-color: var(--colors-disabled);
}
._1sbkoz62yd {
  background-color: var(--colors-gradientBubblegum);
}
._1sbkoz62ye:hover {
  background-color: var(--colors-gradientBubblegum);
}
._1sbkoz62yf:focus {
  background-color: var(--colors-gradientBubblegum);
}
._1sbkoz62yg:active {
  background-color: var(--colors-gradientBubblegum);
}
._1sbkoz62yh {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62yi:hover {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62yj:focus {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62yk:active {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz62yl {
  background-color: var(--colors-gradientCardHeader);
}
._1sbkoz62ym:hover {
  background-color: var(--colors-gradientCardHeader);
}
._1sbkoz62yn:focus {
  background-color: var(--colors-gradientCardHeader);
}
._1sbkoz62yo:active {
  background-color: var(--colors-gradientCardHeader);
}
._1sbkoz62yp {
  background-color: var(--colors-gradientBlue);
}
._1sbkoz62yq:hover {
  background-color: var(--colors-gradientBlue);
}
._1sbkoz62yr:focus {
  background-color: var(--colors-gradientBlue);
}
._1sbkoz62ys:active {
  background-color: var(--colors-gradientBlue);
}
._1sbkoz62yt {
  background-color: var(--colors-gradientViolet);
}
._1sbkoz62yu:hover {
  background-color: var(--colors-gradientViolet);
}
._1sbkoz62yv:focus {
  background-color: var(--colors-gradientViolet);
}
._1sbkoz62yw:active {
  background-color: var(--colors-gradientViolet);
}
._1sbkoz62yx {
  background-color: var(--colors-gradientVioletAlt);
}
._1sbkoz62yy:hover {
  background-color: var(--colors-gradientVioletAlt);
}
._1sbkoz62yz:focus {
  background-color: var(--colors-gradientVioletAlt);
}
._1sbkoz62z0:active {
  background-color: var(--colors-gradientVioletAlt);
}
._1sbkoz62z1 {
  background-color: var(--colors-gradientGold);
}
._1sbkoz62z2:hover {
  background-color: var(--colors-gradientGold);
}
._1sbkoz62z3:focus {
  background-color: var(--colors-gradientGold);
}
._1sbkoz62z4:active {
  background-color: var(--colors-gradientGold);
}
._1sbkoz62z5 {
  background-color: var(--colors-backgroundImage);
}
._1sbkoz62z6:hover {
  background-color: var(--colors-backgroundImage);
}
._1sbkoz62z7:focus {
  background-color: var(--colors-backgroundImage);
}
._1sbkoz62z8:active {
  background-color: var(--colors-backgroundImage);
}
._1sbkoz62z9 {
  background-color: var(--colors-backgroundBtn);
}
._1sbkoz62za:hover {
  background-color: var(--colors-backgroundBtn);
}
._1sbkoz62zb:focus {
  background-color: var(--colors-backgroundBtn);
}
._1sbkoz62zc:active {
  background-color: var(--colors-backgroundBtn);
}
._1sbkoz62zd {
  background-color: var(--colors-textcard);
}
._1sbkoz62ze:hover {
  background-color: var(--colors-textcard);
}
._1sbkoz62zf:focus {
  background-color: var(--colors-textcard);
}
._1sbkoz62zg:active {
  background-color: var(--colors-textcard);
}
._1sbkoz62zh {
  background-color: var(--colors-bgrcard);
}
._1sbkoz62zi:hover {
  background-color: var(--colors-bgrcard);
}
._1sbkoz62zj:focus {
  background-color: var(--colors-bgrcard);
}
._1sbkoz62zk:active {
  background-color: var(--colors-bgrcard);
}
._1sbkoz62zl {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62zm:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62zn:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62zo:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz62zp {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62zq:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62zr:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62zs:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz62zt {
  border-color: var(--colors-white);
}
._1sbkoz62zu:hover {
  border-color: var(--colors-white);
}
._1sbkoz62zv:focus {
  border-color: var(--colors-white);
}
._1sbkoz62zw:active {
  border-color: var(--colors-white);
}
._1sbkoz62zx {
  border-color: var(--colors-failure);
}
._1sbkoz62zy:hover {
  border-color: var(--colors-failure);
}
._1sbkoz62zz:focus {
  border-color: var(--colors-failure);
}
._1sbkoz6300:active {
  border-color: var(--colors-failure);
}
._1sbkoz6301 {
  border-color: var(--colors-failure33);
}
._1sbkoz6302:hover {
  border-color: var(--colors-failure33);
}
._1sbkoz6303:focus {
  border-color: var(--colors-failure33);
}
._1sbkoz6304:active {
  border-color: var(--colors-failure33);
}
._1sbkoz6305 {
  border-color: var(--colors-primary);
}
._1sbkoz6306:hover {
  border-color: var(--colors-primary);
}
._1sbkoz6307:focus {
  border-color: var(--colors-primary);
}
._1sbkoz6308:active {
  border-color: var(--colors-primary);
}
._1sbkoz6309 {
  border-color: var(--colors-primary0f);
}
._1sbkoz630a:hover {
  border-color: var(--colors-primary0f);
}
._1sbkoz630b:focus {
  border-color: var(--colors-primary0f);
}
._1sbkoz630c:active {
  border-color: var(--colors-primary0f);
}
._1sbkoz630d {
  border-color: var(--colors-primary3D);
}
._1sbkoz630e:hover {
  border-color: var(--colors-primary3D);
}
._1sbkoz630f:focus {
  border-color: var(--colors-primary3D);
}
._1sbkoz630g:active {
  border-color: var(--colors-primary3D);
}
._1sbkoz630h {
  border-color: var(--colors-primaryBright);
}
._1sbkoz630i:hover {
  border-color: var(--colors-primaryBright);
}
._1sbkoz630j:focus {
  border-color: var(--colors-primaryBright);
}
._1sbkoz630k:active {
  border-color: var(--colors-primaryBright);
}
._1sbkoz630l {
  border-color: var(--colors-primaryDark);
}
._1sbkoz630m:hover {
  border-color: var(--colors-primaryDark);
}
._1sbkoz630n:focus {
  border-color: var(--colors-primaryDark);
}
._1sbkoz630o:active {
  border-color: var(--colors-primaryDark);
}
._1sbkoz630p {
  border-color: var(--colors-success);
}
._1sbkoz630q:hover {
  border-color: var(--colors-success);
}
._1sbkoz630r:focus {
  border-color: var(--colors-success);
}
._1sbkoz630s:active {
  border-color: var(--colors-success);
}
._1sbkoz630t {
  border-color: var(--colors-success19);
}
._1sbkoz630u:hover {
  border-color: var(--colors-success19);
}
._1sbkoz630v:focus {
  border-color: var(--colors-success19);
}
._1sbkoz630w:active {
  border-color: var(--colors-success19);
}
._1sbkoz630x {
  border-color: var(--colors-warning);
}
._1sbkoz630y:hover {
  border-color: var(--colors-warning);
}
._1sbkoz630z:focus {
  border-color: var(--colors-warning);
}
._1sbkoz6310:active {
  border-color: var(--colors-warning);
}
._1sbkoz6311 {
  border-color: var(--colors-warning2D);
}
._1sbkoz6312:hover {
  border-color: var(--colors-warning2D);
}
._1sbkoz6313:focus {
  border-color: var(--colors-warning2D);
}
._1sbkoz6314:active {
  border-color: var(--colors-warning2D);
}
._1sbkoz6315 {
  border-color: var(--colors-warning33);
}
._1sbkoz6316:hover {
  border-color: var(--colors-warning33);
}
._1sbkoz6317:focus {
  border-color: var(--colors-warning33);
}
._1sbkoz6318:active {
  border-color: var(--colors-warning33);
}
._1sbkoz6319 {
  border-color: var(--colors-linear);
}
._1sbkoz631a:hover {
  border-color: var(--colors-linear);
}
._1sbkoz631b:focus {
  border-color: var(--colors-linear);
}
._1sbkoz631c:active {
  border-color: var(--colors-linear);
}
._1sbkoz631d {
  border-color: var(--colors-binance);
}
._1sbkoz631e:hover {
  border-color: var(--colors-binance);
}
._1sbkoz631f:focus {
  border-color: var(--colors-binance);
}
._1sbkoz631g:active {
  border-color: var(--colors-binance);
}
._1sbkoz631h {
  border-color: var(--colors-overlay);
}
._1sbkoz631i:hover {
  border-color: var(--colors-overlay);
}
._1sbkoz631j:focus {
  border-color: var(--colors-overlay);
}
._1sbkoz631k:active {
  border-color: var(--colors-overlay);
}
._1sbkoz631l {
  border-color: var(--colors-gold);
}
._1sbkoz631m:hover {
  border-color: var(--colors-gold);
}
._1sbkoz631n:focus {
  border-color: var(--colors-gold);
}
._1sbkoz631o:active {
  border-color: var(--colors-gold);
}
._1sbkoz631p {
  border-color: var(--colors-silver);
}
._1sbkoz631q:hover {
  border-color: var(--colors-silver);
}
._1sbkoz631r:focus {
  border-color: var(--colors-silver);
}
._1sbkoz631s:active {
  border-color: var(--colors-silver);
}
._1sbkoz631t {
  border-color: var(--colors-bronze);
}
._1sbkoz631u:hover {
  border-color: var(--colors-bronze);
}
._1sbkoz631v:focus {
  border-color: var(--colors-bronze);
}
._1sbkoz631w:active {
  border-color: var(--colors-bronze);
}
._1sbkoz631x {
  border-color: var(--colors-secondary);
}
._1sbkoz631y:hover {
  border-color: var(--colors-secondary);
}
._1sbkoz631z:focus {
  border-color: var(--colors-secondary);
}
._1sbkoz6320:active {
  border-color: var(--colors-secondary);
}
._1sbkoz6321 {
  border-color: var(--colors-secondary80);
}
._1sbkoz6322:hover {
  border-color: var(--colors-secondary80);
}
._1sbkoz6323:focus {
  border-color: var(--colors-secondary80);
}
._1sbkoz6324:active {
  border-color: var(--colors-secondary80);
}
._1sbkoz6325 {
  border-color: var(--colors-background);
}
._1sbkoz6326:hover {
  border-color: var(--colors-background);
}
._1sbkoz6327:focus {
  border-color: var(--colors-background);
}
._1sbkoz6328:active {
  border-color: var(--colors-background);
}
._1sbkoz6329 {
  border-color: var(--colors-backgroundDisabled);
}
._1sbkoz632a:hover {
  border-color: var(--colors-backgroundDisabled);
}
._1sbkoz632b:focus {
  border-color: var(--colors-backgroundDisabled);
}
._1sbkoz632c:active {
  border-color: var(--colors-backgroundDisabled);
}
._1sbkoz632d {
  border-color: var(--colors-backgroundAlt);
}
._1sbkoz632e:hover {
  border-color: var(--colors-backgroundAlt);
}
._1sbkoz632f:focus {
  border-color: var(--colors-backgroundAlt);
}
._1sbkoz632g:active {
  border-color: var(--colors-backgroundAlt);
}
._1sbkoz632h {
  border-color: var(--colors-backgroundAlt2);
}
._1sbkoz632i:hover {
  border-color: var(--colors-backgroundAlt2);
}
._1sbkoz632j:focus {
  border-color: var(--colors-backgroundAlt2);
}
._1sbkoz632k:active {
  border-color: var(--colors-backgroundAlt2);
}
._1sbkoz632l {
  border-color: var(--colors-cardBorder);
}
._1sbkoz632m:hover {
  border-color: var(--colors-cardBorder);
}
._1sbkoz632n:focus {
  border-color: var(--colors-cardBorder);
}
._1sbkoz632o:active {
  border-color: var(--colors-cardBorder);
}
._1sbkoz632p {
  border-color: var(--colors-contrast);
}
._1sbkoz632q:hover {
  border-color: var(--colors-contrast);
}
._1sbkoz632r:focus {
  border-color: var(--colors-contrast);
}
._1sbkoz632s:active {
  border-color: var(--colors-contrast);
}
._1sbkoz632t {
  border-color: var(--colors-dropdown);
}
._1sbkoz632u:hover {
  border-color: var(--colors-dropdown);
}
._1sbkoz632v:focus {
  border-color: var(--colors-dropdown);
}
._1sbkoz632w:active {
  border-color: var(--colors-dropdown);
}
._1sbkoz632x {
  border-color: var(--colors-dropdownDeep);
}
._1sbkoz632y:hover {
  border-color: var(--colors-dropdownDeep);
}
._1sbkoz632z:focus {
  border-color: var(--colors-dropdownDeep);
}
._1sbkoz6330:active {
  border-color: var(--colors-dropdownDeep);
}
._1sbkoz6331 {
  border-color: var(--colors-invertedContrast);
}
._1sbkoz6332:hover {
  border-color: var(--colors-invertedContrast);
}
._1sbkoz6333:focus {
  border-color: var(--colors-invertedContrast);
}
._1sbkoz6334:active {
  border-color: var(--colors-invertedContrast);
}
._1sbkoz6335 {
  border-color: var(--colors-input);
}
._1sbkoz6336:hover {
  border-color: var(--colors-input);
}
._1sbkoz6337:focus {
  border-color: var(--colors-input);
}
._1sbkoz6338:active {
  border-color: var(--colors-input);
}
._1sbkoz6339 {
  border-color: var(--colors-inputSecondary);
}
._1sbkoz633a:hover {
  border-color: var(--colors-inputSecondary);
}
._1sbkoz633b:focus {
  border-color: var(--colors-inputSecondary);
}
._1sbkoz633c:active {
  border-color: var(--colors-inputSecondary);
}
._1sbkoz633d {
  border-color: var(--colors-tertiary);
}
._1sbkoz633e:hover {
  border-color: var(--colors-tertiary);
}
._1sbkoz633f:focus {
  border-color: var(--colors-tertiary);
}
._1sbkoz633g:active {
  border-color: var(--colors-tertiary);
}
._1sbkoz633h {
  border-color: var(--colors-text);
}
._1sbkoz633i:hover {
  border-color: var(--colors-text);
}
._1sbkoz633j:focus {
  border-color: var(--colors-text);
}
._1sbkoz633k:active {
  border-color: var(--colors-text);
}
._1sbkoz633l {
  border-color: var(--colors-text99);
}
._1sbkoz633m:hover {
  border-color: var(--colors-text99);
}
._1sbkoz633n:focus {
  border-color: var(--colors-text99);
}
._1sbkoz633o:active {
  border-color: var(--colors-text99);
}
._1sbkoz633p {
  border-color: var(--colors-textDisabled);
}
._1sbkoz633q:hover {
  border-color: var(--colors-textDisabled);
}
._1sbkoz633r:focus {
  border-color: var(--colors-textDisabled);
}
._1sbkoz633s:active {
  border-color: var(--colors-textDisabled);
}
._1sbkoz633t {
  border-color: var(--colors-textSubtle);
}
._1sbkoz633u:hover {
  border-color: var(--colors-textSubtle);
}
._1sbkoz633v:focus {
  border-color: var(--colors-textSubtle);
}
._1sbkoz633w:active {
  border-color: var(--colors-textSubtle);
}
._1sbkoz633x {
  border-color: var(--colors-disabled);
}
._1sbkoz633y:hover {
  border-color: var(--colors-disabled);
}
._1sbkoz633z:focus {
  border-color: var(--colors-disabled);
}
._1sbkoz6340:active {
  border-color: var(--colors-disabled);
}
._1sbkoz6341 {
  border-color: var(--colors-gradientBubblegum);
}
._1sbkoz6342:hover {
  border-color: var(--colors-gradientBubblegum);
}
._1sbkoz6343:focus {
  border-color: var(--colors-gradientBubblegum);
}
._1sbkoz6344:active {
  border-color: var(--colors-gradientBubblegum);
}
._1sbkoz6345 {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz6346:hover {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz6347:focus {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz6348:active {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz6349 {
  border-color: var(--colors-gradientCardHeader);
}
._1sbkoz634a:hover {
  border-color: var(--colors-gradientCardHeader);
}
._1sbkoz634b:focus {
  border-color: var(--colors-gradientCardHeader);
}
._1sbkoz634c:active {
  border-color: var(--colors-gradientCardHeader);
}
._1sbkoz634d {
  border-color: var(--colors-gradientBlue);
}
._1sbkoz634e:hover {
  border-color: var(--colors-gradientBlue);
}
._1sbkoz634f:focus {
  border-color: var(--colors-gradientBlue);
}
._1sbkoz634g:active {
  border-color: var(--colors-gradientBlue);
}
._1sbkoz634h {
  border-color: var(--colors-gradientViolet);
}
._1sbkoz634i:hover {
  border-color: var(--colors-gradientViolet);
}
._1sbkoz634j:focus {
  border-color: var(--colors-gradientViolet);
}
._1sbkoz634k:active {
  border-color: var(--colors-gradientViolet);
}
._1sbkoz634l {
  border-color: var(--colors-gradientVioletAlt);
}
._1sbkoz634m:hover {
  border-color: var(--colors-gradientVioletAlt);
}
._1sbkoz634n:focus {
  border-color: var(--colors-gradientVioletAlt);
}
._1sbkoz634o:active {
  border-color: var(--colors-gradientVioletAlt);
}
._1sbkoz634p {
  border-color: var(--colors-gradientGold);
}
._1sbkoz634q:hover {
  border-color: var(--colors-gradientGold);
}
._1sbkoz634r:focus {
  border-color: var(--colors-gradientGold);
}
._1sbkoz634s:active {
  border-color: var(--colors-gradientGold);
}
._1sbkoz634t {
  border-color: var(--colors-backgroundImage);
}
._1sbkoz634u:hover {
  border-color: var(--colors-backgroundImage);
}
._1sbkoz634v:focus {
  border-color: var(--colors-backgroundImage);
}
._1sbkoz634w:active {
  border-color: var(--colors-backgroundImage);
}
._1sbkoz634x {
  border-color: var(--colors-backgroundBtn);
}
._1sbkoz634y:hover {
  border-color: var(--colors-backgroundBtn);
}
._1sbkoz634z:focus {
  border-color: var(--colors-backgroundBtn);
}
._1sbkoz6350:active {
  border-color: var(--colors-backgroundBtn);
}
._1sbkoz6351 {
  border-color: var(--colors-textcard);
}
._1sbkoz6352:hover {
  border-color: var(--colors-textcard);
}
._1sbkoz6353:focus {
  border-color: var(--colors-textcard);
}
._1sbkoz6354:active {
  border-color: var(--colors-textcard);
}
._1sbkoz6355 {
  border-color: var(--colors-bgrcard);
}
._1sbkoz6356:hover {
  border-color: var(--colors-bgrcard);
}
._1sbkoz6357:focus {
  border-color: var(--colors-bgrcard);
}
._1sbkoz6358:active {
  border-color: var(--colors-bgrcard);
}
._1sbkoz6359 {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz635a:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz635b:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz635c:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz635d {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz635e:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz635f:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz635g:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz635h {
  color: var(--colors-white);
}
._1sbkoz635i:hover {
  color: var(--colors-white);
}
._1sbkoz635j:focus {
  color: var(--colors-white);
}
._1sbkoz635k:active {
  color: var(--colors-white);
}
._1sbkoz635l {
  color: var(--colors-failure);
}
._1sbkoz635m:hover {
  color: var(--colors-failure);
}
._1sbkoz635n:focus {
  color: var(--colors-failure);
}
._1sbkoz635o:active {
  color: var(--colors-failure);
}
._1sbkoz635p {
  color: var(--colors-failure33);
}
._1sbkoz635q:hover {
  color: var(--colors-failure33);
}
._1sbkoz635r:focus {
  color: var(--colors-failure33);
}
._1sbkoz635s:active {
  color: var(--colors-failure33);
}
._1sbkoz635t {
  color: var(--colors-primary);
}
._1sbkoz635u:hover {
  color: var(--colors-primary);
}
._1sbkoz635v:focus {
  color: var(--colors-primary);
}
._1sbkoz635w:active {
  color: var(--colors-primary);
}
._1sbkoz635x {
  color: var(--colors-primary0f);
}
._1sbkoz635y:hover {
  color: var(--colors-primary0f);
}
._1sbkoz635z:focus {
  color: var(--colors-primary0f);
}
._1sbkoz6360:active {
  color: var(--colors-primary0f);
}
._1sbkoz6361 {
  color: var(--colors-primary3D);
}
._1sbkoz6362:hover {
  color: var(--colors-primary3D);
}
._1sbkoz6363:focus {
  color: var(--colors-primary3D);
}
._1sbkoz6364:active {
  color: var(--colors-primary3D);
}
._1sbkoz6365 {
  color: var(--colors-primaryBright);
}
._1sbkoz6366:hover {
  color: var(--colors-primaryBright);
}
._1sbkoz6367:focus {
  color: var(--colors-primaryBright);
}
._1sbkoz6368:active {
  color: var(--colors-primaryBright);
}
._1sbkoz6369 {
  color: var(--colors-primaryDark);
}
._1sbkoz636a:hover {
  color: var(--colors-primaryDark);
}
._1sbkoz636b:focus {
  color: var(--colors-primaryDark);
}
._1sbkoz636c:active {
  color: var(--colors-primaryDark);
}
._1sbkoz636d {
  color: var(--colors-success);
}
._1sbkoz636e:hover {
  color: var(--colors-success);
}
._1sbkoz636f:focus {
  color: var(--colors-success);
}
._1sbkoz636g:active {
  color: var(--colors-success);
}
._1sbkoz636h {
  color: var(--colors-success19);
}
._1sbkoz636i:hover {
  color: var(--colors-success19);
}
._1sbkoz636j:focus {
  color: var(--colors-success19);
}
._1sbkoz636k:active {
  color: var(--colors-success19);
}
._1sbkoz636l {
  color: var(--colors-warning);
}
._1sbkoz636m:hover {
  color: var(--colors-warning);
}
._1sbkoz636n:focus {
  color: var(--colors-warning);
}
._1sbkoz636o:active {
  color: var(--colors-warning);
}
._1sbkoz636p {
  color: var(--colors-warning2D);
}
._1sbkoz636q:hover {
  color: var(--colors-warning2D);
}
._1sbkoz636r:focus {
  color: var(--colors-warning2D);
}
._1sbkoz636s:active {
  color: var(--colors-warning2D);
}
._1sbkoz636t {
  color: var(--colors-warning33);
}
._1sbkoz636u:hover {
  color: var(--colors-warning33);
}
._1sbkoz636v:focus {
  color: var(--colors-warning33);
}
._1sbkoz636w:active {
  color: var(--colors-warning33);
}
._1sbkoz636x {
  color: var(--colors-linear);
}
._1sbkoz636y:hover {
  color: var(--colors-linear);
}
._1sbkoz636z:focus {
  color: var(--colors-linear);
}
._1sbkoz6370:active {
  color: var(--colors-linear);
}
._1sbkoz6371 {
  color: var(--colors-binance);
}
._1sbkoz6372:hover {
  color: var(--colors-binance);
}
._1sbkoz6373:focus {
  color: var(--colors-binance);
}
._1sbkoz6374:active {
  color: var(--colors-binance);
}
._1sbkoz6375 {
  color: var(--colors-overlay);
}
._1sbkoz6376:hover {
  color: var(--colors-overlay);
}
._1sbkoz6377:focus {
  color: var(--colors-overlay);
}
._1sbkoz6378:active {
  color: var(--colors-overlay);
}
._1sbkoz6379 {
  color: var(--colors-gold);
}
._1sbkoz637a:hover {
  color: var(--colors-gold);
}
._1sbkoz637b:focus {
  color: var(--colors-gold);
}
._1sbkoz637c:active {
  color: var(--colors-gold);
}
._1sbkoz637d {
  color: var(--colors-silver);
}
._1sbkoz637e:hover {
  color: var(--colors-silver);
}
._1sbkoz637f:focus {
  color: var(--colors-silver);
}
._1sbkoz637g:active {
  color: var(--colors-silver);
}
._1sbkoz637h {
  color: var(--colors-bronze);
}
._1sbkoz637i:hover {
  color: var(--colors-bronze);
}
._1sbkoz637j:focus {
  color: var(--colors-bronze);
}
._1sbkoz637k:active {
  color: var(--colors-bronze);
}
._1sbkoz637l {
  color: var(--colors-secondary);
}
._1sbkoz637m:hover {
  color: var(--colors-secondary);
}
._1sbkoz637n:focus {
  color: var(--colors-secondary);
}
._1sbkoz637o:active {
  color: var(--colors-secondary);
}
._1sbkoz637p {
  color: var(--colors-secondary80);
}
._1sbkoz637q:hover {
  color: var(--colors-secondary80);
}
._1sbkoz637r:focus {
  color: var(--colors-secondary80);
}
._1sbkoz637s:active {
  color: var(--colors-secondary80);
}
._1sbkoz637t {
  color: var(--colors-background);
}
._1sbkoz637u:hover {
  color: var(--colors-background);
}
._1sbkoz637v:focus {
  color: var(--colors-background);
}
._1sbkoz637w:active {
  color: var(--colors-background);
}
._1sbkoz637x {
  color: var(--colors-backgroundDisabled);
}
._1sbkoz637y:hover {
  color: var(--colors-backgroundDisabled);
}
._1sbkoz637z:focus {
  color: var(--colors-backgroundDisabled);
}
._1sbkoz6380:active {
  color: var(--colors-backgroundDisabled);
}
._1sbkoz6381 {
  color: var(--colors-backgroundAlt);
}
._1sbkoz6382:hover {
  color: var(--colors-backgroundAlt);
}
._1sbkoz6383:focus {
  color: var(--colors-backgroundAlt);
}
._1sbkoz6384:active {
  color: var(--colors-backgroundAlt);
}
._1sbkoz6385 {
  color: var(--colors-backgroundAlt2);
}
._1sbkoz6386:hover {
  color: var(--colors-backgroundAlt2);
}
._1sbkoz6387:focus {
  color: var(--colors-backgroundAlt2);
}
._1sbkoz6388:active {
  color: var(--colors-backgroundAlt2);
}
._1sbkoz6389 {
  color: var(--colors-cardBorder);
}
._1sbkoz638a:hover {
  color: var(--colors-cardBorder);
}
._1sbkoz638b:focus {
  color: var(--colors-cardBorder);
}
._1sbkoz638c:active {
  color: var(--colors-cardBorder);
}
._1sbkoz638d {
  color: var(--colors-contrast);
}
._1sbkoz638e:hover {
  color: var(--colors-contrast);
}
._1sbkoz638f:focus {
  color: var(--colors-contrast);
}
._1sbkoz638g:active {
  color: var(--colors-contrast);
}
._1sbkoz638h {
  color: var(--colors-dropdown);
}
._1sbkoz638i:hover {
  color: var(--colors-dropdown);
}
._1sbkoz638j:focus {
  color: var(--colors-dropdown);
}
._1sbkoz638k:active {
  color: var(--colors-dropdown);
}
._1sbkoz638l {
  color: var(--colors-dropdownDeep);
}
._1sbkoz638m:hover {
  color: var(--colors-dropdownDeep);
}
._1sbkoz638n:focus {
  color: var(--colors-dropdownDeep);
}
._1sbkoz638o:active {
  color: var(--colors-dropdownDeep);
}
._1sbkoz638p {
  color: var(--colors-invertedContrast);
}
._1sbkoz638q:hover {
  color: var(--colors-invertedContrast);
}
._1sbkoz638r:focus {
  color: var(--colors-invertedContrast);
}
._1sbkoz638s:active {
  color: var(--colors-invertedContrast);
}
._1sbkoz638t {
  color: var(--colors-input);
}
._1sbkoz638u:hover {
  color: var(--colors-input);
}
._1sbkoz638v:focus {
  color: var(--colors-input);
}
._1sbkoz638w:active {
  color: var(--colors-input);
}
._1sbkoz638x {
  color: var(--colors-inputSecondary);
}
._1sbkoz638y:hover {
  color: var(--colors-inputSecondary);
}
._1sbkoz638z:focus {
  color: var(--colors-inputSecondary);
}
._1sbkoz6390:active {
  color: var(--colors-inputSecondary);
}
._1sbkoz6391 {
  color: var(--colors-tertiary);
}
._1sbkoz6392:hover {
  color: var(--colors-tertiary);
}
._1sbkoz6393:focus {
  color: var(--colors-tertiary);
}
._1sbkoz6394:active {
  color: var(--colors-tertiary);
}
._1sbkoz6395 {
  color: var(--colors-text);
}
._1sbkoz6396:hover {
  color: var(--colors-text);
}
._1sbkoz6397:focus {
  color: var(--colors-text);
}
._1sbkoz6398:active {
  color: var(--colors-text);
}
._1sbkoz6399 {
  color: var(--colors-text99);
}
._1sbkoz639a:hover {
  color: var(--colors-text99);
}
._1sbkoz639b:focus {
  color: var(--colors-text99);
}
._1sbkoz639c:active {
  color: var(--colors-text99);
}
._1sbkoz639d {
  color: var(--colors-textDisabled);
}
._1sbkoz639e:hover {
  color: var(--colors-textDisabled);
}
._1sbkoz639f:focus {
  color: var(--colors-textDisabled);
}
._1sbkoz639g:active {
  color: var(--colors-textDisabled);
}
._1sbkoz639h {
  color: var(--colors-textSubtle);
}
._1sbkoz639i:hover {
  color: var(--colors-textSubtle);
}
._1sbkoz639j:focus {
  color: var(--colors-textSubtle);
}
._1sbkoz639k:active {
  color: var(--colors-textSubtle);
}
._1sbkoz639l {
  color: var(--colors-disabled);
}
._1sbkoz639m:hover {
  color: var(--colors-disabled);
}
._1sbkoz639n:focus {
  color: var(--colors-disabled);
}
._1sbkoz639o:active {
  color: var(--colors-disabled);
}
._1sbkoz639p {
  color: var(--colors-gradientBubblegum);
}
._1sbkoz639q:hover {
  color: var(--colors-gradientBubblegum);
}
._1sbkoz639r:focus {
  color: var(--colors-gradientBubblegum);
}
._1sbkoz639s:active {
  color: var(--colors-gradientBubblegum);
}
._1sbkoz639t {
  color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz639u:hover {
  color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz639v:focus {
  color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz639w:active {
  color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz639x {
  color: var(--colors-gradientCardHeader);
}
._1sbkoz639y:hover {
  color: var(--colors-gradientCardHeader);
}
._1sbkoz639z:focus {
  color: var(--colors-gradientCardHeader);
}
._1sbkoz63a0:active {
  color: var(--colors-gradientCardHeader);
}
._1sbkoz63a1 {
  color: var(--colors-gradientBlue);
}
._1sbkoz63a2:hover {
  color: var(--colors-gradientBlue);
}
._1sbkoz63a3:focus {
  color: var(--colors-gradientBlue);
}
._1sbkoz63a4:active {
  color: var(--colors-gradientBlue);
}
._1sbkoz63a5 {
  color: var(--colors-gradientViolet);
}
._1sbkoz63a6:hover {
  color: var(--colors-gradientViolet);
}
._1sbkoz63a7:focus {
  color: var(--colors-gradientViolet);
}
._1sbkoz63a8:active {
  color: var(--colors-gradientViolet);
}
._1sbkoz63a9 {
  color: var(--colors-gradientVioletAlt);
}
._1sbkoz63aa:hover {
  color: var(--colors-gradientVioletAlt);
}
._1sbkoz63ab:focus {
  color: var(--colors-gradientVioletAlt);
}
._1sbkoz63ac:active {
  color: var(--colors-gradientVioletAlt);
}
._1sbkoz63ad {
  color: var(--colors-gradientGold);
}
._1sbkoz63ae:hover {
  color: var(--colors-gradientGold);
}
._1sbkoz63af:focus {
  color: var(--colors-gradientGold);
}
._1sbkoz63ag:active {
  color: var(--colors-gradientGold);
}
._1sbkoz63ah {
  color: var(--colors-backgroundImage);
}
._1sbkoz63ai:hover {
  color: var(--colors-backgroundImage);
}
._1sbkoz63aj:focus {
  color: var(--colors-backgroundImage);
}
._1sbkoz63ak:active {
  color: var(--colors-backgroundImage);
}
._1sbkoz63al {
  color: var(--colors-backgroundBtn);
}
._1sbkoz63am:hover {
  color: var(--colors-backgroundBtn);
}
._1sbkoz63an:focus {
  color: var(--colors-backgroundBtn);
}
._1sbkoz63ao:active {
  color: var(--colors-backgroundBtn);
}
._1sbkoz63ap {
  color: var(--colors-textcard);
}
._1sbkoz63aq:hover {
  color: var(--colors-textcard);
}
._1sbkoz63ar:focus {
  color: var(--colors-textcard);
}
._1sbkoz63as:active {
  color: var(--colors-textcard);
}
._1sbkoz63at {
  color: var(--colors-bgrcard);
}
._1sbkoz63au:hover {
  color: var(--colors-bgrcard);
}
._1sbkoz63av:focus {
  color: var(--colors-bgrcard);
}
._1sbkoz63aw:active {
  color: var(--colors-bgrcard);
}
._1sbkoz63ax {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz63ay:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz63az:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz63b0:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  linear: var(--colors-light-linear);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  background-image: var(--colors-light-backgroundImage);
  background-btn: var(--colors-light-backgroundBtn);
  textcard: var(--colors-light-textcard);
  bgrcard: var(--colors-light-bgrcard);
}
._1sbkoz63b1 {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz63b2:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz63b3:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz63b4:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  linear: var(--colors-dark-linear);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  background-image: var(--colors-dark-backgroundImage);
  background-btn: var(--colors-dark-backgroundBtn);
  textcard: var(--colors-dark-textcard);
  bgrcard: var(--colors-dark-bgrcard);
}
._1sbkoz63b5 {
  outline-color: var(--colors-white);
}
._1sbkoz63b6:hover {
  outline-color: var(--colors-white);
}
._1sbkoz63b7:focus {
  outline-color: var(--colors-white);
}
._1sbkoz63b8:active {
  outline-color: var(--colors-white);
}
._1sbkoz63b9 {
  outline-color: var(--colors-failure);
}
._1sbkoz63ba:hover {
  outline-color: var(--colors-failure);
}
._1sbkoz63bb:focus {
  outline-color: var(--colors-failure);
}
._1sbkoz63bc:active {
  outline-color: var(--colors-failure);
}
._1sbkoz63bd {
  outline-color: var(--colors-failure33);
}
._1sbkoz63be:hover {
  outline-color: var(--colors-failure33);
}
._1sbkoz63bf:focus {
  outline-color: var(--colors-failure33);
}
._1sbkoz63bg:active {
  outline-color: var(--colors-failure33);
}
._1sbkoz63bh {
  outline-color: var(--colors-primary);
}
._1sbkoz63bi:hover {
  outline-color: var(--colors-primary);
}
._1sbkoz63bj:focus {
  outline-color: var(--colors-primary);
}
._1sbkoz63bk:active {
  outline-color: var(--colors-primary);
}
._1sbkoz63bl {
  outline-color: var(--colors-primary0f);
}
._1sbkoz63bm:hover {
  outline-color: var(--colors-primary0f);
}
._1sbkoz63bn:focus {
  outline-color: var(--colors-primary0f);
}
._1sbkoz63bo:active {
  outline-color: var(--colors-primary0f);
}
._1sbkoz63bp {
  outline-color: var(--colors-primary3D);
}
._1sbkoz63bq:hover {
  outline-color: var(--colors-primary3D);
}
._1sbkoz63br:focus {
  outline-color: var(--colors-primary3D);
}
._1sbkoz63bs:active {
  outline-color: var(--colors-primary3D);
}
._1sbkoz63bt {
  outline-color: var(--colors-primaryBright);
}
._1sbkoz63bu:hover {
  outline-color: var(--colors-primaryBright);
}
._1sbkoz63bv:focus {
  outline-color: var(--colors-primaryBright);
}
._1sbkoz63bw:active {
  outline-color: var(--colors-primaryBright);
}
._1sbkoz63bx {
  outline-color: var(--colors-primaryDark);
}
._1sbkoz63by:hover {
  outline-color: var(--colors-primaryDark);
}
._1sbkoz63bz:focus {
  outline-color: var(--colors-primaryDark);
}
._1sbkoz63c0:active {
  outline-color: var(--colors-primaryDark);
}
._1sbkoz63c1 {
  outline-color: var(--colors-success);
}
._1sbkoz63c2:hover {
  outline-color: var(--colors-success);
}
._1sbkoz63c3:focus {
  outline-color: var(--colors-success);
}
._1sbkoz63c4:active {
  outline-color: var(--colors-success);
}
._1sbkoz63c5 {
  outline-color: var(--colors-success19);
}
._1sbkoz63c6:hover {
  outline-color: var(--colors-success19);
}
._1sbkoz63c7:focus {
  outline-color: var(--colors-success19);
}
._1sbkoz63c8:active {
  outline-color: var(--colors-success19);
}
._1sbkoz63c9 {
  outline-color: var(--colors-warning);
}
._1sbkoz63ca:hover {
  outline-color: var(--colors-warning);
}
._1sbkoz63cb:focus {
  outline-color: var(--colors-warning);
}
._1sbkoz63cc:active {
  outline-color: var(--colors-warning);
}
._1sbkoz63cd {
  outline-color: var(--colors-warning2D);
}
._1sbkoz63ce:hover {
  outline-color: var(--colors-warning2D);
}
._1sbkoz63cf:focus {
  outline-color: var(--colors-warning2D);
}
._1sbkoz63cg:active {
  outline-color: var(--colors-warning2D);
}
._1sbkoz63ch {
  outline-color: var(--colors-warning33);
}
._1sbkoz63ci:hover {
  outline-color: var(--colors-warning33);
}
._1sbkoz63cj:focus {
  outline-color: var(--colors-warning33);
}
._1sbkoz63ck:active {
  outline-color: var(--colors-warning33);
}
._1sbkoz63cl {
  outline-color: var(--colors-linear);
}
._1sbkoz63cm:hover {
  outline-color: var(--colors-linear);
}
._1sbkoz63cn:focus {
  outline-color: var(--colors-linear);
}
._1sbkoz63co:active {
  outline-color: var(--colors-linear);
}
._1sbkoz63cp {
  outline-color: var(--colors-binance);
}
._1sbkoz63cq:hover {
  outline-color: var(--colors-binance);
}
._1sbkoz63cr:focus {
  outline-color: var(--colors-binance);
}
._1sbkoz63cs:active {
  outline-color: var(--colors-binance);
}
._1sbkoz63ct {
  outline-color: var(--colors-overlay);
}
._1sbkoz63cu:hover {
  outline-color: var(--colors-overlay);
}
._1sbkoz63cv:focus {
  outline-color: var(--colors-overlay);
}
._1sbkoz63cw:active {
  outline-color: var(--colors-overlay);
}
._1sbkoz63cx {
  outline-color: var(--colors-gold);
}
._1sbkoz63cy:hover {
  outline-color: var(--colors-gold);
}
._1sbkoz63cz:focus {
  outline-color: var(--colors-gold);
}
._1sbkoz63d0:active {
  outline-color: var(--colors-gold);
}
._1sbkoz63d1 {
  outline-color: var(--colors-silver);
}
._1sbkoz63d2:hover {
  outline-color: var(--colors-silver);
}
._1sbkoz63d3:focus {
  outline-color: var(--colors-silver);
}
._1sbkoz63d4:active {
  outline-color: var(--colors-silver);
}
._1sbkoz63d5 {
  outline-color: var(--colors-bronze);
}
._1sbkoz63d6:hover {
  outline-color: var(--colors-bronze);
}
._1sbkoz63d7:focus {
  outline-color: var(--colors-bronze);
}
._1sbkoz63d8:active {
  outline-color: var(--colors-bronze);
}
._1sbkoz63d9 {
  outline-color: var(--colors-secondary);
}
._1sbkoz63da:hover {
  outline-color: var(--colors-secondary);
}
._1sbkoz63db:focus {
  outline-color: var(--colors-secondary);
}
._1sbkoz63dc:active {
  outline-color: var(--colors-secondary);
}
._1sbkoz63dd {
  outline-color: var(--colors-secondary80);
}
._1sbkoz63de:hover {
  outline-color: var(--colors-secondary80);
}
._1sbkoz63df:focus {
  outline-color: var(--colors-secondary80);
}
._1sbkoz63dg:active {
  outline-color: var(--colors-secondary80);
}
._1sbkoz63dh {
  outline-color: var(--colors-background);
}
._1sbkoz63di:hover {
  outline-color: var(--colors-background);
}
._1sbkoz63dj:focus {
  outline-color: var(--colors-background);
}
._1sbkoz63dk:active {
  outline-color: var(--colors-background);
}
._1sbkoz63dl {
  outline-color: var(--colors-backgroundDisabled);
}
._1sbkoz63dm:hover {
  outline-color: var(--colors-backgroundDisabled);
}
._1sbkoz63dn:focus {
  outline-color: var(--colors-backgroundDisabled);
}
._1sbkoz63do:active {
  outline-color: var(--colors-backgroundDisabled);
}
._1sbkoz63dp {
  outline-color: var(--colors-backgroundAlt);
}
._1sbkoz63dq:hover {
  outline-color: var(--colors-backgroundAlt);
}
._1sbkoz63dr:focus {
  outline-color: var(--colors-backgroundAlt);
}
._1sbkoz63ds:active {
  outline-color: var(--colors-backgroundAlt);
}
._1sbkoz63dt {
  outline-color: var(--colors-backgroundAlt2);
}
._1sbkoz63du:hover {
  outline-color: var(--colors-backgroundAlt2);
}
._1sbkoz63dv:focus {
  outline-color: var(--colors-backgroundAlt2);
}
._1sbkoz63dw:active {
  outline-color: var(--colors-backgroundAlt2);
}
._1sbkoz63dx {
  outline-color: var(--colors-cardBorder);
}
._1sbkoz63dy:hover {
  outline-color: var(--colors-cardBorder);
}
._1sbkoz63dz:focus {
  outline-color: var(--colors-cardBorder);
}
._1sbkoz63e0:active {
  outline-color: var(--colors-cardBorder);
}
._1sbkoz63e1 {
  outline-color: var(--colors-contrast);
}
._1sbkoz63e2:hover {
  outline-color: var(--colors-contrast);
}
._1sbkoz63e3:focus {
  outline-color: var(--colors-contrast);
}
._1sbkoz63e4:active {
  outline-color: var(--colors-contrast);
}
._1sbkoz63e5 {
  outline-color: var(--colors-dropdown);
}
._1sbkoz63e6:hover {
  outline-color: var(--colors-dropdown);
}
._1sbkoz63e7:focus {
  outline-color: var(--colors-dropdown);
}
._1sbkoz63e8:active {
  outline-color: var(--colors-dropdown);
}
._1sbkoz63e9 {
  outline-color: var(--colors-dropdownDeep);
}
._1sbkoz63ea:hover {
  outline-color: var(--colors-dropdownDeep);
}
._1sbkoz63eb:focus {
  outline-color: var(--colors-dropdownDeep);
}
._1sbkoz63ec:active {
  outline-color: var(--colors-dropdownDeep);
}
._1sbkoz63ed {
  outline-color: var(--colors-invertedContrast);
}
._1sbkoz63ee:hover {
  outline-color: var(--colors-invertedContrast);
}
._1sbkoz63ef:focus {
  outline-color: var(--colors-invertedContrast);
}
._1sbkoz63eg:active {
  outline-color: var(--colors-invertedContrast);
}
._1sbkoz63eh {
  outline-color: var(--colors-input);
}
._1sbkoz63ei:hover {
  outline-color: var(--colors-input);
}
._1sbkoz63ej:focus {
  outline-color: var(--colors-input);
}
._1sbkoz63ek:active {
  outline-color: var(--colors-input);
}
._1sbkoz63el {
  outline-color: var(--colors-inputSecondary);
}
._1sbkoz63em:hover {
  outline-color: var(--colors-inputSecondary);
}
._1sbkoz63en:focus {
  outline-color: var(--colors-inputSecondary);
}
._1sbkoz63eo:active {
  outline-color: var(--colors-inputSecondary);
}
._1sbkoz63ep {
  outline-color: var(--colors-tertiary);
}
._1sbkoz63eq:hover {
  outline-color: var(--colors-tertiary);
}
._1sbkoz63er:focus {
  outline-color: var(--colors-tertiary);
}
._1sbkoz63es:active {
  outline-color: var(--colors-tertiary);
}
._1sbkoz63et {
  outline-color: var(--colors-text);
}
._1sbkoz63eu:hover {
  outline-color: var(--colors-text);
}
._1sbkoz63ev:focus {
  outline-color: var(--colors-text);
}
._1sbkoz63ew:active {
  outline-color: var(--colors-text);
}
._1sbkoz63ex {
  outline-color: var(--colors-text99);
}
._1sbkoz63ey:hover {
  outline-color: var(--colors-text99);
}
._1sbkoz63ez:focus {
  outline-color: var(--colors-text99);
}
._1sbkoz63f0:active {
  outline-color: var(--colors-text99);
}
._1sbkoz63f1 {
  outline-color: var(--colors-textDisabled);
}
._1sbkoz63f2:hover {
  outline-color: var(--colors-textDisabled);
}
._1sbkoz63f3:focus {
  outline-color: var(--colors-textDisabled);
}
._1sbkoz63f4:active {
  outline-color: var(--colors-textDisabled);
}
._1sbkoz63f5 {
  outline-color: var(--colors-textSubtle);
}
._1sbkoz63f6:hover {
  outline-color: var(--colors-textSubtle);
}
._1sbkoz63f7:focus {
  outline-color: var(--colors-textSubtle);
}
._1sbkoz63f8:active {
  outline-color: var(--colors-textSubtle);
}
._1sbkoz63f9 {
  outline-color: var(--colors-disabled);
}
._1sbkoz63fa:hover {
  outline-color: var(--colors-disabled);
}
._1sbkoz63fb:focus {
  outline-color: var(--colors-disabled);
}
._1sbkoz63fc:active {
  outline-color: var(--colors-disabled);
}
._1sbkoz63fd {
  outline-color: var(--colors-gradientBubblegum);
}
._1sbkoz63fe:hover {
  outline-color: var(--colors-gradientBubblegum);
}
._1sbkoz63ff:focus {
  outline-color: var(--colors-gradientBubblegum);
}
._1sbkoz63fg:active {
  outline-color: var(--colors-gradientBubblegum);
}
._1sbkoz63fh {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz63fi:hover {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz63fj:focus {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz63fk:active {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1sbkoz63fl {
  outline-color: var(--colors-gradientCardHeader);
}
._1sbkoz63fm:hover {
  outline-color: var(--colors-gradientCardHeader);
}
._1sbkoz63fn:focus {
  outline-color: var(--colors-gradientCardHeader);
}
._1sbkoz63fo:active {
  outline-color: var(--colors-gradientCardHeader);
}
._1sbkoz63fp {
  outline-color: var(--colors-gradientBlue);
}
._1sbkoz63fq:hover {
  outline-color: var(--colors-gradientBlue);
}
._1sbkoz63fr:focus {
  outline-color: var(--colors-gradientBlue);
}
._1sbkoz63fs:active {
  outline-color: var(--colors-gradientBlue);
}
._1sbkoz63ft {
  outline-color: var(--colors-gradientViolet);
}
._1sbkoz63fu:hover {
  outline-color: var(--colors-gradientViolet);
}
._1sbkoz63fv:focus {
  outline-color: var(--colors-gradientViolet);
}
._1sbkoz63fw:active {
  outline-color: var(--colors-gradientViolet);
}
._1sbkoz63fx {
  outline-color: var(--colors-gradientVioletAlt);
}
._1sbkoz63fy:hover {
  outline-color: var(--colors-gradientVioletAlt);
}
._1sbkoz63fz:focus {
  outline-color: var(--colors-gradientVioletAlt);
}
._1sbkoz63g0:active {
  outline-color: var(--colors-gradientVioletAlt);
}
._1sbkoz63g1 {
  outline-color: var(--colors-gradientGold);
}
._1sbkoz63g2:hover {
  outline-color: var(--colors-gradientGold);
}
._1sbkoz63g3:focus {
  outline-color: var(--colors-gradientGold);
}
._1sbkoz63g4:active {
  outline-color: var(--colors-gradientGold);
}
._1sbkoz63g5 {
  outline-color: var(--colors-backgroundImage);
}
._1sbkoz63g6:hover {
  outline-color: var(--colors-backgroundImage);
}
._1sbkoz63g7:focus {
  outline-color: var(--colors-backgroundImage);
}
._1sbkoz63g8:active {
  outline-color: var(--colors-backgroundImage);
}
._1sbkoz63g9 {
  outline-color: var(--colors-backgroundBtn);
}
._1sbkoz63ga:hover {
  outline-color: var(--colors-backgroundBtn);
}
._1sbkoz63gb:focus {
  outline-color: var(--colors-backgroundBtn);
}
._1sbkoz63gc:active {
  outline-color: var(--colors-backgroundBtn);
}
._1sbkoz63gd {
  outline-color: var(--colors-textcard);
}
._1sbkoz63ge:hover {
  outline-color: var(--colors-textcard);
}
._1sbkoz63gf:focus {
  outline-color: var(--colors-textcard);
}
._1sbkoz63gg:active {
  outline-color: var(--colors-textcard);
}
._1sbkoz63gh {
  outline-color: var(--colors-bgrcard);
}
._1sbkoz63gi:hover {
  outline-color: var(--colors-bgrcard);
}
._1sbkoz63gj:focus {
  outline-color: var(--colors-bgrcard);
}
._1sbkoz63gk:active {
  outline-color: var(--colors-bgrcard);
}
@media (min-width: 576px) {
  ._1sbkoz61 {
    display: block;
  }
  ._1sbkoz67 {
    display: flex;
  }
  ._1sbkoz6d {
    display: grid;
  }
  ._1sbkoz6j {
    display: inline;
  }
  ._1sbkoz6p {
    display: inline-flex;
  }
  ._1sbkoz6v {
    display: inline-block;
  }
  ._1sbkoz611 {
    display: none;
  }
  ._1sbkoz617 {
    display: contents;
  }
  ._1sbkoz61d {
    flex-direction: column;
  }
  ._1sbkoz61j {
    flex-direction: row;
  }
  ._1sbkoz61p {
    flex-direction: column-reverse;
  }
  ._1sbkoz61v {
    align-items: center;
  }
  ._1sbkoz621 {
    align-items: end;
  }
  ._1sbkoz627 {
    align-items: baseLine;
  }
  ._1sbkoz62d {
    align-items: inherit;
  }
  ._1sbkoz62j {
    align-items: flex-start;
  }
  ._1sbkoz62p {
    align-items: center;
  }
  ._1sbkoz62v {
    align-items: start;
  }
  ._1sbkoz631 {
    align-items: flex-end;
  }
  ._1sbkoz637 {
    align-items: stretch;
  }
  ._1sbkoz63d {
    flex-wrap: wrap;
  }
  ._1sbkoz63j {
    flex-wrap: nowrap;
  }
  ._1sbkoz63p {
    overflow: auto;
  }
  ._1sbkoz63v {
    overflow: hidden;
  }
  ._1sbkoz641 {
    overflow: scroll;
  }
  ._1sbkoz647 {
    overflow: unset;
  }
  ._1sbkoz64d {
    overflow-y: auto;
  }
  ._1sbkoz64j {
    overflow-y: hidden;
  }
  ._1sbkoz64p {
    overflow-y: scroll;
  }
  ._1sbkoz64v {
    overflow-x: auto;
  }
  ._1sbkoz651 {
    overflow-x: hidden;
  }
  ._1sbkoz657 {
    overflow-x: scroll;
  }
  ._1sbkoz65d {
    position: absolute;
  }
  ._1sbkoz65j {
    position: fixed;
  }
  ._1sbkoz65p {
    position: relative;
  }
  ._1sbkoz65v {
    position: -webkit-sticky;
    position: sticky;
  }
  ._1sbkoz661 {
    text-align: center;
  }
  ._1sbkoz667 {
    text-align: left;
  }
  ._1sbkoz66d {
    text-align: right;
  }
  ._1sbkoz66j {
    justify-content: flex-start;
  }
  ._1sbkoz66p {
    justify-content: center;
  }
  ._1sbkoz66v {
    justify-content: start;
  }
  ._1sbkoz671 {
    justify-content: flex-end;
  }
  ._1sbkoz677 {
    justify-content: stretch;
  }
  ._1sbkoz67d {
    justify-content: space-around;
  }
  ._1sbkoz67j {
    justify-content: space-between;
  }
  ._1sbkoz67p {
    justify-items: flex-start;
  }
  ._1sbkoz67v {
    justify-items: center;
  }
  ._1sbkoz681 {
    justify-items: start;
  }
  ._1sbkoz687 {
    justify-items: flex-end;
  }
  ._1sbkoz68d {
    justify-items: stretch;
  }
  ._1sbkoz68j {
    justify-self: flex-start;
  }
  ._1sbkoz68p {
    justify-self: center;
  }
  ._1sbkoz68v {
    justify-self: start;
  }
  ._1sbkoz691 {
    justify-self: flex-end;
  }
  ._1sbkoz697 {
    justify-self: stretch;
  }
  ._1sbkoz69d {
    inset: var(--space-0);
  }
  ._1sbkoz69j {
    inset: var(--space-1);
  }
  ._1sbkoz69p {
    inset: var(--space-2);
  }
  ._1sbkoz69v {
    inset: var(--space-3);
  }
  ._1sbkoz6a1 {
    inset: var(--space-4);
  }
  ._1sbkoz6a7 {
    inset: var(--space-5);
  }
  ._1sbkoz6ad {
    inset: var(--space-6);
  }
  ._1sbkoz6aj {
    inset: var(--space-7);
  }
  ._1sbkoz6ap {
    inset: var(--space-px);
  }
  ._1sbkoz6av {
    inset: var(--space-4px);
  }
  ._1sbkoz6b1 {
    inset: var(--space-8px);
  }
  ._1sbkoz6b7 {
    inset: var(--space-12px);
  }
  ._1sbkoz6bd {
    inset: var(--space-16px);
  }
  ._1sbkoz6bj {
    inset: var(--space-24px);
  }
  ._1sbkoz6bp {
    inset: var(--space-32px);
  }
  ._1sbkoz6bv {
    inset: var(--space-48px);
  }
  ._1sbkoz6c1 {
    inset: var(--space-56px);
  }
  ._1sbkoz6c7 {
    inset: var(--space-64px);
  }
  ._1sbkoz6cd {
    inset: calc(var(--space-px) * -1);
  }
  ._1sbkoz6cj {
    inset: calc(var(--space-1) * -1);
  }
  ._1sbkoz6cp {
    inset: calc(var(--space-2) * -1);
  }
  ._1sbkoz6cv {
    inset: calc(var(--space-3) * -1);
  }
  ._1sbkoz6d1 {
    inset: calc(var(--space-4) * -1);
  }
  ._1sbkoz6d7 {
    inset: calc(var(--space-5) * -1);
  }
  ._1sbkoz6dd {
    inset: calc(var(--space-6) * -1);
  }
  ._1sbkoz6dj {
    inset: calc(var(--space-7) * -1);
  }
  ._1sbkoz6dp {
    height: var(--space-0);
  }
  ._1sbkoz6dv {
    height: var(--space-1);
  }
  ._1sbkoz6e1 {
    height: var(--space-2);
  }
  ._1sbkoz6e7 {
    height: var(--space-3);
  }
  ._1sbkoz6ed {
    height: var(--space-4);
  }
  ._1sbkoz6ej {
    height: var(--space-5);
  }
  ._1sbkoz6ep {
    height: var(--space-6);
  }
  ._1sbkoz6ev {
    height: var(--space-7);
  }
  ._1sbkoz6f1 {
    height: var(--space-px);
  }
  ._1sbkoz6f7 {
    height: var(--space-4px);
  }
  ._1sbkoz6fd {
    height: var(--space-8px);
  }
  ._1sbkoz6fj {
    height: var(--space-12px);
  }
  ._1sbkoz6fp {
    height: var(--space-16px);
  }
  ._1sbkoz6fv {
    height: var(--space-24px);
  }
  ._1sbkoz6g1 {
    height: var(--space-32px);
  }
  ._1sbkoz6g7 {
    height: var(--space-48px);
  }
  ._1sbkoz6gd {
    height: var(--space-56px);
  }
  ._1sbkoz6gj {
    height: var(--space-64px);
  }
  ._1sbkoz6gp {
    height: 100%;
  }
  ._1sbkoz6gv {
    height: 100%;
  }
  ._1sbkoz6h1 {
    height: auto;
  }
  ._1sbkoz6h7 {
    height: 576px;
  }
  ._1sbkoz6hd {
    height: 852px;
  }
  ._1sbkoz6hj {
    height: 968px;
  }
  ._1sbkoz6hp {
    height: 1080px;
  }
  ._1sbkoz6hv {
    left: var(--space-0);
  }
  ._1sbkoz6i1 {
    left: var(--space-1);
  }
  ._1sbkoz6i7 {
    left: var(--space-2);
  }
  ._1sbkoz6id {
    left: var(--space-3);
  }
  ._1sbkoz6ij {
    left: var(--space-4);
  }
  ._1sbkoz6ip {
    left: var(--space-5);
  }
  ._1sbkoz6iv {
    left: var(--space-6);
  }
  ._1sbkoz6j1 {
    left: var(--space-7);
  }
  ._1sbkoz6j7 {
    left: var(--space-px);
  }
  ._1sbkoz6jd {
    left: var(--space-4px);
  }
  ._1sbkoz6jj {
    left: var(--space-8px);
  }
  ._1sbkoz6jp {
    left: var(--space-12px);
  }
  ._1sbkoz6jv {
    left: var(--space-16px);
  }
  ._1sbkoz6k1 {
    left: var(--space-24px);
  }
  ._1sbkoz6k7 {
    left: var(--space-32px);
  }
  ._1sbkoz6kd {
    left: var(--space-48px);
  }
  ._1sbkoz6kj {
    left: var(--space-56px);
  }
  ._1sbkoz6kp {
    left: var(--space-64px);
  }
  ._1sbkoz6kv {
    left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6l1 {
    left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6l7 {
    left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6ld {
    left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6lj {
    left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6lp {
    left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6lv {
    left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6m1 {
    left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6m7 {
    margin-bottom: var(--space-0);
  }
  ._1sbkoz6md {
    margin-bottom: var(--space-1);
  }
  ._1sbkoz6mj {
    margin-bottom: var(--space-2);
  }
  ._1sbkoz6mp {
    margin-bottom: var(--space-3);
  }
  ._1sbkoz6mv {
    margin-bottom: var(--space-4);
  }
  ._1sbkoz6n1 {
    margin-bottom: var(--space-5);
  }
  ._1sbkoz6n7 {
    margin-bottom: var(--space-6);
  }
  ._1sbkoz6nd {
    margin-bottom: var(--space-7);
  }
  ._1sbkoz6nj {
    margin-bottom: var(--space-px);
  }
  ._1sbkoz6np {
    margin-bottom: var(--space-4px);
  }
  ._1sbkoz6nv {
    margin-bottom: var(--space-8px);
  }
  ._1sbkoz6o1 {
    margin-bottom: var(--space-12px);
  }
  ._1sbkoz6o7 {
    margin-bottom: var(--space-16px);
  }
  ._1sbkoz6od {
    margin-bottom: var(--space-24px);
  }
  ._1sbkoz6oj {
    margin-bottom: var(--space-32px);
  }
  ._1sbkoz6op {
    margin-bottom: var(--space-48px);
  }
  ._1sbkoz6ov {
    margin-bottom: var(--space-56px);
  }
  ._1sbkoz6p1 {
    margin-bottom: var(--space-64px);
  }
  ._1sbkoz6p7 {
    margin-bottom: auto;
  }
  ._1sbkoz6pd {
    margin-bottom: calc(var(--space-px) * -1);
  }
  ._1sbkoz6pj {
    margin-bottom: calc(var(--space-1) * -1);
  }
  ._1sbkoz6pp {
    margin-bottom: calc(var(--space-2) * -1);
  }
  ._1sbkoz6pv {
    margin-bottom: calc(var(--space-3) * -1);
  }
  ._1sbkoz6q1 {
    margin-bottom: calc(var(--space-4) * -1);
  }
  ._1sbkoz6q7 {
    margin-bottom: calc(var(--space-5) * -1);
  }
  ._1sbkoz6qd {
    margin-bottom: calc(var(--space-6) * -1);
  }
  ._1sbkoz6qj {
    margin-bottom: calc(var(--space-7) * -1);
  }
  ._1sbkoz6qp {
    margin-left: var(--space-0);
  }
  ._1sbkoz6qv {
    margin-left: var(--space-1);
  }
  ._1sbkoz6r1 {
    margin-left: var(--space-2);
  }
  ._1sbkoz6r7 {
    margin-left: var(--space-3);
  }
  ._1sbkoz6rd {
    margin-left: var(--space-4);
  }
  ._1sbkoz6rj {
    margin-left: var(--space-5);
  }
  ._1sbkoz6rp {
    margin-left: var(--space-6);
  }
  ._1sbkoz6rv {
    margin-left: var(--space-7);
  }
  ._1sbkoz6s1 {
    margin-left: var(--space-px);
  }
  ._1sbkoz6s7 {
    margin-left: var(--space-4px);
  }
  ._1sbkoz6sd {
    margin-left: var(--space-8px);
  }
  ._1sbkoz6sj {
    margin-left: var(--space-12px);
  }
  ._1sbkoz6sp {
    margin-left: var(--space-16px);
  }
  ._1sbkoz6sv {
    margin-left: var(--space-24px);
  }
  ._1sbkoz6t1 {
    margin-left: var(--space-32px);
  }
  ._1sbkoz6t7 {
    margin-left: var(--space-48px);
  }
  ._1sbkoz6td {
    margin-left: var(--space-56px);
  }
  ._1sbkoz6tj {
    margin-left: var(--space-64px);
  }
  ._1sbkoz6tp {
    margin-left: auto;
  }
  ._1sbkoz6tv {
    margin-left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6u1 {
    margin-left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6u7 {
    margin-left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6ud {
    margin-left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6uj {
    margin-left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6up {
    margin-left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6uv {
    margin-left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6v1 {
    margin-left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6v7 {
    margin-right: var(--space-0);
  }
  ._1sbkoz6vd {
    margin-right: var(--space-1);
  }
  ._1sbkoz6vj {
    margin-right: var(--space-2);
  }
  ._1sbkoz6vp {
    margin-right: var(--space-3);
  }
  ._1sbkoz6vv {
    margin-right: var(--space-4);
  }
  ._1sbkoz6w1 {
    margin-right: var(--space-5);
  }
  ._1sbkoz6w7 {
    margin-right: var(--space-6);
  }
  ._1sbkoz6wd {
    margin-right: var(--space-7);
  }
  ._1sbkoz6wj {
    margin-right: var(--space-px);
  }
  ._1sbkoz6wp {
    margin-right: var(--space-4px);
  }
  ._1sbkoz6wv {
    margin-right: var(--space-8px);
  }
  ._1sbkoz6x1 {
    margin-right: var(--space-12px);
  }
  ._1sbkoz6x7 {
    margin-right: var(--space-16px);
  }
  ._1sbkoz6xd {
    margin-right: var(--space-24px);
  }
  ._1sbkoz6xj {
    margin-right: var(--space-32px);
  }
  ._1sbkoz6xp {
    margin-right: var(--space-48px);
  }
  ._1sbkoz6xv {
    margin-right: var(--space-56px);
  }
  ._1sbkoz6y1 {
    margin-right: var(--space-64px);
  }
  ._1sbkoz6y7 {
    margin-right: auto;
  }
  ._1sbkoz6yd {
    margin-right: calc(var(--space-px) * -1);
  }
  ._1sbkoz6yj {
    margin-right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6yp {
    margin-right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6yv {
    margin-right: calc(var(--space-3) * -1);
  }
  ._1sbkoz6z1 {
    margin-right: calc(var(--space-4) * -1);
  }
  ._1sbkoz6z7 {
    margin-right: calc(var(--space-5) * -1);
  }
  ._1sbkoz6zd {
    margin-right: calc(var(--space-6) * -1);
  }
  ._1sbkoz6zj {
    margin-right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6zp {
    margin-top: var(--space-0);
  }
  ._1sbkoz6zv {
    margin-top: var(--space-1);
  }
  ._1sbkoz6101 {
    margin-top: var(--space-2);
  }
  ._1sbkoz6107 {
    margin-top: var(--space-3);
  }
  ._1sbkoz610d {
    margin-top: var(--space-4);
  }
  ._1sbkoz610j {
    margin-top: var(--space-5);
  }
  ._1sbkoz610p {
    margin-top: var(--space-6);
  }
  ._1sbkoz610v {
    margin-top: var(--space-7);
  }
  ._1sbkoz6111 {
    margin-top: var(--space-px);
  }
  ._1sbkoz6117 {
    margin-top: var(--space-4px);
  }
  ._1sbkoz611d {
    margin-top: var(--space-8px);
  }
  ._1sbkoz611j {
    margin-top: var(--space-12px);
  }
  ._1sbkoz611p {
    margin-top: var(--space-16px);
  }
  ._1sbkoz611v {
    margin-top: var(--space-24px);
  }
  ._1sbkoz6121 {
    margin-top: var(--space-32px);
  }
  ._1sbkoz6127 {
    margin-top: var(--space-48px);
  }
  ._1sbkoz612d {
    margin-top: var(--space-56px);
  }
  ._1sbkoz612j {
    margin-top: var(--space-64px);
  }
  ._1sbkoz612p {
    margin-top: auto;
  }
  ._1sbkoz612v {
    margin-top: calc(var(--space-px) * -1);
  }
  ._1sbkoz6131 {
    margin-top: calc(var(--space-1) * -1);
  }
  ._1sbkoz6137 {
    margin-top: calc(var(--space-2) * -1);
  }
  ._1sbkoz613d {
    margin-top: calc(var(--space-3) * -1);
  }
  ._1sbkoz613j {
    margin-top: calc(var(--space-4) * -1);
  }
  ._1sbkoz613p {
    margin-top: calc(var(--space-5) * -1);
  }
  ._1sbkoz613v {
    margin-top: calc(var(--space-6) * -1);
  }
  ._1sbkoz6141 {
    margin-top: calc(var(--space-7) * -1);
  }
  ._1sbkoz6147 {
    margin: var(--space-0);
  }
  ._1sbkoz614d {
    margin: var(--space-1);
  }
  ._1sbkoz614j {
    margin: var(--space-2);
  }
  ._1sbkoz614p {
    margin: var(--space-3);
  }
  ._1sbkoz614v {
    margin: var(--space-4);
  }
  ._1sbkoz6151 {
    margin: var(--space-5);
  }
  ._1sbkoz6157 {
    margin: var(--space-6);
  }
  ._1sbkoz615d {
    margin: var(--space-7);
  }
  ._1sbkoz615j {
    margin: var(--space-px);
  }
  ._1sbkoz615p {
    margin: var(--space-4px);
  }
  ._1sbkoz615v {
    margin: var(--space-8px);
  }
  ._1sbkoz6161 {
    margin: var(--space-12px);
  }
  ._1sbkoz6167 {
    margin: var(--space-16px);
  }
  ._1sbkoz616d {
    margin: var(--space-24px);
  }
  ._1sbkoz616j {
    margin: var(--space-32px);
  }
  ._1sbkoz616p {
    margin: var(--space-48px);
  }
  ._1sbkoz616v {
    margin: var(--space-56px);
  }
  ._1sbkoz6171 {
    margin: var(--space-64px);
  }
  ._1sbkoz6177 {
    margin: auto;
  }
  ._1sbkoz617d {
    margin: calc(var(--space-px) * -1);
  }
  ._1sbkoz617j {
    margin: calc(var(--space-1) * -1);
  }
  ._1sbkoz617p {
    margin: calc(var(--space-2) * -1);
  }
  ._1sbkoz617v {
    margin: calc(var(--space-3) * -1);
  }
  ._1sbkoz6181 {
    margin: calc(var(--space-4) * -1);
  }
  ._1sbkoz6187 {
    margin: calc(var(--space-5) * -1);
  }
  ._1sbkoz618d {
    margin: calc(var(--space-6) * -1);
  }
  ._1sbkoz618j {
    margin: calc(var(--space-7) * -1);
  }
  ._1sbkoz618p {
    padding: var(--space-0);
  }
  ._1sbkoz618v {
    padding: var(--space-1);
  }
  ._1sbkoz6191 {
    padding: var(--space-2);
  }
  ._1sbkoz6197 {
    padding: var(--space-3);
  }
  ._1sbkoz619d {
    padding: var(--space-4);
  }
  ._1sbkoz619j {
    padding: var(--space-5);
  }
  ._1sbkoz619p {
    padding: var(--space-6);
  }
  ._1sbkoz619v {
    padding: var(--space-7);
  }
  ._1sbkoz61a1 {
    padding: var(--space-px);
  }
  ._1sbkoz61a7 {
    padding: var(--space-4px);
  }
  ._1sbkoz61ad {
    padding: var(--space-8px);
  }
  ._1sbkoz61aj {
    padding: var(--space-12px);
  }
  ._1sbkoz61ap {
    padding: var(--space-16px);
  }
  ._1sbkoz61av {
    padding: var(--space-24px);
  }
  ._1sbkoz61b1 {
    padding: var(--space-32px);
  }
  ._1sbkoz61b7 {
    padding: var(--space-48px);
  }
  ._1sbkoz61bd {
    padding: var(--space-56px);
  }
  ._1sbkoz61bj {
    padding: var(--space-64px);
  }
  ._1sbkoz61bp {
    padding: auto;
  }
  ._1sbkoz61bv {
    padding: calc(var(--space-px) * -1);
  }
  ._1sbkoz61c1 {
    padding: calc(var(--space-1) * -1);
  }
  ._1sbkoz61c7 {
    padding: calc(var(--space-2) * -1);
  }
  ._1sbkoz61cd {
    padding: calc(var(--space-3) * -1);
  }
  ._1sbkoz61cj {
    padding: calc(var(--space-4) * -1);
  }
  ._1sbkoz61cp {
    padding: calc(var(--space-5) * -1);
  }
  ._1sbkoz61cv {
    padding: calc(var(--space-6) * -1);
  }
  ._1sbkoz61d1 {
    padding: calc(var(--space-7) * -1);
  }
  ._1sbkoz61d7 {
    max-height: var(--space-0);
  }
  ._1sbkoz61dd {
    max-height: var(--space-1);
  }
  ._1sbkoz61dj {
    max-height: var(--space-2);
  }
  ._1sbkoz61dp {
    max-height: var(--space-3);
  }
  ._1sbkoz61dv {
    max-height: var(--space-4);
  }
  ._1sbkoz61e1 {
    max-height: var(--space-5);
  }
  ._1sbkoz61e7 {
    max-height: var(--space-6);
  }
  ._1sbkoz61ed {
    max-height: var(--space-7);
  }
  ._1sbkoz61ej {
    max-height: var(--space-px);
  }
  ._1sbkoz61ep {
    max-height: var(--space-4px);
  }
  ._1sbkoz61ev {
    max-height: var(--space-8px);
  }
  ._1sbkoz61f1 {
    max-height: var(--space-12px);
  }
  ._1sbkoz61f7 {
    max-height: var(--space-16px);
  }
  ._1sbkoz61fd {
    max-height: var(--space-24px);
  }
  ._1sbkoz61fj {
    max-height: var(--space-32px);
  }
  ._1sbkoz61fp {
    max-height: var(--space-48px);
  }
  ._1sbkoz61fv {
    max-height: var(--space-56px);
  }
  ._1sbkoz61g1 {
    max-height: var(--space-64px);
  }
  ._1sbkoz61g7 {
    max-width: var(--space-0);
  }
  ._1sbkoz61gd {
    max-width: var(--space-1);
  }
  ._1sbkoz61gj {
    max-width: var(--space-2);
  }
  ._1sbkoz61gp {
    max-width: var(--space-3);
  }
  ._1sbkoz61gv {
    max-width: var(--space-4);
  }
  ._1sbkoz61h1 {
    max-width: var(--space-5);
  }
  ._1sbkoz61h7 {
    max-width: var(--space-6);
  }
  ._1sbkoz61hd {
    max-width: var(--space-7);
  }
  ._1sbkoz61hj {
    max-width: var(--space-px);
  }
  ._1sbkoz61hp {
    max-width: var(--space-4px);
  }
  ._1sbkoz61hv {
    max-width: var(--space-8px);
  }
  ._1sbkoz61i1 {
    max-width: var(--space-12px);
  }
  ._1sbkoz61i7 {
    max-width: var(--space-16px);
  }
  ._1sbkoz61id {
    max-width: var(--space-24px);
  }
  ._1sbkoz61ij {
    max-width: var(--space-32px);
  }
  ._1sbkoz61ip {
    max-width: var(--space-48px);
  }
  ._1sbkoz61iv {
    max-width: var(--space-56px);
  }
  ._1sbkoz61j1 {
    max-width: var(--space-64px);
  }
  ._1sbkoz61j7 {
    max-width: 100%;
  }
  ._1sbkoz61jd {
    max-width: 100%;
  }
  ._1sbkoz61jj {
    max-width: auto;
  }
  ._1sbkoz61jp {
    max-width: 576px;
  }
  ._1sbkoz61jv {
    max-width: 852px;
  }
  ._1sbkoz61k1 {
    max-width: 968px;
  }
  ._1sbkoz61k7 {
    max-width: 1080px;
  }
  ._1sbkoz61kd {
    max-width: none;
  }
  ._1sbkoz61kj {
    min-height: var(--space-0);
  }
  ._1sbkoz61kp {
    min-height: var(--space-1);
  }
  ._1sbkoz61kv {
    min-height: var(--space-2);
  }
  ._1sbkoz61l1 {
    min-height: var(--space-3);
  }
  ._1sbkoz61l7 {
    min-height: var(--space-4);
  }
  ._1sbkoz61ld {
    min-height: var(--space-5);
  }
  ._1sbkoz61lj {
    min-height: var(--space-6);
  }
  ._1sbkoz61lp {
    min-height: var(--space-7);
  }
  ._1sbkoz61lv {
    min-height: var(--space-px);
  }
  ._1sbkoz61m1 {
    min-height: var(--space-4px);
  }
  ._1sbkoz61m7 {
    min-height: var(--space-8px);
  }
  ._1sbkoz61md {
    min-height: var(--space-12px);
  }
  ._1sbkoz61mj {
    min-height: var(--space-16px);
  }
  ._1sbkoz61mp {
    min-height: var(--space-24px);
  }
  ._1sbkoz61mv {
    min-height: var(--space-32px);
  }
  ._1sbkoz61n1 {
    min-height: var(--space-48px);
  }
  ._1sbkoz61n7 {
    min-height: var(--space-56px);
  }
  ._1sbkoz61nd {
    min-height: var(--space-64px);
  }
  ._1sbkoz61nj {
    min-width: var(--space-0);
  }
  ._1sbkoz61np {
    min-width: var(--space-1);
  }
  ._1sbkoz61nv {
    min-width: var(--space-2);
  }
  ._1sbkoz61o1 {
    min-width: var(--space-3);
  }
  ._1sbkoz61o7 {
    min-width: var(--space-4);
  }
  ._1sbkoz61od {
    min-width: var(--space-5);
  }
  ._1sbkoz61oj {
    min-width: var(--space-6);
  }
  ._1sbkoz61op {
    min-width: var(--space-7);
  }
  ._1sbkoz61ov {
    min-width: var(--space-px);
  }
  ._1sbkoz61p1 {
    min-width: var(--space-4px);
  }
  ._1sbkoz61p7 {
    min-width: var(--space-8px);
  }
  ._1sbkoz61pd {
    min-width: var(--space-12px);
  }
  ._1sbkoz61pj {
    min-width: var(--space-16px);
  }
  ._1sbkoz61pp {
    min-width: var(--space-24px);
  }
  ._1sbkoz61pv {
    min-width: var(--space-32px);
  }
  ._1sbkoz61q1 {
    min-width: var(--space-48px);
  }
  ._1sbkoz61q7 {
    min-width: var(--space-56px);
  }
  ._1sbkoz61qd {
    min-width: var(--space-64px);
  }
  ._1sbkoz61qj {
    padding-bottom: var(--space-0);
  }
  ._1sbkoz61qp {
    padding-bottom: var(--space-1);
  }
  ._1sbkoz61qv {
    padding-bottom: var(--space-2);
  }
  ._1sbkoz61r1 {
    padding-bottom: var(--space-3);
  }
  ._1sbkoz61r7 {
    padding-bottom: var(--space-4);
  }
  ._1sbkoz61rd {
    padding-bottom: var(--space-5);
  }
  ._1sbkoz61rj {
    padding-bottom: var(--space-6);
  }
  ._1sbkoz61rp {
    padding-bottom: var(--space-7);
  }
  ._1sbkoz61rv {
    padding-bottom: var(--space-px);
  }
  ._1sbkoz61s1 {
    padding-bottom: var(--space-4px);
  }
  ._1sbkoz61s7 {
    padding-bottom: var(--space-8px);
  }
  ._1sbkoz61sd {
    padding-bottom: var(--space-12px);
  }
  ._1sbkoz61sj {
    padding-bottom: var(--space-16px);
  }
  ._1sbkoz61sp {
    padding-bottom: var(--space-24px);
  }
  ._1sbkoz61sv {
    padding-bottom: var(--space-32px);
  }
  ._1sbkoz61t1 {
    padding-bottom: var(--space-48px);
  }
  ._1sbkoz61t7 {
    padding-bottom: var(--space-56px);
  }
  ._1sbkoz61td {
    padding-bottom: var(--space-64px);
  }
  ._1sbkoz61tj {
    padding-left: var(--space-0);
  }
  ._1sbkoz61tp {
    padding-left: var(--space-1);
  }
  ._1sbkoz61tv {
    padding-left: var(--space-2);
  }
  ._1sbkoz61u1 {
    padding-left: var(--space-3);
  }
  ._1sbkoz61u7 {
    padding-left: var(--space-4);
  }
  ._1sbkoz61ud {
    padding-left: var(--space-5);
  }
  ._1sbkoz61uj {
    padding-left: var(--space-6);
  }
  ._1sbkoz61up {
    padding-left: var(--space-7);
  }
  ._1sbkoz61uv {
    padding-left: var(--space-px);
  }
  ._1sbkoz61v1 {
    padding-left: var(--space-4px);
  }
  ._1sbkoz61v7 {
    padding-left: var(--space-8px);
  }
  ._1sbkoz61vd {
    padding-left: var(--space-12px);
  }
  ._1sbkoz61vj {
    padding-left: var(--space-16px);
  }
  ._1sbkoz61vp {
    padding-left: var(--space-24px);
  }
  ._1sbkoz61vv {
    padding-left: var(--space-32px);
  }
  ._1sbkoz61w1 {
    padding-left: var(--space-48px);
  }
  ._1sbkoz61w7 {
    padding-left: var(--space-56px);
  }
  ._1sbkoz61wd {
    padding-left: var(--space-64px);
  }
  ._1sbkoz61wj {
    padding-right: var(--space-0);
  }
  ._1sbkoz61wp {
    padding-right: var(--space-1);
  }
  ._1sbkoz61wv {
    padding-right: var(--space-2);
  }
  ._1sbkoz61x1 {
    padding-right: var(--space-3);
  }
  ._1sbkoz61x7 {
    padding-right: var(--space-4);
  }
  ._1sbkoz61xd {
    padding-right: var(--space-5);
  }
  ._1sbkoz61xj {
    padding-right: var(--space-6);
  }
  ._1sbkoz61xp {
    padding-right: var(--space-7);
  }
  ._1sbkoz61xv {
    padding-right: var(--space-px);
  }
  ._1sbkoz61y1 {
    padding-right: var(--space-4px);
  }
  ._1sbkoz61y7 {
    padding-right: var(--space-8px);
  }
  ._1sbkoz61yd {
    padding-right: var(--space-12px);
  }
  ._1sbkoz61yj {
    padding-right: var(--space-16px);
  }
  ._1sbkoz61yp {
    padding-right: var(--space-24px);
  }
  ._1sbkoz61yv {
    padding-right: var(--space-32px);
  }
  ._1sbkoz61z1 {
    padding-right: var(--space-48px);
  }
  ._1sbkoz61z7 {
    padding-right: var(--space-56px);
  }
  ._1sbkoz61zd {
    padding-right: var(--space-64px);
  }
  ._1sbkoz61zj {
    padding-top: var(--space-0);
  }
  ._1sbkoz61zp {
    padding-top: var(--space-1);
  }
  ._1sbkoz61zv {
    padding-top: var(--space-2);
  }
  ._1sbkoz6201 {
    padding-top: var(--space-3);
  }
  ._1sbkoz6207 {
    padding-top: var(--space-4);
  }
  ._1sbkoz620d {
    padding-top: var(--space-5);
  }
  ._1sbkoz620j {
    padding-top: var(--space-6);
  }
  ._1sbkoz620p {
    padding-top: var(--space-7);
  }
  ._1sbkoz620v {
    padding-top: var(--space-px);
  }
  ._1sbkoz6211 {
    padding-top: var(--space-4px);
  }
  ._1sbkoz6217 {
    padding-top: var(--space-8px);
  }
  ._1sbkoz621d {
    padding-top: var(--space-12px);
  }
  ._1sbkoz621j {
    padding-top: var(--space-16px);
  }
  ._1sbkoz621p {
    padding-top: var(--space-24px);
  }
  ._1sbkoz621v {
    padding-top: var(--space-32px);
  }
  ._1sbkoz6221 {
    padding-top: var(--space-48px);
  }
  ._1sbkoz6227 {
    padding-top: var(--space-56px);
  }
  ._1sbkoz622d {
    padding-top: var(--space-64px);
  }
  ._1sbkoz622j {
    font-size: var(--fontSizes-10px);
  }
  ._1sbkoz622p {
    font-size: var(--fontSizes-12px);
  }
  ._1sbkoz622v {
    font-size: var(--fontSizes-16px);
  }
  ._1sbkoz6231 {
    font-size: var(--fontSizes-14px);
  }
  ._1sbkoz6237 {
    font-size: var(--fontSizes-20px);
  }
  ._1sbkoz623d {
    font-size: var(--fontSizes-40px);
  }
  ._1sbkoz623j {
    font-size: inherit;
  }
  ._1sbkoz623p {
    right: var(--space-0);
  }
  ._1sbkoz623v {
    right: var(--space-1);
  }
  ._1sbkoz6241 {
    right: var(--space-2);
  }
  ._1sbkoz6247 {
    right: var(--space-3);
  }
  ._1sbkoz624d {
    right: var(--space-4);
  }
  ._1sbkoz624j {
    right: var(--space-5);
  }
  ._1sbkoz624p {
    right: var(--space-6);
  }
  ._1sbkoz624v {
    right: var(--space-7);
  }
  ._1sbkoz6251 {
    right: var(--space-px);
  }
  ._1sbkoz6257 {
    right: var(--space-4px);
  }
  ._1sbkoz625d {
    right: var(--space-8px);
  }
  ._1sbkoz625j {
    right: var(--space-12px);
  }
  ._1sbkoz625p {
    right: var(--space-16px);
  }
  ._1sbkoz625v {
    right: var(--space-24px);
  }
  ._1sbkoz6261 {
    right: var(--space-32px);
  }
  ._1sbkoz6267 {
    right: var(--space-48px);
  }
  ._1sbkoz626d {
    right: var(--space-56px);
  }
  ._1sbkoz626j {
    right: var(--space-64px);
  }
  ._1sbkoz626p {
    right: calc(var(--space-px) * -1);
  }
  ._1sbkoz626v {
    right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6271 {
    right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6277 {
    right: calc(var(--space-3) * -1);
  }
  ._1sbkoz627d {
    right: calc(var(--space-4) * -1);
  }
  ._1sbkoz627j {
    right: calc(var(--space-5) * -1);
  }
  ._1sbkoz627p {
    right: calc(var(--space-6) * -1);
  }
  ._1sbkoz627v {
    right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6281 {
    top: var(--space-0);
  }
  ._1sbkoz6287 {
    top: var(--space-1);
  }
  ._1sbkoz628d {
    top: var(--space-2);
  }
  ._1sbkoz628j {
    top: var(--space-3);
  }
  ._1sbkoz628p {
    top: var(--space-4);
  }
  ._1sbkoz628v {
    top: var(--space-5);
  }
  ._1sbkoz6291 {
    top: var(--space-6);
  }
  ._1sbkoz6297 {
    top: var(--space-7);
  }
  ._1sbkoz629d {
    top: var(--space-px);
  }
  ._1sbkoz629j {
    top: var(--space-4px);
  }
  ._1sbkoz629p {
    top: var(--space-8px);
  }
  ._1sbkoz629v {
    top: var(--space-12px);
  }
  ._1sbkoz62a1 {
    top: var(--space-16px);
  }
  ._1sbkoz62a7 {
    top: var(--space-24px);
  }
  ._1sbkoz62ad {
    top: var(--space-32px);
  }
  ._1sbkoz62aj {
    top: var(--space-48px);
  }
  ._1sbkoz62ap {
    top: var(--space-56px);
  }
  ._1sbkoz62av {
    top: var(--space-64px);
  }
  ._1sbkoz62b1 {
    top: calc(var(--space-px) * -1);
  }
  ._1sbkoz62b7 {
    top: calc(var(--space-1) * -1);
  }
  ._1sbkoz62bd {
    top: calc(var(--space-2) * -1);
  }
  ._1sbkoz62bj {
    top: calc(var(--space-3) * -1);
  }
  ._1sbkoz62bp {
    top: calc(var(--space-4) * -1);
  }
  ._1sbkoz62bv {
    top: calc(var(--space-5) * -1);
  }
  ._1sbkoz62c1 {
    top: calc(var(--space-6) * -1);
  }
  ._1sbkoz62c7 {
    top: calc(var(--space-7) * -1);
  }
  ._1sbkoz62cd {
    flex: 1 1;
  }
  ._1sbkoz62cj {
    flex: 1 1 auto;
  }
  ._1sbkoz62cp {
    flex: 0 1 auto;
  }
  ._1sbkoz62cv {
    flex: none;
  }
  ._1sbkoz62d1 {
    width: var(--space-0);
  }
  ._1sbkoz62d7 {
    width: var(--space-1);
  }
  ._1sbkoz62dd {
    width: var(--space-2);
  }
  ._1sbkoz62dj {
    width: var(--space-3);
  }
  ._1sbkoz62dp {
    width: var(--space-4);
  }
  ._1sbkoz62dv {
    width: var(--space-5);
  }
  ._1sbkoz62e1 {
    width: var(--space-6);
  }
  ._1sbkoz62e7 {
    width: var(--space-7);
  }
  ._1sbkoz62ed {
    width: var(--space-px);
  }
  ._1sbkoz62ej {
    width: var(--space-4px);
  }
  ._1sbkoz62ep {
    width: var(--space-8px);
  }
  ._1sbkoz62ev {
    width: var(--space-12px);
  }
  ._1sbkoz62f1 {
    width: var(--space-16px);
  }
  ._1sbkoz62f7 {
    width: var(--space-24px);
  }
  ._1sbkoz62fd {
    width: var(--space-32px);
  }
  ._1sbkoz62fj {
    width: var(--space-48px);
  }
  ._1sbkoz62fp {
    width: var(--space-56px);
  }
  ._1sbkoz62fv {
    width: var(--space-64px);
  }
  ._1sbkoz62g1 {
    width: 100%;
  }
  ._1sbkoz62g7 {
    width: 100%;
  }
  ._1sbkoz62gd {
    width: auto;
  }
  ._1sbkoz62gj {
    width: 576px;
  }
  ._1sbkoz62gp {
    width: 852px;
  }
  ._1sbkoz62gv {
    width: 968px;
  }
  ._1sbkoz62h1 {
    width: 1080px;
  }
  ._1sbkoz62h7 {
    z-index: 0;
  }
  ._1sbkoz62hd {
    z-index: 10;
  }
  ._1sbkoz62hj {
    z-index: 20;
  }
  ._1sbkoz62hp {
    z-index: 30;
  }
  ._1sbkoz62hv {
    z-index: 40;
  }
  ._1sbkoz62i1 {
    z-index: 50;
  }
  ._1sbkoz62i7 {
    z-index: 75;
  }
  ._1sbkoz62id {
    z-index: 99;
  }
  ._1sbkoz62ij {
    z-index: 100;
  }
  ._1sbkoz62ip {
    z-index: 9;
  }
  ._1sbkoz62iv {
    z-index: 10;
  }
  ._1sbkoz62j1 {
    z-index: 100;
  }
  ._1sbkoz62j7 {
    z-index: auto;
  }
  ._1sbkoz62jd {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1sbkoz62jj {
    border-radius: var(--radii-0);
  }
  ._1sbkoz62jp {
    border-radius: var(--radii-small);
  }
  ._1sbkoz62jv {
    border-radius: var(--radii-default);
  }
  ._1sbkoz62k1 {
    border-radius: var(--radii-card);
  }
  ._1sbkoz62k7 {
    border-radius: var(--radii-circle);
  }
  ._1sbkoz62kd {
    border-top-left-radius: var(--radii-0);
  }
  ._1sbkoz62kj {
    border-top-left-radius: var(--radii-small);
  }
  ._1sbkoz62kp {
    border-top-left-radius: var(--radii-default);
  }
  ._1sbkoz62kv {
    border-top-left-radius: var(--radii-card);
  }
  ._1sbkoz62l1 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1sbkoz62l7 {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1sbkoz62ld {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1sbkoz62lj {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1sbkoz62lp {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1sbkoz62lv {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1sbkoz62m1 {
    border-top-right-radius: var(--radii-0);
  }
  ._1sbkoz62m7 {
    border-top-right-radius: var(--radii-small);
  }
  ._1sbkoz62md {
    border-top-right-radius: var(--radii-default);
  }
  ._1sbkoz62mj {
    border-top-right-radius: var(--radii-card);
  }
  ._1sbkoz62mp {
    border-top-right-radius: var(--radii-circle);
  }
  ._1sbkoz62mv {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1sbkoz62n1 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1sbkoz62n7 {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1sbkoz62nd {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1sbkoz62nj {
    border-bottom-left-radius: var(--radii-circle);
  }
}
@media (min-width: 852px) {
  ._1sbkoz62 {
    display: block;
  }
  ._1sbkoz68 {
    display: flex;
  }
  ._1sbkoz6e {
    display: grid;
  }
  ._1sbkoz6k {
    display: inline;
  }
  ._1sbkoz6q {
    display: inline-flex;
  }
  ._1sbkoz6w {
    display: inline-block;
  }
  ._1sbkoz612 {
    display: none;
  }
  ._1sbkoz618 {
    display: contents;
  }
  ._1sbkoz61e {
    flex-direction: column;
  }
  ._1sbkoz61k {
    flex-direction: row;
  }
  ._1sbkoz61q {
    flex-direction: column-reverse;
  }
  ._1sbkoz61w {
    align-items: center;
  }
  ._1sbkoz622 {
    align-items: end;
  }
  ._1sbkoz628 {
    align-items: baseLine;
  }
  ._1sbkoz62e {
    align-items: inherit;
  }
  ._1sbkoz62k {
    align-items: flex-start;
  }
  ._1sbkoz62q {
    align-items: center;
  }
  ._1sbkoz62w {
    align-items: start;
  }
  ._1sbkoz632 {
    align-items: flex-end;
  }
  ._1sbkoz638 {
    align-items: stretch;
  }
  ._1sbkoz63e {
    flex-wrap: wrap;
  }
  ._1sbkoz63k {
    flex-wrap: nowrap;
  }
  ._1sbkoz63q {
    overflow: auto;
  }
  ._1sbkoz63w {
    overflow: hidden;
  }
  ._1sbkoz642 {
    overflow: scroll;
  }
  ._1sbkoz648 {
    overflow: unset;
  }
  ._1sbkoz64e {
    overflow-y: auto;
  }
  ._1sbkoz64k {
    overflow-y: hidden;
  }
  ._1sbkoz64q {
    overflow-y: scroll;
  }
  ._1sbkoz64w {
    overflow-x: auto;
  }
  ._1sbkoz652 {
    overflow-x: hidden;
  }
  ._1sbkoz658 {
    overflow-x: scroll;
  }
  ._1sbkoz65e {
    position: absolute;
  }
  ._1sbkoz65k {
    position: fixed;
  }
  ._1sbkoz65q {
    position: relative;
  }
  ._1sbkoz65w {
    position: -webkit-sticky;
    position: sticky;
  }
  ._1sbkoz662 {
    text-align: center;
  }
  ._1sbkoz668 {
    text-align: left;
  }
  ._1sbkoz66e {
    text-align: right;
  }
  ._1sbkoz66k {
    justify-content: flex-start;
  }
  ._1sbkoz66q {
    justify-content: center;
  }
  ._1sbkoz66w {
    justify-content: start;
  }
  ._1sbkoz672 {
    justify-content: flex-end;
  }
  ._1sbkoz678 {
    justify-content: stretch;
  }
  ._1sbkoz67e {
    justify-content: space-around;
  }
  ._1sbkoz67k {
    justify-content: space-between;
  }
  ._1sbkoz67q {
    justify-items: flex-start;
  }
  ._1sbkoz67w {
    justify-items: center;
  }
  ._1sbkoz682 {
    justify-items: start;
  }
  ._1sbkoz688 {
    justify-items: flex-end;
  }
  ._1sbkoz68e {
    justify-items: stretch;
  }
  ._1sbkoz68k {
    justify-self: flex-start;
  }
  ._1sbkoz68q {
    justify-self: center;
  }
  ._1sbkoz68w {
    justify-self: start;
  }
  ._1sbkoz692 {
    justify-self: flex-end;
  }
  ._1sbkoz698 {
    justify-self: stretch;
  }
  ._1sbkoz69e {
    inset: var(--space-0);
  }
  ._1sbkoz69k {
    inset: var(--space-1);
  }
  ._1sbkoz69q {
    inset: var(--space-2);
  }
  ._1sbkoz69w {
    inset: var(--space-3);
  }
  ._1sbkoz6a2 {
    inset: var(--space-4);
  }
  ._1sbkoz6a8 {
    inset: var(--space-5);
  }
  ._1sbkoz6ae {
    inset: var(--space-6);
  }
  ._1sbkoz6ak {
    inset: var(--space-7);
  }
  ._1sbkoz6aq {
    inset: var(--space-px);
  }
  ._1sbkoz6aw {
    inset: var(--space-4px);
  }
  ._1sbkoz6b2 {
    inset: var(--space-8px);
  }
  ._1sbkoz6b8 {
    inset: var(--space-12px);
  }
  ._1sbkoz6be {
    inset: var(--space-16px);
  }
  ._1sbkoz6bk {
    inset: var(--space-24px);
  }
  ._1sbkoz6bq {
    inset: var(--space-32px);
  }
  ._1sbkoz6bw {
    inset: var(--space-48px);
  }
  ._1sbkoz6c2 {
    inset: var(--space-56px);
  }
  ._1sbkoz6c8 {
    inset: var(--space-64px);
  }
  ._1sbkoz6ce {
    inset: calc(var(--space-px) * -1);
  }
  ._1sbkoz6ck {
    inset: calc(var(--space-1) * -1);
  }
  ._1sbkoz6cq {
    inset: calc(var(--space-2) * -1);
  }
  ._1sbkoz6cw {
    inset: calc(var(--space-3) * -1);
  }
  ._1sbkoz6d2 {
    inset: calc(var(--space-4) * -1);
  }
  ._1sbkoz6d8 {
    inset: calc(var(--space-5) * -1);
  }
  ._1sbkoz6de {
    inset: calc(var(--space-6) * -1);
  }
  ._1sbkoz6dk {
    inset: calc(var(--space-7) * -1);
  }
  ._1sbkoz6dq {
    height: var(--space-0);
  }
  ._1sbkoz6dw {
    height: var(--space-1);
  }
  ._1sbkoz6e2 {
    height: var(--space-2);
  }
  ._1sbkoz6e8 {
    height: var(--space-3);
  }
  ._1sbkoz6ee {
    height: var(--space-4);
  }
  ._1sbkoz6ek {
    height: var(--space-5);
  }
  ._1sbkoz6eq {
    height: var(--space-6);
  }
  ._1sbkoz6ew {
    height: var(--space-7);
  }
  ._1sbkoz6f2 {
    height: var(--space-px);
  }
  ._1sbkoz6f8 {
    height: var(--space-4px);
  }
  ._1sbkoz6fe {
    height: var(--space-8px);
  }
  ._1sbkoz6fk {
    height: var(--space-12px);
  }
  ._1sbkoz6fq {
    height: var(--space-16px);
  }
  ._1sbkoz6fw {
    height: var(--space-24px);
  }
  ._1sbkoz6g2 {
    height: var(--space-32px);
  }
  ._1sbkoz6g8 {
    height: var(--space-48px);
  }
  ._1sbkoz6ge {
    height: var(--space-56px);
  }
  ._1sbkoz6gk {
    height: var(--space-64px);
  }
  ._1sbkoz6gq {
    height: 100%;
  }
  ._1sbkoz6gw {
    height: 100%;
  }
  ._1sbkoz6h2 {
    height: auto;
  }
  ._1sbkoz6h8 {
    height: 576px;
  }
  ._1sbkoz6he {
    height: 852px;
  }
  ._1sbkoz6hk {
    height: 968px;
  }
  ._1sbkoz6hq {
    height: 1080px;
  }
  ._1sbkoz6hw {
    left: var(--space-0);
  }
  ._1sbkoz6i2 {
    left: var(--space-1);
  }
  ._1sbkoz6i8 {
    left: var(--space-2);
  }
  ._1sbkoz6ie {
    left: var(--space-3);
  }
  ._1sbkoz6ik {
    left: var(--space-4);
  }
  ._1sbkoz6iq {
    left: var(--space-5);
  }
  ._1sbkoz6iw {
    left: var(--space-6);
  }
  ._1sbkoz6j2 {
    left: var(--space-7);
  }
  ._1sbkoz6j8 {
    left: var(--space-px);
  }
  ._1sbkoz6je {
    left: var(--space-4px);
  }
  ._1sbkoz6jk {
    left: var(--space-8px);
  }
  ._1sbkoz6jq {
    left: var(--space-12px);
  }
  ._1sbkoz6jw {
    left: var(--space-16px);
  }
  ._1sbkoz6k2 {
    left: var(--space-24px);
  }
  ._1sbkoz6k8 {
    left: var(--space-32px);
  }
  ._1sbkoz6ke {
    left: var(--space-48px);
  }
  ._1sbkoz6kk {
    left: var(--space-56px);
  }
  ._1sbkoz6kq {
    left: var(--space-64px);
  }
  ._1sbkoz6kw {
    left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6l2 {
    left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6l8 {
    left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6le {
    left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6lk {
    left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6lq {
    left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6lw {
    left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6m2 {
    left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6m8 {
    margin-bottom: var(--space-0);
  }
  ._1sbkoz6me {
    margin-bottom: var(--space-1);
  }
  ._1sbkoz6mk {
    margin-bottom: var(--space-2);
  }
  ._1sbkoz6mq {
    margin-bottom: var(--space-3);
  }
  ._1sbkoz6mw {
    margin-bottom: var(--space-4);
  }
  ._1sbkoz6n2 {
    margin-bottom: var(--space-5);
  }
  ._1sbkoz6n8 {
    margin-bottom: var(--space-6);
  }
  ._1sbkoz6ne {
    margin-bottom: var(--space-7);
  }
  ._1sbkoz6nk {
    margin-bottom: var(--space-px);
  }
  ._1sbkoz6nq {
    margin-bottom: var(--space-4px);
  }
  ._1sbkoz6nw {
    margin-bottom: var(--space-8px);
  }
  ._1sbkoz6o2 {
    margin-bottom: var(--space-12px);
  }
  ._1sbkoz6o8 {
    margin-bottom: var(--space-16px);
  }
  ._1sbkoz6oe {
    margin-bottom: var(--space-24px);
  }
  ._1sbkoz6ok {
    margin-bottom: var(--space-32px);
  }
  ._1sbkoz6oq {
    margin-bottom: var(--space-48px);
  }
  ._1sbkoz6ow {
    margin-bottom: var(--space-56px);
  }
  ._1sbkoz6p2 {
    margin-bottom: var(--space-64px);
  }
  ._1sbkoz6p8 {
    margin-bottom: auto;
  }
  ._1sbkoz6pe {
    margin-bottom: calc(var(--space-px) * -1);
  }
  ._1sbkoz6pk {
    margin-bottom: calc(var(--space-1) * -1);
  }
  ._1sbkoz6pq {
    margin-bottom: calc(var(--space-2) * -1);
  }
  ._1sbkoz6pw {
    margin-bottom: calc(var(--space-3) * -1);
  }
  ._1sbkoz6q2 {
    margin-bottom: calc(var(--space-4) * -1);
  }
  ._1sbkoz6q8 {
    margin-bottom: calc(var(--space-5) * -1);
  }
  ._1sbkoz6qe {
    margin-bottom: calc(var(--space-6) * -1);
  }
  ._1sbkoz6qk {
    margin-bottom: calc(var(--space-7) * -1);
  }
  ._1sbkoz6qq {
    margin-left: var(--space-0);
  }
  ._1sbkoz6qw {
    margin-left: var(--space-1);
  }
  ._1sbkoz6r2 {
    margin-left: var(--space-2);
  }
  ._1sbkoz6r8 {
    margin-left: var(--space-3);
  }
  ._1sbkoz6re {
    margin-left: var(--space-4);
  }
  ._1sbkoz6rk {
    margin-left: var(--space-5);
  }
  ._1sbkoz6rq {
    margin-left: var(--space-6);
  }
  ._1sbkoz6rw {
    margin-left: var(--space-7);
  }
  ._1sbkoz6s2 {
    margin-left: var(--space-px);
  }
  ._1sbkoz6s8 {
    margin-left: var(--space-4px);
  }
  ._1sbkoz6se {
    margin-left: var(--space-8px);
  }
  ._1sbkoz6sk {
    margin-left: var(--space-12px);
  }
  ._1sbkoz6sq {
    margin-left: var(--space-16px);
  }
  ._1sbkoz6sw {
    margin-left: var(--space-24px);
  }
  ._1sbkoz6t2 {
    margin-left: var(--space-32px);
  }
  ._1sbkoz6t8 {
    margin-left: var(--space-48px);
  }
  ._1sbkoz6te {
    margin-left: var(--space-56px);
  }
  ._1sbkoz6tk {
    margin-left: var(--space-64px);
  }
  ._1sbkoz6tq {
    margin-left: auto;
  }
  ._1sbkoz6tw {
    margin-left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6u2 {
    margin-left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6u8 {
    margin-left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6ue {
    margin-left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6uk {
    margin-left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6uq {
    margin-left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6uw {
    margin-left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6v2 {
    margin-left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6v8 {
    margin-right: var(--space-0);
  }
  ._1sbkoz6ve {
    margin-right: var(--space-1);
  }
  ._1sbkoz6vk {
    margin-right: var(--space-2);
  }
  ._1sbkoz6vq {
    margin-right: var(--space-3);
  }
  ._1sbkoz6vw {
    margin-right: var(--space-4);
  }
  ._1sbkoz6w2 {
    margin-right: var(--space-5);
  }
  ._1sbkoz6w8 {
    margin-right: var(--space-6);
  }
  ._1sbkoz6we {
    margin-right: var(--space-7);
  }
  ._1sbkoz6wk {
    margin-right: var(--space-px);
  }
  ._1sbkoz6wq {
    margin-right: var(--space-4px);
  }
  ._1sbkoz6ww {
    margin-right: var(--space-8px);
  }
  ._1sbkoz6x2 {
    margin-right: var(--space-12px);
  }
  ._1sbkoz6x8 {
    margin-right: var(--space-16px);
  }
  ._1sbkoz6xe {
    margin-right: var(--space-24px);
  }
  ._1sbkoz6xk {
    margin-right: var(--space-32px);
  }
  ._1sbkoz6xq {
    margin-right: var(--space-48px);
  }
  ._1sbkoz6xw {
    margin-right: var(--space-56px);
  }
  ._1sbkoz6y2 {
    margin-right: var(--space-64px);
  }
  ._1sbkoz6y8 {
    margin-right: auto;
  }
  ._1sbkoz6ye {
    margin-right: calc(var(--space-px) * -1);
  }
  ._1sbkoz6yk {
    margin-right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6yq {
    margin-right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6yw {
    margin-right: calc(var(--space-3) * -1);
  }
  ._1sbkoz6z2 {
    margin-right: calc(var(--space-4) * -1);
  }
  ._1sbkoz6z8 {
    margin-right: calc(var(--space-5) * -1);
  }
  ._1sbkoz6ze {
    margin-right: calc(var(--space-6) * -1);
  }
  ._1sbkoz6zk {
    margin-right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6zq {
    margin-top: var(--space-0);
  }
  ._1sbkoz6zw {
    margin-top: var(--space-1);
  }
  ._1sbkoz6102 {
    margin-top: var(--space-2);
  }
  ._1sbkoz6108 {
    margin-top: var(--space-3);
  }
  ._1sbkoz610e {
    margin-top: var(--space-4);
  }
  ._1sbkoz610k {
    margin-top: var(--space-5);
  }
  ._1sbkoz610q {
    margin-top: var(--space-6);
  }
  ._1sbkoz610w {
    margin-top: var(--space-7);
  }
  ._1sbkoz6112 {
    margin-top: var(--space-px);
  }
  ._1sbkoz6118 {
    margin-top: var(--space-4px);
  }
  ._1sbkoz611e {
    margin-top: var(--space-8px);
  }
  ._1sbkoz611k {
    margin-top: var(--space-12px);
  }
  ._1sbkoz611q {
    margin-top: var(--space-16px);
  }
  ._1sbkoz611w {
    margin-top: var(--space-24px);
  }
  ._1sbkoz6122 {
    margin-top: var(--space-32px);
  }
  ._1sbkoz6128 {
    margin-top: var(--space-48px);
  }
  ._1sbkoz612e {
    margin-top: var(--space-56px);
  }
  ._1sbkoz612k {
    margin-top: var(--space-64px);
  }
  ._1sbkoz612q {
    margin-top: auto;
  }
  ._1sbkoz612w {
    margin-top: calc(var(--space-px) * -1);
  }
  ._1sbkoz6132 {
    margin-top: calc(var(--space-1) * -1);
  }
  ._1sbkoz6138 {
    margin-top: calc(var(--space-2) * -1);
  }
  ._1sbkoz613e {
    margin-top: calc(var(--space-3) * -1);
  }
  ._1sbkoz613k {
    margin-top: calc(var(--space-4) * -1);
  }
  ._1sbkoz613q {
    margin-top: calc(var(--space-5) * -1);
  }
  ._1sbkoz613w {
    margin-top: calc(var(--space-6) * -1);
  }
  ._1sbkoz6142 {
    margin-top: calc(var(--space-7) * -1);
  }
  ._1sbkoz6148 {
    margin: var(--space-0);
  }
  ._1sbkoz614e {
    margin: var(--space-1);
  }
  ._1sbkoz614k {
    margin: var(--space-2);
  }
  ._1sbkoz614q {
    margin: var(--space-3);
  }
  ._1sbkoz614w {
    margin: var(--space-4);
  }
  ._1sbkoz6152 {
    margin: var(--space-5);
  }
  ._1sbkoz6158 {
    margin: var(--space-6);
  }
  ._1sbkoz615e {
    margin: var(--space-7);
  }
  ._1sbkoz615k {
    margin: var(--space-px);
  }
  ._1sbkoz615q {
    margin: var(--space-4px);
  }
  ._1sbkoz615w {
    margin: var(--space-8px);
  }
  ._1sbkoz6162 {
    margin: var(--space-12px);
  }
  ._1sbkoz6168 {
    margin: var(--space-16px);
  }
  ._1sbkoz616e {
    margin: var(--space-24px);
  }
  ._1sbkoz616k {
    margin: var(--space-32px);
  }
  ._1sbkoz616q {
    margin: var(--space-48px);
  }
  ._1sbkoz616w {
    margin: var(--space-56px);
  }
  ._1sbkoz6172 {
    margin: var(--space-64px);
  }
  ._1sbkoz6178 {
    margin: auto;
  }
  ._1sbkoz617e {
    margin: calc(var(--space-px) * -1);
  }
  ._1sbkoz617k {
    margin: calc(var(--space-1) * -1);
  }
  ._1sbkoz617q {
    margin: calc(var(--space-2) * -1);
  }
  ._1sbkoz617w {
    margin: calc(var(--space-3) * -1);
  }
  ._1sbkoz6182 {
    margin: calc(var(--space-4) * -1);
  }
  ._1sbkoz6188 {
    margin: calc(var(--space-5) * -1);
  }
  ._1sbkoz618e {
    margin: calc(var(--space-6) * -1);
  }
  ._1sbkoz618k {
    margin: calc(var(--space-7) * -1);
  }
  ._1sbkoz618q {
    padding: var(--space-0);
  }
  ._1sbkoz618w {
    padding: var(--space-1);
  }
  ._1sbkoz6192 {
    padding: var(--space-2);
  }
  ._1sbkoz6198 {
    padding: var(--space-3);
  }
  ._1sbkoz619e {
    padding: var(--space-4);
  }
  ._1sbkoz619k {
    padding: var(--space-5);
  }
  ._1sbkoz619q {
    padding: var(--space-6);
  }
  ._1sbkoz619w {
    padding: var(--space-7);
  }
  ._1sbkoz61a2 {
    padding: var(--space-px);
  }
  ._1sbkoz61a8 {
    padding: var(--space-4px);
  }
  ._1sbkoz61ae {
    padding: var(--space-8px);
  }
  ._1sbkoz61ak {
    padding: var(--space-12px);
  }
  ._1sbkoz61aq {
    padding: var(--space-16px);
  }
  ._1sbkoz61aw {
    padding: var(--space-24px);
  }
  ._1sbkoz61b2 {
    padding: var(--space-32px);
  }
  ._1sbkoz61b8 {
    padding: var(--space-48px);
  }
  ._1sbkoz61be {
    padding: var(--space-56px);
  }
  ._1sbkoz61bk {
    padding: var(--space-64px);
  }
  ._1sbkoz61bq {
    padding: auto;
  }
  ._1sbkoz61bw {
    padding: calc(var(--space-px) * -1);
  }
  ._1sbkoz61c2 {
    padding: calc(var(--space-1) * -1);
  }
  ._1sbkoz61c8 {
    padding: calc(var(--space-2) * -1);
  }
  ._1sbkoz61ce {
    padding: calc(var(--space-3) * -1);
  }
  ._1sbkoz61ck {
    padding: calc(var(--space-4) * -1);
  }
  ._1sbkoz61cq {
    padding: calc(var(--space-5) * -1);
  }
  ._1sbkoz61cw {
    padding: calc(var(--space-6) * -1);
  }
  ._1sbkoz61d2 {
    padding: calc(var(--space-7) * -1);
  }
  ._1sbkoz61d8 {
    max-height: var(--space-0);
  }
  ._1sbkoz61de {
    max-height: var(--space-1);
  }
  ._1sbkoz61dk {
    max-height: var(--space-2);
  }
  ._1sbkoz61dq {
    max-height: var(--space-3);
  }
  ._1sbkoz61dw {
    max-height: var(--space-4);
  }
  ._1sbkoz61e2 {
    max-height: var(--space-5);
  }
  ._1sbkoz61e8 {
    max-height: var(--space-6);
  }
  ._1sbkoz61ee {
    max-height: var(--space-7);
  }
  ._1sbkoz61ek {
    max-height: var(--space-px);
  }
  ._1sbkoz61eq {
    max-height: var(--space-4px);
  }
  ._1sbkoz61ew {
    max-height: var(--space-8px);
  }
  ._1sbkoz61f2 {
    max-height: var(--space-12px);
  }
  ._1sbkoz61f8 {
    max-height: var(--space-16px);
  }
  ._1sbkoz61fe {
    max-height: var(--space-24px);
  }
  ._1sbkoz61fk {
    max-height: var(--space-32px);
  }
  ._1sbkoz61fq {
    max-height: var(--space-48px);
  }
  ._1sbkoz61fw {
    max-height: var(--space-56px);
  }
  ._1sbkoz61g2 {
    max-height: var(--space-64px);
  }
  ._1sbkoz61g8 {
    max-width: var(--space-0);
  }
  ._1sbkoz61ge {
    max-width: var(--space-1);
  }
  ._1sbkoz61gk {
    max-width: var(--space-2);
  }
  ._1sbkoz61gq {
    max-width: var(--space-3);
  }
  ._1sbkoz61gw {
    max-width: var(--space-4);
  }
  ._1sbkoz61h2 {
    max-width: var(--space-5);
  }
  ._1sbkoz61h8 {
    max-width: var(--space-6);
  }
  ._1sbkoz61he {
    max-width: var(--space-7);
  }
  ._1sbkoz61hk {
    max-width: var(--space-px);
  }
  ._1sbkoz61hq {
    max-width: var(--space-4px);
  }
  ._1sbkoz61hw {
    max-width: var(--space-8px);
  }
  ._1sbkoz61i2 {
    max-width: var(--space-12px);
  }
  ._1sbkoz61i8 {
    max-width: var(--space-16px);
  }
  ._1sbkoz61ie {
    max-width: var(--space-24px);
  }
  ._1sbkoz61ik {
    max-width: var(--space-32px);
  }
  ._1sbkoz61iq {
    max-width: var(--space-48px);
  }
  ._1sbkoz61iw {
    max-width: var(--space-56px);
  }
  ._1sbkoz61j2 {
    max-width: var(--space-64px);
  }
  ._1sbkoz61j8 {
    max-width: 100%;
  }
  ._1sbkoz61je {
    max-width: 100%;
  }
  ._1sbkoz61jk {
    max-width: auto;
  }
  ._1sbkoz61jq {
    max-width: 576px;
  }
  ._1sbkoz61jw {
    max-width: 852px;
  }
  ._1sbkoz61k2 {
    max-width: 968px;
  }
  ._1sbkoz61k8 {
    max-width: 1080px;
  }
  ._1sbkoz61ke {
    max-width: none;
  }
  ._1sbkoz61kk {
    min-height: var(--space-0);
  }
  ._1sbkoz61kq {
    min-height: var(--space-1);
  }
  ._1sbkoz61kw {
    min-height: var(--space-2);
  }
  ._1sbkoz61l2 {
    min-height: var(--space-3);
  }
  ._1sbkoz61l8 {
    min-height: var(--space-4);
  }
  ._1sbkoz61le {
    min-height: var(--space-5);
  }
  ._1sbkoz61lk {
    min-height: var(--space-6);
  }
  ._1sbkoz61lq {
    min-height: var(--space-7);
  }
  ._1sbkoz61lw {
    min-height: var(--space-px);
  }
  ._1sbkoz61m2 {
    min-height: var(--space-4px);
  }
  ._1sbkoz61m8 {
    min-height: var(--space-8px);
  }
  ._1sbkoz61me {
    min-height: var(--space-12px);
  }
  ._1sbkoz61mk {
    min-height: var(--space-16px);
  }
  ._1sbkoz61mq {
    min-height: var(--space-24px);
  }
  ._1sbkoz61mw {
    min-height: var(--space-32px);
  }
  ._1sbkoz61n2 {
    min-height: var(--space-48px);
  }
  ._1sbkoz61n8 {
    min-height: var(--space-56px);
  }
  ._1sbkoz61ne {
    min-height: var(--space-64px);
  }
  ._1sbkoz61nk {
    min-width: var(--space-0);
  }
  ._1sbkoz61nq {
    min-width: var(--space-1);
  }
  ._1sbkoz61nw {
    min-width: var(--space-2);
  }
  ._1sbkoz61o2 {
    min-width: var(--space-3);
  }
  ._1sbkoz61o8 {
    min-width: var(--space-4);
  }
  ._1sbkoz61oe {
    min-width: var(--space-5);
  }
  ._1sbkoz61ok {
    min-width: var(--space-6);
  }
  ._1sbkoz61oq {
    min-width: var(--space-7);
  }
  ._1sbkoz61ow {
    min-width: var(--space-px);
  }
  ._1sbkoz61p2 {
    min-width: var(--space-4px);
  }
  ._1sbkoz61p8 {
    min-width: var(--space-8px);
  }
  ._1sbkoz61pe {
    min-width: var(--space-12px);
  }
  ._1sbkoz61pk {
    min-width: var(--space-16px);
  }
  ._1sbkoz61pq {
    min-width: var(--space-24px);
  }
  ._1sbkoz61pw {
    min-width: var(--space-32px);
  }
  ._1sbkoz61q2 {
    min-width: var(--space-48px);
  }
  ._1sbkoz61q8 {
    min-width: var(--space-56px);
  }
  ._1sbkoz61qe {
    min-width: var(--space-64px);
  }
  ._1sbkoz61qk {
    padding-bottom: var(--space-0);
  }
  ._1sbkoz61qq {
    padding-bottom: var(--space-1);
  }
  ._1sbkoz61qw {
    padding-bottom: var(--space-2);
  }
  ._1sbkoz61r2 {
    padding-bottom: var(--space-3);
  }
  ._1sbkoz61r8 {
    padding-bottom: var(--space-4);
  }
  ._1sbkoz61re {
    padding-bottom: var(--space-5);
  }
  ._1sbkoz61rk {
    padding-bottom: var(--space-6);
  }
  ._1sbkoz61rq {
    padding-bottom: var(--space-7);
  }
  ._1sbkoz61rw {
    padding-bottom: var(--space-px);
  }
  ._1sbkoz61s2 {
    padding-bottom: var(--space-4px);
  }
  ._1sbkoz61s8 {
    padding-bottom: var(--space-8px);
  }
  ._1sbkoz61se {
    padding-bottom: var(--space-12px);
  }
  ._1sbkoz61sk {
    padding-bottom: var(--space-16px);
  }
  ._1sbkoz61sq {
    padding-bottom: var(--space-24px);
  }
  ._1sbkoz61sw {
    padding-bottom: var(--space-32px);
  }
  ._1sbkoz61t2 {
    padding-bottom: var(--space-48px);
  }
  ._1sbkoz61t8 {
    padding-bottom: var(--space-56px);
  }
  ._1sbkoz61te {
    padding-bottom: var(--space-64px);
  }
  ._1sbkoz61tk {
    padding-left: var(--space-0);
  }
  ._1sbkoz61tq {
    padding-left: var(--space-1);
  }
  ._1sbkoz61tw {
    padding-left: var(--space-2);
  }
  ._1sbkoz61u2 {
    padding-left: var(--space-3);
  }
  ._1sbkoz61u8 {
    padding-left: var(--space-4);
  }
  ._1sbkoz61ue {
    padding-left: var(--space-5);
  }
  ._1sbkoz61uk {
    padding-left: var(--space-6);
  }
  ._1sbkoz61uq {
    padding-left: var(--space-7);
  }
  ._1sbkoz61uw {
    padding-left: var(--space-px);
  }
  ._1sbkoz61v2 {
    padding-left: var(--space-4px);
  }
  ._1sbkoz61v8 {
    padding-left: var(--space-8px);
  }
  ._1sbkoz61ve {
    padding-left: var(--space-12px);
  }
  ._1sbkoz61vk {
    padding-left: var(--space-16px);
  }
  ._1sbkoz61vq {
    padding-left: var(--space-24px);
  }
  ._1sbkoz61vw {
    padding-left: var(--space-32px);
  }
  ._1sbkoz61w2 {
    padding-left: var(--space-48px);
  }
  ._1sbkoz61w8 {
    padding-left: var(--space-56px);
  }
  ._1sbkoz61we {
    padding-left: var(--space-64px);
  }
  ._1sbkoz61wk {
    padding-right: var(--space-0);
  }
  ._1sbkoz61wq {
    padding-right: var(--space-1);
  }
  ._1sbkoz61ww {
    padding-right: var(--space-2);
  }
  ._1sbkoz61x2 {
    padding-right: var(--space-3);
  }
  ._1sbkoz61x8 {
    padding-right: var(--space-4);
  }
  ._1sbkoz61xe {
    padding-right: var(--space-5);
  }
  ._1sbkoz61xk {
    padding-right: var(--space-6);
  }
  ._1sbkoz61xq {
    padding-right: var(--space-7);
  }
  ._1sbkoz61xw {
    padding-right: var(--space-px);
  }
  ._1sbkoz61y2 {
    padding-right: var(--space-4px);
  }
  ._1sbkoz61y8 {
    padding-right: var(--space-8px);
  }
  ._1sbkoz61ye {
    padding-right: var(--space-12px);
  }
  ._1sbkoz61yk {
    padding-right: var(--space-16px);
  }
  ._1sbkoz61yq {
    padding-right: var(--space-24px);
  }
  ._1sbkoz61yw {
    padding-right: var(--space-32px);
  }
  ._1sbkoz61z2 {
    padding-right: var(--space-48px);
  }
  ._1sbkoz61z8 {
    padding-right: var(--space-56px);
  }
  ._1sbkoz61ze {
    padding-right: var(--space-64px);
  }
  ._1sbkoz61zk {
    padding-top: var(--space-0);
  }
  ._1sbkoz61zq {
    padding-top: var(--space-1);
  }
  ._1sbkoz61zw {
    padding-top: var(--space-2);
  }
  ._1sbkoz6202 {
    padding-top: var(--space-3);
  }
  ._1sbkoz6208 {
    padding-top: var(--space-4);
  }
  ._1sbkoz620e {
    padding-top: var(--space-5);
  }
  ._1sbkoz620k {
    padding-top: var(--space-6);
  }
  ._1sbkoz620q {
    padding-top: var(--space-7);
  }
  ._1sbkoz620w {
    padding-top: var(--space-px);
  }
  ._1sbkoz6212 {
    padding-top: var(--space-4px);
  }
  ._1sbkoz6218 {
    padding-top: var(--space-8px);
  }
  ._1sbkoz621e {
    padding-top: var(--space-12px);
  }
  ._1sbkoz621k {
    padding-top: var(--space-16px);
  }
  ._1sbkoz621q {
    padding-top: var(--space-24px);
  }
  ._1sbkoz621w {
    padding-top: var(--space-32px);
  }
  ._1sbkoz6222 {
    padding-top: var(--space-48px);
  }
  ._1sbkoz6228 {
    padding-top: var(--space-56px);
  }
  ._1sbkoz622e {
    padding-top: var(--space-64px);
  }
  ._1sbkoz622k {
    font-size: var(--fontSizes-10px);
  }
  ._1sbkoz622q {
    font-size: var(--fontSizes-12px);
  }
  ._1sbkoz622w {
    font-size: var(--fontSizes-16px);
  }
  ._1sbkoz6232 {
    font-size: var(--fontSizes-14px);
  }
  ._1sbkoz6238 {
    font-size: var(--fontSizes-20px);
  }
  ._1sbkoz623e {
    font-size: var(--fontSizes-40px);
  }
  ._1sbkoz623k {
    font-size: inherit;
  }
  ._1sbkoz623q {
    right: var(--space-0);
  }
  ._1sbkoz623w {
    right: var(--space-1);
  }
  ._1sbkoz6242 {
    right: var(--space-2);
  }
  ._1sbkoz6248 {
    right: var(--space-3);
  }
  ._1sbkoz624e {
    right: var(--space-4);
  }
  ._1sbkoz624k {
    right: var(--space-5);
  }
  ._1sbkoz624q {
    right: var(--space-6);
  }
  ._1sbkoz624w {
    right: var(--space-7);
  }
  ._1sbkoz6252 {
    right: var(--space-px);
  }
  ._1sbkoz6258 {
    right: var(--space-4px);
  }
  ._1sbkoz625e {
    right: var(--space-8px);
  }
  ._1sbkoz625k {
    right: var(--space-12px);
  }
  ._1sbkoz625q {
    right: var(--space-16px);
  }
  ._1sbkoz625w {
    right: var(--space-24px);
  }
  ._1sbkoz6262 {
    right: var(--space-32px);
  }
  ._1sbkoz6268 {
    right: var(--space-48px);
  }
  ._1sbkoz626e {
    right: var(--space-56px);
  }
  ._1sbkoz626k {
    right: var(--space-64px);
  }
  ._1sbkoz626q {
    right: calc(var(--space-px) * -1);
  }
  ._1sbkoz626w {
    right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6272 {
    right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6278 {
    right: calc(var(--space-3) * -1);
  }
  ._1sbkoz627e {
    right: calc(var(--space-4) * -1);
  }
  ._1sbkoz627k {
    right: calc(var(--space-5) * -1);
  }
  ._1sbkoz627q {
    right: calc(var(--space-6) * -1);
  }
  ._1sbkoz627w {
    right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6282 {
    top: var(--space-0);
  }
  ._1sbkoz6288 {
    top: var(--space-1);
  }
  ._1sbkoz628e {
    top: var(--space-2);
  }
  ._1sbkoz628k {
    top: var(--space-3);
  }
  ._1sbkoz628q {
    top: var(--space-4);
  }
  ._1sbkoz628w {
    top: var(--space-5);
  }
  ._1sbkoz6292 {
    top: var(--space-6);
  }
  ._1sbkoz6298 {
    top: var(--space-7);
  }
  ._1sbkoz629e {
    top: var(--space-px);
  }
  ._1sbkoz629k {
    top: var(--space-4px);
  }
  ._1sbkoz629q {
    top: var(--space-8px);
  }
  ._1sbkoz629w {
    top: var(--space-12px);
  }
  ._1sbkoz62a2 {
    top: var(--space-16px);
  }
  ._1sbkoz62a8 {
    top: var(--space-24px);
  }
  ._1sbkoz62ae {
    top: var(--space-32px);
  }
  ._1sbkoz62ak {
    top: var(--space-48px);
  }
  ._1sbkoz62aq {
    top: var(--space-56px);
  }
  ._1sbkoz62aw {
    top: var(--space-64px);
  }
  ._1sbkoz62b2 {
    top: calc(var(--space-px) * -1);
  }
  ._1sbkoz62b8 {
    top: calc(var(--space-1) * -1);
  }
  ._1sbkoz62be {
    top: calc(var(--space-2) * -1);
  }
  ._1sbkoz62bk {
    top: calc(var(--space-3) * -1);
  }
  ._1sbkoz62bq {
    top: calc(var(--space-4) * -1);
  }
  ._1sbkoz62bw {
    top: calc(var(--space-5) * -1);
  }
  ._1sbkoz62c2 {
    top: calc(var(--space-6) * -1);
  }
  ._1sbkoz62c8 {
    top: calc(var(--space-7) * -1);
  }
  ._1sbkoz62ce {
    flex: 1 1;
  }
  ._1sbkoz62ck {
    flex: 1 1 auto;
  }
  ._1sbkoz62cq {
    flex: 0 1 auto;
  }
  ._1sbkoz62cw {
    flex: none;
  }
  ._1sbkoz62d2 {
    width: var(--space-0);
  }
  ._1sbkoz62d8 {
    width: var(--space-1);
  }
  ._1sbkoz62de {
    width: var(--space-2);
  }
  ._1sbkoz62dk {
    width: var(--space-3);
  }
  ._1sbkoz62dq {
    width: var(--space-4);
  }
  ._1sbkoz62dw {
    width: var(--space-5);
  }
  ._1sbkoz62e2 {
    width: var(--space-6);
  }
  ._1sbkoz62e8 {
    width: var(--space-7);
  }
  ._1sbkoz62ee {
    width: var(--space-px);
  }
  ._1sbkoz62ek {
    width: var(--space-4px);
  }
  ._1sbkoz62eq {
    width: var(--space-8px);
  }
  ._1sbkoz62ew {
    width: var(--space-12px);
  }
  ._1sbkoz62f2 {
    width: var(--space-16px);
  }
  ._1sbkoz62f8 {
    width: var(--space-24px);
  }
  ._1sbkoz62fe {
    width: var(--space-32px);
  }
  ._1sbkoz62fk {
    width: var(--space-48px);
  }
  ._1sbkoz62fq {
    width: var(--space-56px);
  }
  ._1sbkoz62fw {
    width: var(--space-64px);
  }
  ._1sbkoz62g2 {
    width: 100%;
  }
  ._1sbkoz62g8 {
    width: 100%;
  }
  ._1sbkoz62ge {
    width: auto;
  }
  ._1sbkoz62gk {
    width: 576px;
  }
  ._1sbkoz62gq {
    width: 852px;
  }
  ._1sbkoz62gw {
    width: 968px;
  }
  ._1sbkoz62h2 {
    width: 1080px;
  }
  ._1sbkoz62h8 {
    z-index: 0;
  }
  ._1sbkoz62he {
    z-index: 10;
  }
  ._1sbkoz62hk {
    z-index: 20;
  }
  ._1sbkoz62hq {
    z-index: 30;
  }
  ._1sbkoz62hw {
    z-index: 40;
  }
  ._1sbkoz62i2 {
    z-index: 50;
  }
  ._1sbkoz62i8 {
    z-index: 75;
  }
  ._1sbkoz62ie {
    z-index: 99;
  }
  ._1sbkoz62ik {
    z-index: 100;
  }
  ._1sbkoz62iq {
    z-index: 9;
  }
  ._1sbkoz62iw {
    z-index: 10;
  }
  ._1sbkoz62j2 {
    z-index: 100;
  }
  ._1sbkoz62j8 {
    z-index: auto;
  }
  ._1sbkoz62je {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1sbkoz62jk {
    border-radius: var(--radii-0);
  }
  ._1sbkoz62jq {
    border-radius: var(--radii-small);
  }
  ._1sbkoz62jw {
    border-radius: var(--radii-default);
  }
  ._1sbkoz62k2 {
    border-radius: var(--radii-card);
  }
  ._1sbkoz62k8 {
    border-radius: var(--radii-circle);
  }
  ._1sbkoz62ke {
    border-top-left-radius: var(--radii-0);
  }
  ._1sbkoz62kk {
    border-top-left-radius: var(--radii-small);
  }
  ._1sbkoz62kq {
    border-top-left-radius: var(--radii-default);
  }
  ._1sbkoz62kw {
    border-top-left-radius: var(--radii-card);
  }
  ._1sbkoz62l2 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1sbkoz62l8 {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1sbkoz62le {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1sbkoz62lk {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1sbkoz62lq {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1sbkoz62lw {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1sbkoz62m2 {
    border-top-right-radius: var(--radii-0);
  }
  ._1sbkoz62m8 {
    border-top-right-radius: var(--radii-small);
  }
  ._1sbkoz62me {
    border-top-right-radius: var(--radii-default);
  }
  ._1sbkoz62mk {
    border-top-right-radius: var(--radii-card);
  }
  ._1sbkoz62mq {
    border-top-right-radius: var(--radii-circle);
  }
  ._1sbkoz62mw {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1sbkoz62n2 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1sbkoz62n8 {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1sbkoz62ne {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1sbkoz62nk {
    border-bottom-left-radius: var(--radii-circle);
  }
}
@media (min-width: 968px) {
  ._1sbkoz63 {
    display: block;
  }
  ._1sbkoz69 {
    display: flex;
  }
  ._1sbkoz6f {
    display: grid;
  }
  ._1sbkoz6l {
    display: inline;
  }
  ._1sbkoz6r {
    display: inline-flex;
  }
  ._1sbkoz6x {
    display: inline-block;
  }
  ._1sbkoz613 {
    display: none;
  }
  ._1sbkoz619 {
    display: contents;
  }
  ._1sbkoz61f {
    flex-direction: column;
  }
  ._1sbkoz61l {
    flex-direction: row;
  }
  ._1sbkoz61r {
    flex-direction: column-reverse;
  }
  ._1sbkoz61x {
    align-items: center;
  }
  ._1sbkoz623 {
    align-items: end;
  }
  ._1sbkoz629 {
    align-items: baseLine;
  }
  ._1sbkoz62f {
    align-items: inherit;
  }
  ._1sbkoz62l {
    align-items: flex-start;
  }
  ._1sbkoz62r {
    align-items: center;
  }
  ._1sbkoz62x {
    align-items: start;
  }
  ._1sbkoz633 {
    align-items: flex-end;
  }
  ._1sbkoz639 {
    align-items: stretch;
  }
  ._1sbkoz63f {
    flex-wrap: wrap;
  }
  ._1sbkoz63l {
    flex-wrap: nowrap;
  }
  ._1sbkoz63r {
    overflow: auto;
  }
  ._1sbkoz63x {
    overflow: hidden;
  }
  ._1sbkoz643 {
    overflow: scroll;
  }
  ._1sbkoz649 {
    overflow: unset;
  }
  ._1sbkoz64f {
    overflow-y: auto;
  }
  ._1sbkoz64l {
    overflow-y: hidden;
  }
  ._1sbkoz64r {
    overflow-y: scroll;
  }
  ._1sbkoz64x {
    overflow-x: auto;
  }
  ._1sbkoz653 {
    overflow-x: hidden;
  }
  ._1sbkoz659 {
    overflow-x: scroll;
  }
  ._1sbkoz65f {
    position: absolute;
  }
  ._1sbkoz65l {
    position: fixed;
  }
  ._1sbkoz65r {
    position: relative;
  }
  ._1sbkoz65x {
    position: -webkit-sticky;
    position: sticky;
  }
  ._1sbkoz663 {
    text-align: center;
  }
  ._1sbkoz669 {
    text-align: left;
  }
  ._1sbkoz66f {
    text-align: right;
  }
  ._1sbkoz66l {
    justify-content: flex-start;
  }
  ._1sbkoz66r {
    justify-content: center;
  }
  ._1sbkoz66x {
    justify-content: start;
  }
  ._1sbkoz673 {
    justify-content: flex-end;
  }
  ._1sbkoz679 {
    justify-content: stretch;
  }
  ._1sbkoz67f {
    justify-content: space-around;
  }
  ._1sbkoz67l {
    justify-content: space-between;
  }
  ._1sbkoz67r {
    justify-items: flex-start;
  }
  ._1sbkoz67x {
    justify-items: center;
  }
  ._1sbkoz683 {
    justify-items: start;
  }
  ._1sbkoz689 {
    justify-items: flex-end;
  }
  ._1sbkoz68f {
    justify-items: stretch;
  }
  ._1sbkoz68l {
    justify-self: flex-start;
  }
  ._1sbkoz68r {
    justify-self: center;
  }
  ._1sbkoz68x {
    justify-self: start;
  }
  ._1sbkoz693 {
    justify-self: flex-end;
  }
  ._1sbkoz699 {
    justify-self: stretch;
  }
  ._1sbkoz69f {
    inset: var(--space-0);
  }
  ._1sbkoz69l {
    inset: var(--space-1);
  }
  ._1sbkoz69r {
    inset: var(--space-2);
  }
  ._1sbkoz69x {
    inset: var(--space-3);
  }
  ._1sbkoz6a3 {
    inset: var(--space-4);
  }
  ._1sbkoz6a9 {
    inset: var(--space-5);
  }
  ._1sbkoz6af {
    inset: var(--space-6);
  }
  ._1sbkoz6al {
    inset: var(--space-7);
  }
  ._1sbkoz6ar {
    inset: var(--space-px);
  }
  ._1sbkoz6ax {
    inset: var(--space-4px);
  }
  ._1sbkoz6b3 {
    inset: var(--space-8px);
  }
  ._1sbkoz6b9 {
    inset: var(--space-12px);
  }
  ._1sbkoz6bf {
    inset: var(--space-16px);
  }
  ._1sbkoz6bl {
    inset: var(--space-24px);
  }
  ._1sbkoz6br {
    inset: var(--space-32px);
  }
  ._1sbkoz6bx {
    inset: var(--space-48px);
  }
  ._1sbkoz6c3 {
    inset: var(--space-56px);
  }
  ._1sbkoz6c9 {
    inset: var(--space-64px);
  }
  ._1sbkoz6cf {
    inset: calc(var(--space-px) * -1);
  }
  ._1sbkoz6cl {
    inset: calc(var(--space-1) * -1);
  }
  ._1sbkoz6cr {
    inset: calc(var(--space-2) * -1);
  }
  ._1sbkoz6cx {
    inset: calc(var(--space-3) * -1);
  }
  ._1sbkoz6d3 {
    inset: calc(var(--space-4) * -1);
  }
  ._1sbkoz6d9 {
    inset: calc(var(--space-5) * -1);
  }
  ._1sbkoz6df {
    inset: calc(var(--space-6) * -1);
  }
  ._1sbkoz6dl {
    inset: calc(var(--space-7) * -1);
  }
  ._1sbkoz6dr {
    height: var(--space-0);
  }
  ._1sbkoz6dx {
    height: var(--space-1);
  }
  ._1sbkoz6e3 {
    height: var(--space-2);
  }
  ._1sbkoz6e9 {
    height: var(--space-3);
  }
  ._1sbkoz6ef {
    height: var(--space-4);
  }
  ._1sbkoz6el {
    height: var(--space-5);
  }
  ._1sbkoz6er {
    height: var(--space-6);
  }
  ._1sbkoz6ex {
    height: var(--space-7);
  }
  ._1sbkoz6f3 {
    height: var(--space-px);
  }
  ._1sbkoz6f9 {
    height: var(--space-4px);
  }
  ._1sbkoz6ff {
    height: var(--space-8px);
  }
  ._1sbkoz6fl {
    height: var(--space-12px);
  }
  ._1sbkoz6fr {
    height: var(--space-16px);
  }
  ._1sbkoz6fx {
    height: var(--space-24px);
  }
  ._1sbkoz6g3 {
    height: var(--space-32px);
  }
  ._1sbkoz6g9 {
    height: var(--space-48px);
  }
  ._1sbkoz6gf {
    height: var(--space-56px);
  }
  ._1sbkoz6gl {
    height: var(--space-64px);
  }
  ._1sbkoz6gr {
    height: 100%;
  }
  ._1sbkoz6gx {
    height: 100%;
  }
  ._1sbkoz6h3 {
    height: auto;
  }
  ._1sbkoz6h9 {
    height: 576px;
  }
  ._1sbkoz6hf {
    height: 852px;
  }
  ._1sbkoz6hl {
    height: 968px;
  }
  ._1sbkoz6hr {
    height: 1080px;
  }
  ._1sbkoz6hx {
    left: var(--space-0);
  }
  ._1sbkoz6i3 {
    left: var(--space-1);
  }
  ._1sbkoz6i9 {
    left: var(--space-2);
  }
  ._1sbkoz6if {
    left: var(--space-3);
  }
  ._1sbkoz6il {
    left: var(--space-4);
  }
  ._1sbkoz6ir {
    left: var(--space-5);
  }
  ._1sbkoz6ix {
    left: var(--space-6);
  }
  ._1sbkoz6j3 {
    left: var(--space-7);
  }
  ._1sbkoz6j9 {
    left: var(--space-px);
  }
  ._1sbkoz6jf {
    left: var(--space-4px);
  }
  ._1sbkoz6jl {
    left: var(--space-8px);
  }
  ._1sbkoz6jr {
    left: var(--space-12px);
  }
  ._1sbkoz6jx {
    left: var(--space-16px);
  }
  ._1sbkoz6k3 {
    left: var(--space-24px);
  }
  ._1sbkoz6k9 {
    left: var(--space-32px);
  }
  ._1sbkoz6kf {
    left: var(--space-48px);
  }
  ._1sbkoz6kl {
    left: var(--space-56px);
  }
  ._1sbkoz6kr {
    left: var(--space-64px);
  }
  ._1sbkoz6kx {
    left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6l3 {
    left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6l9 {
    left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6lf {
    left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6ll {
    left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6lr {
    left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6lx {
    left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6m3 {
    left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6m9 {
    margin-bottom: var(--space-0);
  }
  ._1sbkoz6mf {
    margin-bottom: var(--space-1);
  }
  ._1sbkoz6ml {
    margin-bottom: var(--space-2);
  }
  ._1sbkoz6mr {
    margin-bottom: var(--space-3);
  }
  ._1sbkoz6mx {
    margin-bottom: var(--space-4);
  }
  ._1sbkoz6n3 {
    margin-bottom: var(--space-5);
  }
  ._1sbkoz6n9 {
    margin-bottom: var(--space-6);
  }
  ._1sbkoz6nf {
    margin-bottom: var(--space-7);
  }
  ._1sbkoz6nl {
    margin-bottom: var(--space-px);
  }
  ._1sbkoz6nr {
    margin-bottom: var(--space-4px);
  }
  ._1sbkoz6nx {
    margin-bottom: var(--space-8px);
  }
  ._1sbkoz6o3 {
    margin-bottom: var(--space-12px);
  }
  ._1sbkoz6o9 {
    margin-bottom: var(--space-16px);
  }
  ._1sbkoz6of {
    margin-bottom: var(--space-24px);
  }
  ._1sbkoz6ol {
    margin-bottom: var(--space-32px);
  }
  ._1sbkoz6or {
    margin-bottom: var(--space-48px);
  }
  ._1sbkoz6ox {
    margin-bottom: var(--space-56px);
  }
  ._1sbkoz6p3 {
    margin-bottom: var(--space-64px);
  }
  ._1sbkoz6p9 {
    margin-bottom: auto;
  }
  ._1sbkoz6pf {
    margin-bottom: calc(var(--space-px) * -1);
  }
  ._1sbkoz6pl {
    margin-bottom: calc(var(--space-1) * -1);
  }
  ._1sbkoz6pr {
    margin-bottom: calc(var(--space-2) * -1);
  }
  ._1sbkoz6px {
    margin-bottom: calc(var(--space-3) * -1);
  }
  ._1sbkoz6q3 {
    margin-bottom: calc(var(--space-4) * -1);
  }
  ._1sbkoz6q9 {
    margin-bottom: calc(var(--space-5) * -1);
  }
  ._1sbkoz6qf {
    margin-bottom: calc(var(--space-6) * -1);
  }
  ._1sbkoz6ql {
    margin-bottom: calc(var(--space-7) * -1);
  }
  ._1sbkoz6qr {
    margin-left: var(--space-0);
  }
  ._1sbkoz6qx {
    margin-left: var(--space-1);
  }
  ._1sbkoz6r3 {
    margin-left: var(--space-2);
  }
  ._1sbkoz6r9 {
    margin-left: var(--space-3);
  }
  ._1sbkoz6rf {
    margin-left: var(--space-4);
  }
  ._1sbkoz6rl {
    margin-left: var(--space-5);
  }
  ._1sbkoz6rr {
    margin-left: var(--space-6);
  }
  ._1sbkoz6rx {
    margin-left: var(--space-7);
  }
  ._1sbkoz6s3 {
    margin-left: var(--space-px);
  }
  ._1sbkoz6s9 {
    margin-left: var(--space-4px);
  }
  ._1sbkoz6sf {
    margin-left: var(--space-8px);
  }
  ._1sbkoz6sl {
    margin-left: var(--space-12px);
  }
  ._1sbkoz6sr {
    margin-left: var(--space-16px);
  }
  ._1sbkoz6sx {
    margin-left: var(--space-24px);
  }
  ._1sbkoz6t3 {
    margin-left: var(--space-32px);
  }
  ._1sbkoz6t9 {
    margin-left: var(--space-48px);
  }
  ._1sbkoz6tf {
    margin-left: var(--space-56px);
  }
  ._1sbkoz6tl {
    margin-left: var(--space-64px);
  }
  ._1sbkoz6tr {
    margin-left: auto;
  }
  ._1sbkoz6tx {
    margin-left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6u3 {
    margin-left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6u9 {
    margin-left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6uf {
    margin-left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6ul {
    margin-left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6ur {
    margin-left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6ux {
    margin-left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6v3 {
    margin-left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6v9 {
    margin-right: var(--space-0);
  }
  ._1sbkoz6vf {
    margin-right: var(--space-1);
  }
  ._1sbkoz6vl {
    margin-right: var(--space-2);
  }
  ._1sbkoz6vr {
    margin-right: var(--space-3);
  }
  ._1sbkoz6vx {
    margin-right: var(--space-4);
  }
  ._1sbkoz6w3 {
    margin-right: var(--space-5);
  }
  ._1sbkoz6w9 {
    margin-right: var(--space-6);
  }
  ._1sbkoz6wf {
    margin-right: var(--space-7);
  }
  ._1sbkoz6wl {
    margin-right: var(--space-px);
  }
  ._1sbkoz6wr {
    margin-right: var(--space-4px);
  }
  ._1sbkoz6wx {
    margin-right: var(--space-8px);
  }
  ._1sbkoz6x3 {
    margin-right: var(--space-12px);
  }
  ._1sbkoz6x9 {
    margin-right: var(--space-16px);
  }
  ._1sbkoz6xf {
    margin-right: var(--space-24px);
  }
  ._1sbkoz6xl {
    margin-right: var(--space-32px);
  }
  ._1sbkoz6xr {
    margin-right: var(--space-48px);
  }
  ._1sbkoz6xx {
    margin-right: var(--space-56px);
  }
  ._1sbkoz6y3 {
    margin-right: var(--space-64px);
  }
  ._1sbkoz6y9 {
    margin-right: auto;
  }
  ._1sbkoz6yf {
    margin-right: calc(var(--space-px) * -1);
  }
  ._1sbkoz6yl {
    margin-right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6yr {
    margin-right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6yx {
    margin-right: calc(var(--space-3) * -1);
  }
  ._1sbkoz6z3 {
    margin-right: calc(var(--space-4) * -1);
  }
  ._1sbkoz6z9 {
    margin-right: calc(var(--space-5) * -1);
  }
  ._1sbkoz6zf {
    margin-right: calc(var(--space-6) * -1);
  }
  ._1sbkoz6zl {
    margin-right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6zr {
    margin-top: var(--space-0);
  }
  ._1sbkoz6zx {
    margin-top: var(--space-1);
  }
  ._1sbkoz6103 {
    margin-top: var(--space-2);
  }
  ._1sbkoz6109 {
    margin-top: var(--space-3);
  }
  ._1sbkoz610f {
    margin-top: var(--space-4);
  }
  ._1sbkoz610l {
    margin-top: var(--space-5);
  }
  ._1sbkoz610r {
    margin-top: var(--space-6);
  }
  ._1sbkoz610x {
    margin-top: var(--space-7);
  }
  ._1sbkoz6113 {
    margin-top: var(--space-px);
  }
  ._1sbkoz6119 {
    margin-top: var(--space-4px);
  }
  ._1sbkoz611f {
    margin-top: var(--space-8px);
  }
  ._1sbkoz611l {
    margin-top: var(--space-12px);
  }
  ._1sbkoz611r {
    margin-top: var(--space-16px);
  }
  ._1sbkoz611x {
    margin-top: var(--space-24px);
  }
  ._1sbkoz6123 {
    margin-top: var(--space-32px);
  }
  ._1sbkoz6129 {
    margin-top: var(--space-48px);
  }
  ._1sbkoz612f {
    margin-top: var(--space-56px);
  }
  ._1sbkoz612l {
    margin-top: var(--space-64px);
  }
  ._1sbkoz612r {
    margin-top: auto;
  }
  ._1sbkoz612x {
    margin-top: calc(var(--space-px) * -1);
  }
  ._1sbkoz6133 {
    margin-top: calc(var(--space-1) * -1);
  }
  ._1sbkoz6139 {
    margin-top: calc(var(--space-2) * -1);
  }
  ._1sbkoz613f {
    margin-top: calc(var(--space-3) * -1);
  }
  ._1sbkoz613l {
    margin-top: calc(var(--space-4) * -1);
  }
  ._1sbkoz613r {
    margin-top: calc(var(--space-5) * -1);
  }
  ._1sbkoz613x {
    margin-top: calc(var(--space-6) * -1);
  }
  ._1sbkoz6143 {
    margin-top: calc(var(--space-7) * -1);
  }
  ._1sbkoz6149 {
    margin: var(--space-0);
  }
  ._1sbkoz614f {
    margin: var(--space-1);
  }
  ._1sbkoz614l {
    margin: var(--space-2);
  }
  ._1sbkoz614r {
    margin: var(--space-3);
  }
  ._1sbkoz614x {
    margin: var(--space-4);
  }
  ._1sbkoz6153 {
    margin: var(--space-5);
  }
  ._1sbkoz6159 {
    margin: var(--space-6);
  }
  ._1sbkoz615f {
    margin: var(--space-7);
  }
  ._1sbkoz615l {
    margin: var(--space-px);
  }
  ._1sbkoz615r {
    margin: var(--space-4px);
  }
  ._1sbkoz615x {
    margin: var(--space-8px);
  }
  ._1sbkoz6163 {
    margin: var(--space-12px);
  }
  ._1sbkoz6169 {
    margin: var(--space-16px);
  }
  ._1sbkoz616f {
    margin: var(--space-24px);
  }
  ._1sbkoz616l {
    margin: var(--space-32px);
  }
  ._1sbkoz616r {
    margin: var(--space-48px);
  }
  ._1sbkoz616x {
    margin: var(--space-56px);
  }
  ._1sbkoz6173 {
    margin: var(--space-64px);
  }
  ._1sbkoz6179 {
    margin: auto;
  }
  ._1sbkoz617f {
    margin: calc(var(--space-px) * -1);
  }
  ._1sbkoz617l {
    margin: calc(var(--space-1) * -1);
  }
  ._1sbkoz617r {
    margin: calc(var(--space-2) * -1);
  }
  ._1sbkoz617x {
    margin: calc(var(--space-3) * -1);
  }
  ._1sbkoz6183 {
    margin: calc(var(--space-4) * -1);
  }
  ._1sbkoz6189 {
    margin: calc(var(--space-5) * -1);
  }
  ._1sbkoz618f {
    margin: calc(var(--space-6) * -1);
  }
  ._1sbkoz618l {
    margin: calc(var(--space-7) * -1);
  }
  ._1sbkoz618r {
    padding: var(--space-0);
  }
  ._1sbkoz618x {
    padding: var(--space-1);
  }
  ._1sbkoz6193 {
    padding: var(--space-2);
  }
  ._1sbkoz6199 {
    padding: var(--space-3);
  }
  ._1sbkoz619f {
    padding: var(--space-4);
  }
  ._1sbkoz619l {
    padding: var(--space-5);
  }
  ._1sbkoz619r {
    padding: var(--space-6);
  }
  ._1sbkoz619x {
    padding: var(--space-7);
  }
  ._1sbkoz61a3 {
    padding: var(--space-px);
  }
  ._1sbkoz61a9 {
    padding: var(--space-4px);
  }
  ._1sbkoz61af {
    padding: var(--space-8px);
  }
  ._1sbkoz61al {
    padding: var(--space-12px);
  }
  ._1sbkoz61ar {
    padding: var(--space-16px);
  }
  ._1sbkoz61ax {
    padding: var(--space-24px);
  }
  ._1sbkoz61b3 {
    padding: var(--space-32px);
  }
  ._1sbkoz61b9 {
    padding: var(--space-48px);
  }
  ._1sbkoz61bf {
    padding: var(--space-56px);
  }
  ._1sbkoz61bl {
    padding: var(--space-64px);
  }
  ._1sbkoz61br {
    padding: auto;
  }
  ._1sbkoz61bx {
    padding: calc(var(--space-px) * -1);
  }
  ._1sbkoz61c3 {
    padding: calc(var(--space-1) * -1);
  }
  ._1sbkoz61c9 {
    padding: calc(var(--space-2) * -1);
  }
  ._1sbkoz61cf {
    padding: calc(var(--space-3) * -1);
  }
  ._1sbkoz61cl {
    padding: calc(var(--space-4) * -1);
  }
  ._1sbkoz61cr {
    padding: calc(var(--space-5) * -1);
  }
  ._1sbkoz61cx {
    padding: calc(var(--space-6) * -1);
  }
  ._1sbkoz61d3 {
    padding: calc(var(--space-7) * -1);
  }
  ._1sbkoz61d9 {
    max-height: var(--space-0);
  }
  ._1sbkoz61df {
    max-height: var(--space-1);
  }
  ._1sbkoz61dl {
    max-height: var(--space-2);
  }
  ._1sbkoz61dr {
    max-height: var(--space-3);
  }
  ._1sbkoz61dx {
    max-height: var(--space-4);
  }
  ._1sbkoz61e3 {
    max-height: var(--space-5);
  }
  ._1sbkoz61e9 {
    max-height: var(--space-6);
  }
  ._1sbkoz61ef {
    max-height: var(--space-7);
  }
  ._1sbkoz61el {
    max-height: var(--space-px);
  }
  ._1sbkoz61er {
    max-height: var(--space-4px);
  }
  ._1sbkoz61ex {
    max-height: var(--space-8px);
  }
  ._1sbkoz61f3 {
    max-height: var(--space-12px);
  }
  ._1sbkoz61f9 {
    max-height: var(--space-16px);
  }
  ._1sbkoz61ff {
    max-height: var(--space-24px);
  }
  ._1sbkoz61fl {
    max-height: var(--space-32px);
  }
  ._1sbkoz61fr {
    max-height: var(--space-48px);
  }
  ._1sbkoz61fx {
    max-height: var(--space-56px);
  }
  ._1sbkoz61g3 {
    max-height: var(--space-64px);
  }
  ._1sbkoz61g9 {
    max-width: var(--space-0);
  }
  ._1sbkoz61gf {
    max-width: var(--space-1);
  }
  ._1sbkoz61gl {
    max-width: var(--space-2);
  }
  ._1sbkoz61gr {
    max-width: var(--space-3);
  }
  ._1sbkoz61gx {
    max-width: var(--space-4);
  }
  ._1sbkoz61h3 {
    max-width: var(--space-5);
  }
  ._1sbkoz61h9 {
    max-width: var(--space-6);
  }
  ._1sbkoz61hf {
    max-width: var(--space-7);
  }
  ._1sbkoz61hl {
    max-width: var(--space-px);
  }
  ._1sbkoz61hr {
    max-width: var(--space-4px);
  }
  ._1sbkoz61hx {
    max-width: var(--space-8px);
  }
  ._1sbkoz61i3 {
    max-width: var(--space-12px);
  }
  ._1sbkoz61i9 {
    max-width: var(--space-16px);
  }
  ._1sbkoz61if {
    max-width: var(--space-24px);
  }
  ._1sbkoz61il {
    max-width: var(--space-32px);
  }
  ._1sbkoz61ir {
    max-width: var(--space-48px);
  }
  ._1sbkoz61ix {
    max-width: var(--space-56px);
  }
  ._1sbkoz61j3 {
    max-width: var(--space-64px);
  }
  ._1sbkoz61j9 {
    max-width: 100%;
  }
  ._1sbkoz61jf {
    max-width: 100%;
  }
  ._1sbkoz61jl {
    max-width: auto;
  }
  ._1sbkoz61jr {
    max-width: 576px;
  }
  ._1sbkoz61jx {
    max-width: 852px;
  }
  ._1sbkoz61k3 {
    max-width: 968px;
  }
  ._1sbkoz61k9 {
    max-width: 1080px;
  }
  ._1sbkoz61kf {
    max-width: none;
  }
  ._1sbkoz61kl {
    min-height: var(--space-0);
  }
  ._1sbkoz61kr {
    min-height: var(--space-1);
  }
  ._1sbkoz61kx {
    min-height: var(--space-2);
  }
  ._1sbkoz61l3 {
    min-height: var(--space-3);
  }
  ._1sbkoz61l9 {
    min-height: var(--space-4);
  }
  ._1sbkoz61lf {
    min-height: var(--space-5);
  }
  ._1sbkoz61ll {
    min-height: var(--space-6);
  }
  ._1sbkoz61lr {
    min-height: var(--space-7);
  }
  ._1sbkoz61lx {
    min-height: var(--space-px);
  }
  ._1sbkoz61m3 {
    min-height: var(--space-4px);
  }
  ._1sbkoz61m9 {
    min-height: var(--space-8px);
  }
  ._1sbkoz61mf {
    min-height: var(--space-12px);
  }
  ._1sbkoz61ml {
    min-height: var(--space-16px);
  }
  ._1sbkoz61mr {
    min-height: var(--space-24px);
  }
  ._1sbkoz61mx {
    min-height: var(--space-32px);
  }
  ._1sbkoz61n3 {
    min-height: var(--space-48px);
  }
  ._1sbkoz61n9 {
    min-height: var(--space-56px);
  }
  ._1sbkoz61nf {
    min-height: var(--space-64px);
  }
  ._1sbkoz61nl {
    min-width: var(--space-0);
  }
  ._1sbkoz61nr {
    min-width: var(--space-1);
  }
  ._1sbkoz61nx {
    min-width: var(--space-2);
  }
  ._1sbkoz61o3 {
    min-width: var(--space-3);
  }
  ._1sbkoz61o9 {
    min-width: var(--space-4);
  }
  ._1sbkoz61of {
    min-width: var(--space-5);
  }
  ._1sbkoz61ol {
    min-width: var(--space-6);
  }
  ._1sbkoz61or {
    min-width: var(--space-7);
  }
  ._1sbkoz61ox {
    min-width: var(--space-px);
  }
  ._1sbkoz61p3 {
    min-width: var(--space-4px);
  }
  ._1sbkoz61p9 {
    min-width: var(--space-8px);
  }
  ._1sbkoz61pf {
    min-width: var(--space-12px);
  }
  ._1sbkoz61pl {
    min-width: var(--space-16px);
  }
  ._1sbkoz61pr {
    min-width: var(--space-24px);
  }
  ._1sbkoz61px {
    min-width: var(--space-32px);
  }
  ._1sbkoz61q3 {
    min-width: var(--space-48px);
  }
  ._1sbkoz61q9 {
    min-width: var(--space-56px);
  }
  ._1sbkoz61qf {
    min-width: var(--space-64px);
  }
  ._1sbkoz61ql {
    padding-bottom: var(--space-0);
  }
  ._1sbkoz61qr {
    padding-bottom: var(--space-1);
  }
  ._1sbkoz61qx {
    padding-bottom: var(--space-2);
  }
  ._1sbkoz61r3 {
    padding-bottom: var(--space-3);
  }
  ._1sbkoz61r9 {
    padding-bottom: var(--space-4);
  }
  ._1sbkoz61rf {
    padding-bottom: var(--space-5);
  }
  ._1sbkoz61rl {
    padding-bottom: var(--space-6);
  }
  ._1sbkoz61rr {
    padding-bottom: var(--space-7);
  }
  ._1sbkoz61rx {
    padding-bottom: var(--space-px);
  }
  ._1sbkoz61s3 {
    padding-bottom: var(--space-4px);
  }
  ._1sbkoz61s9 {
    padding-bottom: var(--space-8px);
  }
  ._1sbkoz61sf {
    padding-bottom: var(--space-12px);
  }
  ._1sbkoz61sl {
    padding-bottom: var(--space-16px);
  }
  ._1sbkoz61sr {
    padding-bottom: var(--space-24px);
  }
  ._1sbkoz61sx {
    padding-bottom: var(--space-32px);
  }
  ._1sbkoz61t3 {
    padding-bottom: var(--space-48px);
  }
  ._1sbkoz61t9 {
    padding-bottom: var(--space-56px);
  }
  ._1sbkoz61tf {
    padding-bottom: var(--space-64px);
  }
  ._1sbkoz61tl {
    padding-left: var(--space-0);
  }
  ._1sbkoz61tr {
    padding-left: var(--space-1);
  }
  ._1sbkoz61tx {
    padding-left: var(--space-2);
  }
  ._1sbkoz61u3 {
    padding-left: var(--space-3);
  }
  ._1sbkoz61u9 {
    padding-left: var(--space-4);
  }
  ._1sbkoz61uf {
    padding-left: var(--space-5);
  }
  ._1sbkoz61ul {
    padding-left: var(--space-6);
  }
  ._1sbkoz61ur {
    padding-left: var(--space-7);
  }
  ._1sbkoz61ux {
    padding-left: var(--space-px);
  }
  ._1sbkoz61v3 {
    padding-left: var(--space-4px);
  }
  ._1sbkoz61v9 {
    padding-left: var(--space-8px);
  }
  ._1sbkoz61vf {
    padding-left: var(--space-12px);
  }
  ._1sbkoz61vl {
    padding-left: var(--space-16px);
  }
  ._1sbkoz61vr {
    padding-left: var(--space-24px);
  }
  ._1sbkoz61vx {
    padding-left: var(--space-32px);
  }
  ._1sbkoz61w3 {
    padding-left: var(--space-48px);
  }
  ._1sbkoz61w9 {
    padding-left: var(--space-56px);
  }
  ._1sbkoz61wf {
    padding-left: var(--space-64px);
  }
  ._1sbkoz61wl {
    padding-right: var(--space-0);
  }
  ._1sbkoz61wr {
    padding-right: var(--space-1);
  }
  ._1sbkoz61wx {
    padding-right: var(--space-2);
  }
  ._1sbkoz61x3 {
    padding-right: var(--space-3);
  }
  ._1sbkoz61x9 {
    padding-right: var(--space-4);
  }
  ._1sbkoz61xf {
    padding-right: var(--space-5);
  }
  ._1sbkoz61xl {
    padding-right: var(--space-6);
  }
  ._1sbkoz61xr {
    padding-right: var(--space-7);
  }
  ._1sbkoz61xx {
    padding-right: var(--space-px);
  }
  ._1sbkoz61y3 {
    padding-right: var(--space-4px);
  }
  ._1sbkoz61y9 {
    padding-right: var(--space-8px);
  }
  ._1sbkoz61yf {
    padding-right: var(--space-12px);
  }
  ._1sbkoz61yl {
    padding-right: var(--space-16px);
  }
  ._1sbkoz61yr {
    padding-right: var(--space-24px);
  }
  ._1sbkoz61yx {
    padding-right: var(--space-32px);
  }
  ._1sbkoz61z3 {
    padding-right: var(--space-48px);
  }
  ._1sbkoz61z9 {
    padding-right: var(--space-56px);
  }
  ._1sbkoz61zf {
    padding-right: var(--space-64px);
  }
  ._1sbkoz61zl {
    padding-top: var(--space-0);
  }
  ._1sbkoz61zr {
    padding-top: var(--space-1);
  }
  ._1sbkoz61zx {
    padding-top: var(--space-2);
  }
  ._1sbkoz6203 {
    padding-top: var(--space-3);
  }
  ._1sbkoz6209 {
    padding-top: var(--space-4);
  }
  ._1sbkoz620f {
    padding-top: var(--space-5);
  }
  ._1sbkoz620l {
    padding-top: var(--space-6);
  }
  ._1sbkoz620r {
    padding-top: var(--space-7);
  }
  ._1sbkoz620x {
    padding-top: var(--space-px);
  }
  ._1sbkoz6213 {
    padding-top: var(--space-4px);
  }
  ._1sbkoz6219 {
    padding-top: var(--space-8px);
  }
  ._1sbkoz621f {
    padding-top: var(--space-12px);
  }
  ._1sbkoz621l {
    padding-top: var(--space-16px);
  }
  ._1sbkoz621r {
    padding-top: var(--space-24px);
  }
  ._1sbkoz621x {
    padding-top: var(--space-32px);
  }
  ._1sbkoz6223 {
    padding-top: var(--space-48px);
  }
  ._1sbkoz6229 {
    padding-top: var(--space-56px);
  }
  ._1sbkoz622f {
    padding-top: var(--space-64px);
  }
  ._1sbkoz622l {
    font-size: var(--fontSizes-10px);
  }
  ._1sbkoz622r {
    font-size: var(--fontSizes-12px);
  }
  ._1sbkoz622x {
    font-size: var(--fontSizes-16px);
  }
  ._1sbkoz6233 {
    font-size: var(--fontSizes-14px);
  }
  ._1sbkoz6239 {
    font-size: var(--fontSizes-20px);
  }
  ._1sbkoz623f {
    font-size: var(--fontSizes-40px);
  }
  ._1sbkoz623l {
    font-size: inherit;
  }
  ._1sbkoz623r {
    right: var(--space-0);
  }
  ._1sbkoz623x {
    right: var(--space-1);
  }
  ._1sbkoz6243 {
    right: var(--space-2);
  }
  ._1sbkoz6249 {
    right: var(--space-3);
  }
  ._1sbkoz624f {
    right: var(--space-4);
  }
  ._1sbkoz624l {
    right: var(--space-5);
  }
  ._1sbkoz624r {
    right: var(--space-6);
  }
  ._1sbkoz624x {
    right: var(--space-7);
  }
  ._1sbkoz6253 {
    right: var(--space-px);
  }
  ._1sbkoz6259 {
    right: var(--space-4px);
  }
  ._1sbkoz625f {
    right: var(--space-8px);
  }
  ._1sbkoz625l {
    right: var(--space-12px);
  }
  ._1sbkoz625r {
    right: var(--space-16px);
  }
  ._1sbkoz625x {
    right: var(--space-24px);
  }
  ._1sbkoz6263 {
    right: var(--space-32px);
  }
  ._1sbkoz6269 {
    right: var(--space-48px);
  }
  ._1sbkoz626f {
    right: var(--space-56px);
  }
  ._1sbkoz626l {
    right: var(--space-64px);
  }
  ._1sbkoz626r {
    right: calc(var(--space-px) * -1);
  }
  ._1sbkoz626x {
    right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6273 {
    right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6279 {
    right: calc(var(--space-3) * -1);
  }
  ._1sbkoz627f {
    right: calc(var(--space-4) * -1);
  }
  ._1sbkoz627l {
    right: calc(var(--space-5) * -1);
  }
  ._1sbkoz627r {
    right: calc(var(--space-6) * -1);
  }
  ._1sbkoz627x {
    right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6283 {
    top: var(--space-0);
  }
  ._1sbkoz6289 {
    top: var(--space-1);
  }
  ._1sbkoz628f {
    top: var(--space-2);
  }
  ._1sbkoz628l {
    top: var(--space-3);
  }
  ._1sbkoz628r {
    top: var(--space-4);
  }
  ._1sbkoz628x {
    top: var(--space-5);
  }
  ._1sbkoz6293 {
    top: var(--space-6);
  }
  ._1sbkoz6299 {
    top: var(--space-7);
  }
  ._1sbkoz629f {
    top: var(--space-px);
  }
  ._1sbkoz629l {
    top: var(--space-4px);
  }
  ._1sbkoz629r {
    top: var(--space-8px);
  }
  ._1sbkoz629x {
    top: var(--space-12px);
  }
  ._1sbkoz62a3 {
    top: var(--space-16px);
  }
  ._1sbkoz62a9 {
    top: var(--space-24px);
  }
  ._1sbkoz62af {
    top: var(--space-32px);
  }
  ._1sbkoz62al {
    top: var(--space-48px);
  }
  ._1sbkoz62ar {
    top: var(--space-56px);
  }
  ._1sbkoz62ax {
    top: var(--space-64px);
  }
  ._1sbkoz62b3 {
    top: calc(var(--space-px) * -1);
  }
  ._1sbkoz62b9 {
    top: calc(var(--space-1) * -1);
  }
  ._1sbkoz62bf {
    top: calc(var(--space-2) * -1);
  }
  ._1sbkoz62bl {
    top: calc(var(--space-3) * -1);
  }
  ._1sbkoz62br {
    top: calc(var(--space-4) * -1);
  }
  ._1sbkoz62bx {
    top: calc(var(--space-5) * -1);
  }
  ._1sbkoz62c3 {
    top: calc(var(--space-6) * -1);
  }
  ._1sbkoz62c9 {
    top: calc(var(--space-7) * -1);
  }
  ._1sbkoz62cf {
    flex: 1 1;
  }
  ._1sbkoz62cl {
    flex: 1 1 auto;
  }
  ._1sbkoz62cr {
    flex: 0 1 auto;
  }
  ._1sbkoz62cx {
    flex: none;
  }
  ._1sbkoz62d3 {
    width: var(--space-0);
  }
  ._1sbkoz62d9 {
    width: var(--space-1);
  }
  ._1sbkoz62df {
    width: var(--space-2);
  }
  ._1sbkoz62dl {
    width: var(--space-3);
  }
  ._1sbkoz62dr {
    width: var(--space-4);
  }
  ._1sbkoz62dx {
    width: var(--space-5);
  }
  ._1sbkoz62e3 {
    width: var(--space-6);
  }
  ._1sbkoz62e9 {
    width: var(--space-7);
  }
  ._1sbkoz62ef {
    width: var(--space-px);
  }
  ._1sbkoz62el {
    width: var(--space-4px);
  }
  ._1sbkoz62er {
    width: var(--space-8px);
  }
  ._1sbkoz62ex {
    width: var(--space-12px);
  }
  ._1sbkoz62f3 {
    width: var(--space-16px);
  }
  ._1sbkoz62f9 {
    width: var(--space-24px);
  }
  ._1sbkoz62ff {
    width: var(--space-32px);
  }
  ._1sbkoz62fl {
    width: var(--space-48px);
  }
  ._1sbkoz62fr {
    width: var(--space-56px);
  }
  ._1sbkoz62fx {
    width: var(--space-64px);
  }
  ._1sbkoz62g3 {
    width: 100%;
  }
  ._1sbkoz62g9 {
    width: 100%;
  }
  ._1sbkoz62gf {
    width: auto;
  }
  ._1sbkoz62gl {
    width: 576px;
  }
  ._1sbkoz62gr {
    width: 852px;
  }
  ._1sbkoz62gx {
    width: 968px;
  }
  ._1sbkoz62h3 {
    width: 1080px;
  }
  ._1sbkoz62h9 {
    z-index: 0;
  }
  ._1sbkoz62hf {
    z-index: 10;
  }
  ._1sbkoz62hl {
    z-index: 20;
  }
  ._1sbkoz62hr {
    z-index: 30;
  }
  ._1sbkoz62hx {
    z-index: 40;
  }
  ._1sbkoz62i3 {
    z-index: 50;
  }
  ._1sbkoz62i9 {
    z-index: 75;
  }
  ._1sbkoz62if {
    z-index: 99;
  }
  ._1sbkoz62il {
    z-index: 100;
  }
  ._1sbkoz62ir {
    z-index: 9;
  }
  ._1sbkoz62ix {
    z-index: 10;
  }
  ._1sbkoz62j3 {
    z-index: 100;
  }
  ._1sbkoz62j9 {
    z-index: auto;
  }
  ._1sbkoz62jf {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1sbkoz62jl {
    border-radius: var(--radii-0);
  }
  ._1sbkoz62jr {
    border-radius: var(--radii-small);
  }
  ._1sbkoz62jx {
    border-radius: var(--radii-default);
  }
  ._1sbkoz62k3 {
    border-radius: var(--radii-card);
  }
  ._1sbkoz62k9 {
    border-radius: var(--radii-circle);
  }
  ._1sbkoz62kf {
    border-top-left-radius: var(--radii-0);
  }
  ._1sbkoz62kl {
    border-top-left-radius: var(--radii-small);
  }
  ._1sbkoz62kr {
    border-top-left-radius: var(--radii-default);
  }
  ._1sbkoz62kx {
    border-top-left-radius: var(--radii-card);
  }
  ._1sbkoz62l3 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1sbkoz62l9 {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1sbkoz62lf {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1sbkoz62ll {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1sbkoz62lr {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1sbkoz62lx {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1sbkoz62m3 {
    border-top-right-radius: var(--radii-0);
  }
  ._1sbkoz62m9 {
    border-top-right-radius: var(--radii-small);
  }
  ._1sbkoz62mf {
    border-top-right-radius: var(--radii-default);
  }
  ._1sbkoz62ml {
    border-top-right-radius: var(--radii-card);
  }
  ._1sbkoz62mr {
    border-top-right-radius: var(--radii-circle);
  }
  ._1sbkoz62mx {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1sbkoz62n3 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1sbkoz62n9 {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1sbkoz62nf {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1sbkoz62nl {
    border-bottom-left-radius: var(--radii-circle);
  }
}
@media (min-width: 1080px) {
  ._1sbkoz64 {
    display: block;
  }
  ._1sbkoz6a {
    display: flex;
  }
  ._1sbkoz6g {
    display: grid;
  }
  ._1sbkoz6m {
    display: inline;
  }
  ._1sbkoz6s {
    display: inline-flex;
  }
  ._1sbkoz6y {
    display: inline-block;
  }
  ._1sbkoz614 {
    display: none;
  }
  ._1sbkoz61a {
    display: contents;
  }
  ._1sbkoz61g {
    flex-direction: column;
  }
  ._1sbkoz61m {
    flex-direction: row;
  }
  ._1sbkoz61s {
    flex-direction: column-reverse;
  }
  ._1sbkoz61y {
    align-items: center;
  }
  ._1sbkoz624 {
    align-items: end;
  }
  ._1sbkoz62a {
    align-items: baseLine;
  }
  ._1sbkoz62g {
    align-items: inherit;
  }
  ._1sbkoz62m {
    align-items: flex-start;
  }
  ._1sbkoz62s {
    align-items: center;
  }
  ._1sbkoz62y {
    align-items: start;
  }
  ._1sbkoz634 {
    align-items: flex-end;
  }
  ._1sbkoz63a {
    align-items: stretch;
  }
  ._1sbkoz63g {
    flex-wrap: wrap;
  }
  ._1sbkoz63m {
    flex-wrap: nowrap;
  }
  ._1sbkoz63s {
    overflow: auto;
  }
  ._1sbkoz63y {
    overflow: hidden;
  }
  ._1sbkoz644 {
    overflow: scroll;
  }
  ._1sbkoz64a {
    overflow: unset;
  }
  ._1sbkoz64g {
    overflow-y: auto;
  }
  ._1sbkoz64m {
    overflow-y: hidden;
  }
  ._1sbkoz64s {
    overflow-y: scroll;
  }
  ._1sbkoz64y {
    overflow-x: auto;
  }
  ._1sbkoz654 {
    overflow-x: hidden;
  }
  ._1sbkoz65a {
    overflow-x: scroll;
  }
  ._1sbkoz65g {
    position: absolute;
  }
  ._1sbkoz65m {
    position: fixed;
  }
  ._1sbkoz65s {
    position: relative;
  }
  ._1sbkoz65y {
    position: -webkit-sticky;
    position: sticky;
  }
  ._1sbkoz664 {
    text-align: center;
  }
  ._1sbkoz66a {
    text-align: left;
  }
  ._1sbkoz66g {
    text-align: right;
  }
  ._1sbkoz66m {
    justify-content: flex-start;
  }
  ._1sbkoz66s {
    justify-content: center;
  }
  ._1sbkoz66y {
    justify-content: start;
  }
  ._1sbkoz674 {
    justify-content: flex-end;
  }
  ._1sbkoz67a {
    justify-content: stretch;
  }
  ._1sbkoz67g {
    justify-content: space-around;
  }
  ._1sbkoz67m {
    justify-content: space-between;
  }
  ._1sbkoz67s {
    justify-items: flex-start;
  }
  ._1sbkoz67y {
    justify-items: center;
  }
  ._1sbkoz684 {
    justify-items: start;
  }
  ._1sbkoz68a {
    justify-items: flex-end;
  }
  ._1sbkoz68g {
    justify-items: stretch;
  }
  ._1sbkoz68m {
    justify-self: flex-start;
  }
  ._1sbkoz68s {
    justify-self: center;
  }
  ._1sbkoz68y {
    justify-self: start;
  }
  ._1sbkoz694 {
    justify-self: flex-end;
  }
  ._1sbkoz69a {
    justify-self: stretch;
  }
  ._1sbkoz69g {
    inset: var(--space-0);
  }
  ._1sbkoz69m {
    inset: var(--space-1);
  }
  ._1sbkoz69s {
    inset: var(--space-2);
  }
  ._1sbkoz69y {
    inset: var(--space-3);
  }
  ._1sbkoz6a4 {
    inset: var(--space-4);
  }
  ._1sbkoz6aa {
    inset: var(--space-5);
  }
  ._1sbkoz6ag {
    inset: var(--space-6);
  }
  ._1sbkoz6am {
    inset: var(--space-7);
  }
  ._1sbkoz6as {
    inset: var(--space-px);
  }
  ._1sbkoz6ay {
    inset: var(--space-4px);
  }
  ._1sbkoz6b4 {
    inset: var(--space-8px);
  }
  ._1sbkoz6ba {
    inset: var(--space-12px);
  }
  ._1sbkoz6bg {
    inset: var(--space-16px);
  }
  ._1sbkoz6bm {
    inset: var(--space-24px);
  }
  ._1sbkoz6bs {
    inset: var(--space-32px);
  }
  ._1sbkoz6by {
    inset: var(--space-48px);
  }
  ._1sbkoz6c4 {
    inset: var(--space-56px);
  }
  ._1sbkoz6ca {
    inset: var(--space-64px);
  }
  ._1sbkoz6cg {
    inset: calc(var(--space-px) * -1);
  }
  ._1sbkoz6cm {
    inset: calc(var(--space-1) * -1);
  }
  ._1sbkoz6cs {
    inset: calc(var(--space-2) * -1);
  }
  ._1sbkoz6cy {
    inset: calc(var(--space-3) * -1);
  }
  ._1sbkoz6d4 {
    inset: calc(var(--space-4) * -1);
  }
  ._1sbkoz6da {
    inset: calc(var(--space-5) * -1);
  }
  ._1sbkoz6dg {
    inset: calc(var(--space-6) * -1);
  }
  ._1sbkoz6dm {
    inset: calc(var(--space-7) * -1);
  }
  ._1sbkoz6ds {
    height: var(--space-0);
  }
  ._1sbkoz6dy {
    height: var(--space-1);
  }
  ._1sbkoz6e4 {
    height: var(--space-2);
  }
  ._1sbkoz6ea {
    height: var(--space-3);
  }
  ._1sbkoz6eg {
    height: var(--space-4);
  }
  ._1sbkoz6em {
    height: var(--space-5);
  }
  ._1sbkoz6es {
    height: var(--space-6);
  }
  ._1sbkoz6ey {
    height: var(--space-7);
  }
  ._1sbkoz6f4 {
    height: var(--space-px);
  }
  ._1sbkoz6fa {
    height: var(--space-4px);
  }
  ._1sbkoz6fg {
    height: var(--space-8px);
  }
  ._1sbkoz6fm {
    height: var(--space-12px);
  }
  ._1sbkoz6fs {
    height: var(--space-16px);
  }
  ._1sbkoz6fy {
    height: var(--space-24px);
  }
  ._1sbkoz6g4 {
    height: var(--space-32px);
  }
  ._1sbkoz6ga {
    height: var(--space-48px);
  }
  ._1sbkoz6gg {
    height: var(--space-56px);
  }
  ._1sbkoz6gm {
    height: var(--space-64px);
  }
  ._1sbkoz6gs {
    height: 100%;
  }
  ._1sbkoz6gy {
    height: 100%;
  }
  ._1sbkoz6h4 {
    height: auto;
  }
  ._1sbkoz6ha {
    height: 576px;
  }
  ._1sbkoz6hg {
    height: 852px;
  }
  ._1sbkoz6hm {
    height: 968px;
  }
  ._1sbkoz6hs {
    height: 1080px;
  }
  ._1sbkoz6hy {
    left: var(--space-0);
  }
  ._1sbkoz6i4 {
    left: var(--space-1);
  }
  ._1sbkoz6ia {
    left: var(--space-2);
  }
  ._1sbkoz6ig {
    left: var(--space-3);
  }
  ._1sbkoz6im {
    left: var(--space-4);
  }
  ._1sbkoz6is {
    left: var(--space-5);
  }
  ._1sbkoz6iy {
    left: var(--space-6);
  }
  ._1sbkoz6j4 {
    left: var(--space-7);
  }
  ._1sbkoz6ja {
    left: var(--space-px);
  }
  ._1sbkoz6jg {
    left: var(--space-4px);
  }
  ._1sbkoz6jm {
    left: var(--space-8px);
  }
  ._1sbkoz6js {
    left: var(--space-12px);
  }
  ._1sbkoz6jy {
    left: var(--space-16px);
  }
  ._1sbkoz6k4 {
    left: var(--space-24px);
  }
  ._1sbkoz6ka {
    left: var(--space-32px);
  }
  ._1sbkoz6kg {
    left: var(--space-48px);
  }
  ._1sbkoz6km {
    left: var(--space-56px);
  }
  ._1sbkoz6ks {
    left: var(--space-64px);
  }
  ._1sbkoz6ky {
    left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6l4 {
    left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6la {
    left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6lg {
    left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6lm {
    left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6ls {
    left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6ly {
    left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6m4 {
    left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6ma {
    margin-bottom: var(--space-0);
  }
  ._1sbkoz6mg {
    margin-bottom: var(--space-1);
  }
  ._1sbkoz6mm {
    margin-bottom: var(--space-2);
  }
  ._1sbkoz6ms {
    margin-bottom: var(--space-3);
  }
  ._1sbkoz6my {
    margin-bottom: var(--space-4);
  }
  ._1sbkoz6n4 {
    margin-bottom: var(--space-5);
  }
  ._1sbkoz6na {
    margin-bottom: var(--space-6);
  }
  ._1sbkoz6ng {
    margin-bottom: var(--space-7);
  }
  ._1sbkoz6nm {
    margin-bottom: var(--space-px);
  }
  ._1sbkoz6ns {
    margin-bottom: var(--space-4px);
  }
  ._1sbkoz6ny {
    margin-bottom: var(--space-8px);
  }
  ._1sbkoz6o4 {
    margin-bottom: var(--space-12px);
  }
  ._1sbkoz6oa {
    margin-bottom: var(--space-16px);
  }
  ._1sbkoz6og {
    margin-bottom: var(--space-24px);
  }
  ._1sbkoz6om {
    margin-bottom: var(--space-32px);
  }
  ._1sbkoz6os {
    margin-bottom: var(--space-48px);
  }
  ._1sbkoz6oy {
    margin-bottom: var(--space-56px);
  }
  ._1sbkoz6p4 {
    margin-bottom: var(--space-64px);
  }
  ._1sbkoz6pa {
    margin-bottom: auto;
  }
  ._1sbkoz6pg {
    margin-bottom: calc(var(--space-px) * -1);
  }
  ._1sbkoz6pm {
    margin-bottom: calc(var(--space-1) * -1);
  }
  ._1sbkoz6ps {
    margin-bottom: calc(var(--space-2) * -1);
  }
  ._1sbkoz6py {
    margin-bottom: calc(var(--space-3) * -1);
  }
  ._1sbkoz6q4 {
    margin-bottom: calc(var(--space-4) * -1);
  }
  ._1sbkoz6qa {
    margin-bottom: calc(var(--space-5) * -1);
  }
  ._1sbkoz6qg {
    margin-bottom: calc(var(--space-6) * -1);
  }
  ._1sbkoz6qm {
    margin-bottom: calc(var(--space-7) * -1);
  }
  ._1sbkoz6qs {
    margin-left: var(--space-0);
  }
  ._1sbkoz6qy {
    margin-left: var(--space-1);
  }
  ._1sbkoz6r4 {
    margin-left: var(--space-2);
  }
  ._1sbkoz6ra {
    margin-left: var(--space-3);
  }
  ._1sbkoz6rg {
    margin-left: var(--space-4);
  }
  ._1sbkoz6rm {
    margin-left: var(--space-5);
  }
  ._1sbkoz6rs {
    margin-left: var(--space-6);
  }
  ._1sbkoz6ry {
    margin-left: var(--space-7);
  }
  ._1sbkoz6s4 {
    margin-left: var(--space-px);
  }
  ._1sbkoz6sa {
    margin-left: var(--space-4px);
  }
  ._1sbkoz6sg {
    margin-left: var(--space-8px);
  }
  ._1sbkoz6sm {
    margin-left: var(--space-12px);
  }
  ._1sbkoz6ss {
    margin-left: var(--space-16px);
  }
  ._1sbkoz6sy {
    margin-left: var(--space-24px);
  }
  ._1sbkoz6t4 {
    margin-left: var(--space-32px);
  }
  ._1sbkoz6ta {
    margin-left: var(--space-48px);
  }
  ._1sbkoz6tg {
    margin-left: var(--space-56px);
  }
  ._1sbkoz6tm {
    margin-left: var(--space-64px);
  }
  ._1sbkoz6ts {
    margin-left: auto;
  }
  ._1sbkoz6ty {
    margin-left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6u4 {
    margin-left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6ua {
    margin-left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6ug {
    margin-left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6um {
    margin-left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6us {
    margin-left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6uy {
    margin-left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6v4 {
    margin-left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6va {
    margin-right: var(--space-0);
  }
  ._1sbkoz6vg {
    margin-right: var(--space-1);
  }
  ._1sbkoz6vm {
    margin-right: var(--space-2);
  }
  ._1sbkoz6vs {
    margin-right: var(--space-3);
  }
  ._1sbkoz6vy {
    margin-right: var(--space-4);
  }
  ._1sbkoz6w4 {
    margin-right: var(--space-5);
  }
  ._1sbkoz6wa {
    margin-right: var(--space-6);
  }
  ._1sbkoz6wg {
    margin-right: var(--space-7);
  }
  ._1sbkoz6wm {
    margin-right: var(--space-px);
  }
  ._1sbkoz6ws {
    margin-right: var(--space-4px);
  }
  ._1sbkoz6wy {
    margin-right: var(--space-8px);
  }
  ._1sbkoz6x4 {
    margin-right: var(--space-12px);
  }
  ._1sbkoz6xa {
    margin-right: var(--space-16px);
  }
  ._1sbkoz6xg {
    margin-right: var(--space-24px);
  }
  ._1sbkoz6xm {
    margin-right: var(--space-32px);
  }
  ._1sbkoz6xs {
    margin-right: var(--space-48px);
  }
  ._1sbkoz6xy {
    margin-right: var(--space-56px);
  }
  ._1sbkoz6y4 {
    margin-right: var(--space-64px);
  }
  ._1sbkoz6ya {
    margin-right: auto;
  }
  ._1sbkoz6yg {
    margin-right: calc(var(--space-px) * -1);
  }
  ._1sbkoz6ym {
    margin-right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6ys {
    margin-right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6yy {
    margin-right: calc(var(--space-3) * -1);
  }
  ._1sbkoz6z4 {
    margin-right: calc(var(--space-4) * -1);
  }
  ._1sbkoz6za {
    margin-right: calc(var(--space-5) * -1);
  }
  ._1sbkoz6zg {
    margin-right: calc(var(--space-6) * -1);
  }
  ._1sbkoz6zm {
    margin-right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6zs {
    margin-top: var(--space-0);
  }
  ._1sbkoz6zy {
    margin-top: var(--space-1);
  }
  ._1sbkoz6104 {
    margin-top: var(--space-2);
  }
  ._1sbkoz610a {
    margin-top: var(--space-3);
  }
  ._1sbkoz610g {
    margin-top: var(--space-4);
  }
  ._1sbkoz610m {
    margin-top: var(--space-5);
  }
  ._1sbkoz610s {
    margin-top: var(--space-6);
  }
  ._1sbkoz610y {
    margin-top: var(--space-7);
  }
  ._1sbkoz6114 {
    margin-top: var(--space-px);
  }
  ._1sbkoz611a {
    margin-top: var(--space-4px);
  }
  ._1sbkoz611g {
    margin-top: var(--space-8px);
  }
  ._1sbkoz611m {
    margin-top: var(--space-12px);
  }
  ._1sbkoz611s {
    margin-top: var(--space-16px);
  }
  ._1sbkoz611y {
    margin-top: var(--space-24px);
  }
  ._1sbkoz6124 {
    margin-top: var(--space-32px);
  }
  ._1sbkoz612a {
    margin-top: var(--space-48px);
  }
  ._1sbkoz612g {
    margin-top: var(--space-56px);
  }
  ._1sbkoz612m {
    margin-top: var(--space-64px);
  }
  ._1sbkoz612s {
    margin-top: auto;
  }
  ._1sbkoz612y {
    margin-top: calc(var(--space-px) * -1);
  }
  ._1sbkoz6134 {
    margin-top: calc(var(--space-1) * -1);
  }
  ._1sbkoz613a {
    margin-top: calc(var(--space-2) * -1);
  }
  ._1sbkoz613g {
    margin-top: calc(var(--space-3) * -1);
  }
  ._1sbkoz613m {
    margin-top: calc(var(--space-4) * -1);
  }
  ._1sbkoz613s {
    margin-top: calc(var(--space-5) * -1);
  }
  ._1sbkoz613y {
    margin-top: calc(var(--space-6) * -1);
  }
  ._1sbkoz6144 {
    margin-top: calc(var(--space-7) * -1);
  }
  ._1sbkoz614a {
    margin: var(--space-0);
  }
  ._1sbkoz614g {
    margin: var(--space-1);
  }
  ._1sbkoz614m {
    margin: var(--space-2);
  }
  ._1sbkoz614s {
    margin: var(--space-3);
  }
  ._1sbkoz614y {
    margin: var(--space-4);
  }
  ._1sbkoz6154 {
    margin: var(--space-5);
  }
  ._1sbkoz615a {
    margin: var(--space-6);
  }
  ._1sbkoz615g {
    margin: var(--space-7);
  }
  ._1sbkoz615m {
    margin: var(--space-px);
  }
  ._1sbkoz615s {
    margin: var(--space-4px);
  }
  ._1sbkoz615y {
    margin: var(--space-8px);
  }
  ._1sbkoz6164 {
    margin: var(--space-12px);
  }
  ._1sbkoz616a {
    margin: var(--space-16px);
  }
  ._1sbkoz616g {
    margin: var(--space-24px);
  }
  ._1sbkoz616m {
    margin: var(--space-32px);
  }
  ._1sbkoz616s {
    margin: var(--space-48px);
  }
  ._1sbkoz616y {
    margin: var(--space-56px);
  }
  ._1sbkoz6174 {
    margin: var(--space-64px);
  }
  ._1sbkoz617a {
    margin: auto;
  }
  ._1sbkoz617g {
    margin: calc(var(--space-px) * -1);
  }
  ._1sbkoz617m {
    margin: calc(var(--space-1) * -1);
  }
  ._1sbkoz617s {
    margin: calc(var(--space-2) * -1);
  }
  ._1sbkoz617y {
    margin: calc(var(--space-3) * -1);
  }
  ._1sbkoz6184 {
    margin: calc(var(--space-4) * -1);
  }
  ._1sbkoz618a {
    margin: calc(var(--space-5) * -1);
  }
  ._1sbkoz618g {
    margin: calc(var(--space-6) * -1);
  }
  ._1sbkoz618m {
    margin: calc(var(--space-7) * -1);
  }
  ._1sbkoz618s {
    padding: var(--space-0);
  }
  ._1sbkoz618y {
    padding: var(--space-1);
  }
  ._1sbkoz6194 {
    padding: var(--space-2);
  }
  ._1sbkoz619a {
    padding: var(--space-3);
  }
  ._1sbkoz619g {
    padding: var(--space-4);
  }
  ._1sbkoz619m {
    padding: var(--space-5);
  }
  ._1sbkoz619s {
    padding: var(--space-6);
  }
  ._1sbkoz619y {
    padding: var(--space-7);
  }
  ._1sbkoz61a4 {
    padding: var(--space-px);
  }
  ._1sbkoz61aa {
    padding: var(--space-4px);
  }
  ._1sbkoz61ag {
    padding: var(--space-8px);
  }
  ._1sbkoz61am {
    padding: var(--space-12px);
  }
  ._1sbkoz61as {
    padding: var(--space-16px);
  }
  ._1sbkoz61ay {
    padding: var(--space-24px);
  }
  ._1sbkoz61b4 {
    padding: var(--space-32px);
  }
  ._1sbkoz61ba {
    padding: var(--space-48px);
  }
  ._1sbkoz61bg {
    padding: var(--space-56px);
  }
  ._1sbkoz61bm {
    padding: var(--space-64px);
  }
  ._1sbkoz61bs {
    padding: auto;
  }
  ._1sbkoz61by {
    padding: calc(var(--space-px) * -1);
  }
  ._1sbkoz61c4 {
    padding: calc(var(--space-1) * -1);
  }
  ._1sbkoz61ca {
    padding: calc(var(--space-2) * -1);
  }
  ._1sbkoz61cg {
    padding: calc(var(--space-3) * -1);
  }
  ._1sbkoz61cm {
    padding: calc(var(--space-4) * -1);
  }
  ._1sbkoz61cs {
    padding: calc(var(--space-5) * -1);
  }
  ._1sbkoz61cy {
    padding: calc(var(--space-6) * -1);
  }
  ._1sbkoz61d4 {
    padding: calc(var(--space-7) * -1);
  }
  ._1sbkoz61da {
    max-height: var(--space-0);
  }
  ._1sbkoz61dg {
    max-height: var(--space-1);
  }
  ._1sbkoz61dm {
    max-height: var(--space-2);
  }
  ._1sbkoz61ds {
    max-height: var(--space-3);
  }
  ._1sbkoz61dy {
    max-height: var(--space-4);
  }
  ._1sbkoz61e4 {
    max-height: var(--space-5);
  }
  ._1sbkoz61ea {
    max-height: var(--space-6);
  }
  ._1sbkoz61eg {
    max-height: var(--space-7);
  }
  ._1sbkoz61em {
    max-height: var(--space-px);
  }
  ._1sbkoz61es {
    max-height: var(--space-4px);
  }
  ._1sbkoz61ey {
    max-height: var(--space-8px);
  }
  ._1sbkoz61f4 {
    max-height: var(--space-12px);
  }
  ._1sbkoz61fa {
    max-height: var(--space-16px);
  }
  ._1sbkoz61fg {
    max-height: var(--space-24px);
  }
  ._1sbkoz61fm {
    max-height: var(--space-32px);
  }
  ._1sbkoz61fs {
    max-height: var(--space-48px);
  }
  ._1sbkoz61fy {
    max-height: var(--space-56px);
  }
  ._1sbkoz61g4 {
    max-height: var(--space-64px);
  }
  ._1sbkoz61ga {
    max-width: var(--space-0);
  }
  ._1sbkoz61gg {
    max-width: var(--space-1);
  }
  ._1sbkoz61gm {
    max-width: var(--space-2);
  }
  ._1sbkoz61gs {
    max-width: var(--space-3);
  }
  ._1sbkoz61gy {
    max-width: var(--space-4);
  }
  ._1sbkoz61h4 {
    max-width: var(--space-5);
  }
  ._1sbkoz61ha {
    max-width: var(--space-6);
  }
  ._1sbkoz61hg {
    max-width: var(--space-7);
  }
  ._1sbkoz61hm {
    max-width: var(--space-px);
  }
  ._1sbkoz61hs {
    max-width: var(--space-4px);
  }
  ._1sbkoz61hy {
    max-width: var(--space-8px);
  }
  ._1sbkoz61i4 {
    max-width: var(--space-12px);
  }
  ._1sbkoz61ia {
    max-width: var(--space-16px);
  }
  ._1sbkoz61ig {
    max-width: var(--space-24px);
  }
  ._1sbkoz61im {
    max-width: var(--space-32px);
  }
  ._1sbkoz61is {
    max-width: var(--space-48px);
  }
  ._1sbkoz61iy {
    max-width: var(--space-56px);
  }
  ._1sbkoz61j4 {
    max-width: var(--space-64px);
  }
  ._1sbkoz61ja {
    max-width: 100%;
  }
  ._1sbkoz61jg {
    max-width: 100%;
  }
  ._1sbkoz61jm {
    max-width: auto;
  }
  ._1sbkoz61js {
    max-width: 576px;
  }
  ._1sbkoz61jy {
    max-width: 852px;
  }
  ._1sbkoz61k4 {
    max-width: 968px;
  }
  ._1sbkoz61ka {
    max-width: 1080px;
  }
  ._1sbkoz61kg {
    max-width: none;
  }
  ._1sbkoz61km {
    min-height: var(--space-0);
  }
  ._1sbkoz61ks {
    min-height: var(--space-1);
  }
  ._1sbkoz61ky {
    min-height: var(--space-2);
  }
  ._1sbkoz61l4 {
    min-height: var(--space-3);
  }
  ._1sbkoz61la {
    min-height: var(--space-4);
  }
  ._1sbkoz61lg {
    min-height: var(--space-5);
  }
  ._1sbkoz61lm {
    min-height: var(--space-6);
  }
  ._1sbkoz61ls {
    min-height: var(--space-7);
  }
  ._1sbkoz61ly {
    min-height: var(--space-px);
  }
  ._1sbkoz61m4 {
    min-height: var(--space-4px);
  }
  ._1sbkoz61ma {
    min-height: var(--space-8px);
  }
  ._1sbkoz61mg {
    min-height: var(--space-12px);
  }
  ._1sbkoz61mm {
    min-height: var(--space-16px);
  }
  ._1sbkoz61ms {
    min-height: var(--space-24px);
  }
  ._1sbkoz61my {
    min-height: var(--space-32px);
  }
  ._1sbkoz61n4 {
    min-height: var(--space-48px);
  }
  ._1sbkoz61na {
    min-height: var(--space-56px);
  }
  ._1sbkoz61ng {
    min-height: var(--space-64px);
  }
  ._1sbkoz61nm {
    min-width: var(--space-0);
  }
  ._1sbkoz61ns {
    min-width: var(--space-1);
  }
  ._1sbkoz61ny {
    min-width: var(--space-2);
  }
  ._1sbkoz61o4 {
    min-width: var(--space-3);
  }
  ._1sbkoz61oa {
    min-width: var(--space-4);
  }
  ._1sbkoz61og {
    min-width: var(--space-5);
  }
  ._1sbkoz61om {
    min-width: var(--space-6);
  }
  ._1sbkoz61os {
    min-width: var(--space-7);
  }
  ._1sbkoz61oy {
    min-width: var(--space-px);
  }
  ._1sbkoz61p4 {
    min-width: var(--space-4px);
  }
  ._1sbkoz61pa {
    min-width: var(--space-8px);
  }
  ._1sbkoz61pg {
    min-width: var(--space-12px);
  }
  ._1sbkoz61pm {
    min-width: var(--space-16px);
  }
  ._1sbkoz61ps {
    min-width: var(--space-24px);
  }
  ._1sbkoz61py {
    min-width: var(--space-32px);
  }
  ._1sbkoz61q4 {
    min-width: var(--space-48px);
  }
  ._1sbkoz61qa {
    min-width: var(--space-56px);
  }
  ._1sbkoz61qg {
    min-width: var(--space-64px);
  }
  ._1sbkoz61qm {
    padding-bottom: var(--space-0);
  }
  ._1sbkoz61qs {
    padding-bottom: var(--space-1);
  }
  ._1sbkoz61qy {
    padding-bottom: var(--space-2);
  }
  ._1sbkoz61r4 {
    padding-bottom: var(--space-3);
  }
  ._1sbkoz61ra {
    padding-bottom: var(--space-4);
  }
  ._1sbkoz61rg {
    padding-bottom: var(--space-5);
  }
  ._1sbkoz61rm {
    padding-bottom: var(--space-6);
  }
  ._1sbkoz61rs {
    padding-bottom: var(--space-7);
  }
  ._1sbkoz61ry {
    padding-bottom: var(--space-px);
  }
  ._1sbkoz61s4 {
    padding-bottom: var(--space-4px);
  }
  ._1sbkoz61sa {
    padding-bottom: var(--space-8px);
  }
  ._1sbkoz61sg {
    padding-bottom: var(--space-12px);
  }
  ._1sbkoz61sm {
    padding-bottom: var(--space-16px);
  }
  ._1sbkoz61ss {
    padding-bottom: var(--space-24px);
  }
  ._1sbkoz61sy {
    padding-bottom: var(--space-32px);
  }
  ._1sbkoz61t4 {
    padding-bottom: var(--space-48px);
  }
  ._1sbkoz61ta {
    padding-bottom: var(--space-56px);
  }
  ._1sbkoz61tg {
    padding-bottom: var(--space-64px);
  }
  ._1sbkoz61tm {
    padding-left: var(--space-0);
  }
  ._1sbkoz61ts {
    padding-left: var(--space-1);
  }
  ._1sbkoz61ty {
    padding-left: var(--space-2);
  }
  ._1sbkoz61u4 {
    padding-left: var(--space-3);
  }
  ._1sbkoz61ua {
    padding-left: var(--space-4);
  }
  ._1sbkoz61ug {
    padding-left: var(--space-5);
  }
  ._1sbkoz61um {
    padding-left: var(--space-6);
  }
  ._1sbkoz61us {
    padding-left: var(--space-7);
  }
  ._1sbkoz61uy {
    padding-left: var(--space-px);
  }
  ._1sbkoz61v4 {
    padding-left: var(--space-4px);
  }
  ._1sbkoz61va {
    padding-left: var(--space-8px);
  }
  ._1sbkoz61vg {
    padding-left: var(--space-12px);
  }
  ._1sbkoz61vm {
    padding-left: var(--space-16px);
  }
  ._1sbkoz61vs {
    padding-left: var(--space-24px);
  }
  ._1sbkoz61vy {
    padding-left: var(--space-32px);
  }
  ._1sbkoz61w4 {
    padding-left: var(--space-48px);
  }
  ._1sbkoz61wa {
    padding-left: var(--space-56px);
  }
  ._1sbkoz61wg {
    padding-left: var(--space-64px);
  }
  ._1sbkoz61wm {
    padding-right: var(--space-0);
  }
  ._1sbkoz61ws {
    padding-right: var(--space-1);
  }
  ._1sbkoz61wy {
    padding-right: var(--space-2);
  }
  ._1sbkoz61x4 {
    padding-right: var(--space-3);
  }
  ._1sbkoz61xa {
    padding-right: var(--space-4);
  }
  ._1sbkoz61xg {
    padding-right: var(--space-5);
  }
  ._1sbkoz61xm {
    padding-right: var(--space-6);
  }
  ._1sbkoz61xs {
    padding-right: var(--space-7);
  }
  ._1sbkoz61xy {
    padding-right: var(--space-px);
  }
  ._1sbkoz61y4 {
    padding-right: var(--space-4px);
  }
  ._1sbkoz61ya {
    padding-right: var(--space-8px);
  }
  ._1sbkoz61yg {
    padding-right: var(--space-12px);
  }
  ._1sbkoz61ym {
    padding-right: var(--space-16px);
  }
  ._1sbkoz61ys {
    padding-right: var(--space-24px);
  }
  ._1sbkoz61yy {
    padding-right: var(--space-32px);
  }
  ._1sbkoz61z4 {
    padding-right: var(--space-48px);
  }
  ._1sbkoz61za {
    padding-right: var(--space-56px);
  }
  ._1sbkoz61zg {
    padding-right: var(--space-64px);
  }
  ._1sbkoz61zm {
    padding-top: var(--space-0);
  }
  ._1sbkoz61zs {
    padding-top: var(--space-1);
  }
  ._1sbkoz61zy {
    padding-top: var(--space-2);
  }
  ._1sbkoz6204 {
    padding-top: var(--space-3);
  }
  ._1sbkoz620a {
    padding-top: var(--space-4);
  }
  ._1sbkoz620g {
    padding-top: var(--space-5);
  }
  ._1sbkoz620m {
    padding-top: var(--space-6);
  }
  ._1sbkoz620s {
    padding-top: var(--space-7);
  }
  ._1sbkoz620y {
    padding-top: var(--space-px);
  }
  ._1sbkoz6214 {
    padding-top: var(--space-4px);
  }
  ._1sbkoz621a {
    padding-top: var(--space-8px);
  }
  ._1sbkoz621g {
    padding-top: var(--space-12px);
  }
  ._1sbkoz621m {
    padding-top: var(--space-16px);
  }
  ._1sbkoz621s {
    padding-top: var(--space-24px);
  }
  ._1sbkoz621y {
    padding-top: var(--space-32px);
  }
  ._1sbkoz6224 {
    padding-top: var(--space-48px);
  }
  ._1sbkoz622a {
    padding-top: var(--space-56px);
  }
  ._1sbkoz622g {
    padding-top: var(--space-64px);
  }
  ._1sbkoz622m {
    font-size: var(--fontSizes-10px);
  }
  ._1sbkoz622s {
    font-size: var(--fontSizes-12px);
  }
  ._1sbkoz622y {
    font-size: var(--fontSizes-16px);
  }
  ._1sbkoz6234 {
    font-size: var(--fontSizes-14px);
  }
  ._1sbkoz623a {
    font-size: var(--fontSizes-20px);
  }
  ._1sbkoz623g {
    font-size: var(--fontSizes-40px);
  }
  ._1sbkoz623m {
    font-size: inherit;
  }
  ._1sbkoz623s {
    right: var(--space-0);
  }
  ._1sbkoz623y {
    right: var(--space-1);
  }
  ._1sbkoz6244 {
    right: var(--space-2);
  }
  ._1sbkoz624a {
    right: var(--space-3);
  }
  ._1sbkoz624g {
    right: var(--space-4);
  }
  ._1sbkoz624m {
    right: var(--space-5);
  }
  ._1sbkoz624s {
    right: var(--space-6);
  }
  ._1sbkoz624y {
    right: var(--space-7);
  }
  ._1sbkoz6254 {
    right: var(--space-px);
  }
  ._1sbkoz625a {
    right: var(--space-4px);
  }
  ._1sbkoz625g {
    right: var(--space-8px);
  }
  ._1sbkoz625m {
    right: var(--space-12px);
  }
  ._1sbkoz625s {
    right: var(--space-16px);
  }
  ._1sbkoz625y {
    right: var(--space-24px);
  }
  ._1sbkoz6264 {
    right: var(--space-32px);
  }
  ._1sbkoz626a {
    right: var(--space-48px);
  }
  ._1sbkoz626g {
    right: var(--space-56px);
  }
  ._1sbkoz626m {
    right: var(--space-64px);
  }
  ._1sbkoz626s {
    right: calc(var(--space-px) * -1);
  }
  ._1sbkoz626y {
    right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6274 {
    right: calc(var(--space-2) * -1);
  }
  ._1sbkoz627a {
    right: calc(var(--space-3) * -1);
  }
  ._1sbkoz627g {
    right: calc(var(--space-4) * -1);
  }
  ._1sbkoz627m {
    right: calc(var(--space-5) * -1);
  }
  ._1sbkoz627s {
    right: calc(var(--space-6) * -1);
  }
  ._1sbkoz627y {
    right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6284 {
    top: var(--space-0);
  }
  ._1sbkoz628a {
    top: var(--space-1);
  }
  ._1sbkoz628g {
    top: var(--space-2);
  }
  ._1sbkoz628m {
    top: var(--space-3);
  }
  ._1sbkoz628s {
    top: var(--space-4);
  }
  ._1sbkoz628y {
    top: var(--space-5);
  }
  ._1sbkoz6294 {
    top: var(--space-6);
  }
  ._1sbkoz629a {
    top: var(--space-7);
  }
  ._1sbkoz629g {
    top: var(--space-px);
  }
  ._1sbkoz629m {
    top: var(--space-4px);
  }
  ._1sbkoz629s {
    top: var(--space-8px);
  }
  ._1sbkoz629y {
    top: var(--space-12px);
  }
  ._1sbkoz62a4 {
    top: var(--space-16px);
  }
  ._1sbkoz62aa {
    top: var(--space-24px);
  }
  ._1sbkoz62ag {
    top: var(--space-32px);
  }
  ._1sbkoz62am {
    top: var(--space-48px);
  }
  ._1sbkoz62as {
    top: var(--space-56px);
  }
  ._1sbkoz62ay {
    top: var(--space-64px);
  }
  ._1sbkoz62b4 {
    top: calc(var(--space-px) * -1);
  }
  ._1sbkoz62ba {
    top: calc(var(--space-1) * -1);
  }
  ._1sbkoz62bg {
    top: calc(var(--space-2) * -1);
  }
  ._1sbkoz62bm {
    top: calc(var(--space-3) * -1);
  }
  ._1sbkoz62bs {
    top: calc(var(--space-4) * -1);
  }
  ._1sbkoz62by {
    top: calc(var(--space-5) * -1);
  }
  ._1sbkoz62c4 {
    top: calc(var(--space-6) * -1);
  }
  ._1sbkoz62ca {
    top: calc(var(--space-7) * -1);
  }
  ._1sbkoz62cg {
    flex: 1 1;
  }
  ._1sbkoz62cm {
    flex: 1 1 auto;
  }
  ._1sbkoz62cs {
    flex: 0 1 auto;
  }
  ._1sbkoz62cy {
    flex: none;
  }
  ._1sbkoz62d4 {
    width: var(--space-0);
  }
  ._1sbkoz62da {
    width: var(--space-1);
  }
  ._1sbkoz62dg {
    width: var(--space-2);
  }
  ._1sbkoz62dm {
    width: var(--space-3);
  }
  ._1sbkoz62ds {
    width: var(--space-4);
  }
  ._1sbkoz62dy {
    width: var(--space-5);
  }
  ._1sbkoz62e4 {
    width: var(--space-6);
  }
  ._1sbkoz62ea {
    width: var(--space-7);
  }
  ._1sbkoz62eg {
    width: var(--space-px);
  }
  ._1sbkoz62em {
    width: var(--space-4px);
  }
  ._1sbkoz62es {
    width: var(--space-8px);
  }
  ._1sbkoz62ey {
    width: var(--space-12px);
  }
  ._1sbkoz62f4 {
    width: var(--space-16px);
  }
  ._1sbkoz62fa {
    width: var(--space-24px);
  }
  ._1sbkoz62fg {
    width: var(--space-32px);
  }
  ._1sbkoz62fm {
    width: var(--space-48px);
  }
  ._1sbkoz62fs {
    width: var(--space-56px);
  }
  ._1sbkoz62fy {
    width: var(--space-64px);
  }
  ._1sbkoz62g4 {
    width: 100%;
  }
  ._1sbkoz62ga {
    width: 100%;
  }
  ._1sbkoz62gg {
    width: auto;
  }
  ._1sbkoz62gm {
    width: 576px;
  }
  ._1sbkoz62gs {
    width: 852px;
  }
  ._1sbkoz62gy {
    width: 968px;
  }
  ._1sbkoz62h4 {
    width: 1080px;
  }
  ._1sbkoz62ha {
    z-index: 0;
  }
  ._1sbkoz62hg {
    z-index: 10;
  }
  ._1sbkoz62hm {
    z-index: 20;
  }
  ._1sbkoz62hs {
    z-index: 30;
  }
  ._1sbkoz62hy {
    z-index: 40;
  }
  ._1sbkoz62i4 {
    z-index: 50;
  }
  ._1sbkoz62ia {
    z-index: 75;
  }
  ._1sbkoz62ig {
    z-index: 99;
  }
  ._1sbkoz62im {
    z-index: 100;
  }
  ._1sbkoz62is {
    z-index: 9;
  }
  ._1sbkoz62iy {
    z-index: 10;
  }
  ._1sbkoz62j4 {
    z-index: 100;
  }
  ._1sbkoz62ja {
    z-index: auto;
  }
  ._1sbkoz62jg {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1sbkoz62jm {
    border-radius: var(--radii-0);
  }
  ._1sbkoz62js {
    border-radius: var(--radii-small);
  }
  ._1sbkoz62jy {
    border-radius: var(--radii-default);
  }
  ._1sbkoz62k4 {
    border-radius: var(--radii-card);
  }
  ._1sbkoz62ka {
    border-radius: var(--radii-circle);
  }
  ._1sbkoz62kg {
    border-top-left-radius: var(--radii-0);
  }
  ._1sbkoz62km {
    border-top-left-radius: var(--radii-small);
  }
  ._1sbkoz62ks {
    border-top-left-radius: var(--radii-default);
  }
  ._1sbkoz62ky {
    border-top-left-radius: var(--radii-card);
  }
  ._1sbkoz62l4 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1sbkoz62la {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1sbkoz62lg {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1sbkoz62lm {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1sbkoz62ls {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1sbkoz62ly {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1sbkoz62m4 {
    border-top-right-radius: var(--radii-0);
  }
  ._1sbkoz62ma {
    border-top-right-radius: var(--radii-small);
  }
  ._1sbkoz62mg {
    border-top-right-radius: var(--radii-default);
  }
  ._1sbkoz62mm {
    border-top-right-radius: var(--radii-card);
  }
  ._1sbkoz62ms {
    border-top-right-radius: var(--radii-circle);
  }
  ._1sbkoz62my {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1sbkoz62n4 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1sbkoz62na {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1sbkoz62ng {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1sbkoz62nm {
    border-bottom-left-radius: var(--radii-circle);
  }
}
@media (min-width: 1200px) {
  ._1sbkoz65 {
    display: block;
  }
  ._1sbkoz6b {
    display: flex;
  }
  ._1sbkoz6h {
    display: grid;
  }
  ._1sbkoz6n {
    display: inline;
  }
  ._1sbkoz6t {
    display: inline-flex;
  }
  ._1sbkoz6z {
    display: inline-block;
  }
  ._1sbkoz615 {
    display: none;
  }
  ._1sbkoz61b {
    display: contents;
  }
  ._1sbkoz61h {
    flex-direction: column;
  }
  ._1sbkoz61n {
    flex-direction: row;
  }
  ._1sbkoz61t {
    flex-direction: column-reverse;
  }
  ._1sbkoz61z {
    align-items: center;
  }
  ._1sbkoz625 {
    align-items: end;
  }
  ._1sbkoz62b {
    align-items: baseLine;
  }
  ._1sbkoz62h {
    align-items: inherit;
  }
  ._1sbkoz62n {
    align-items: flex-start;
  }
  ._1sbkoz62t {
    align-items: center;
  }
  ._1sbkoz62z {
    align-items: start;
  }
  ._1sbkoz635 {
    align-items: flex-end;
  }
  ._1sbkoz63b {
    align-items: stretch;
  }
  ._1sbkoz63h {
    flex-wrap: wrap;
  }
  ._1sbkoz63n {
    flex-wrap: nowrap;
  }
  ._1sbkoz63t {
    overflow: auto;
  }
  ._1sbkoz63z {
    overflow: hidden;
  }
  ._1sbkoz645 {
    overflow: scroll;
  }
  ._1sbkoz64b {
    overflow: unset;
  }
  ._1sbkoz64h {
    overflow-y: auto;
  }
  ._1sbkoz64n {
    overflow-y: hidden;
  }
  ._1sbkoz64t {
    overflow-y: scroll;
  }
  ._1sbkoz64z {
    overflow-x: auto;
  }
  ._1sbkoz655 {
    overflow-x: hidden;
  }
  ._1sbkoz65b {
    overflow-x: scroll;
  }
  ._1sbkoz65h {
    position: absolute;
  }
  ._1sbkoz65n {
    position: fixed;
  }
  ._1sbkoz65t {
    position: relative;
  }
  ._1sbkoz65z {
    position: -webkit-sticky;
    position: sticky;
  }
  ._1sbkoz665 {
    text-align: center;
  }
  ._1sbkoz66b {
    text-align: left;
  }
  ._1sbkoz66h {
    text-align: right;
  }
  ._1sbkoz66n {
    justify-content: flex-start;
  }
  ._1sbkoz66t {
    justify-content: center;
  }
  ._1sbkoz66z {
    justify-content: start;
  }
  ._1sbkoz675 {
    justify-content: flex-end;
  }
  ._1sbkoz67b {
    justify-content: stretch;
  }
  ._1sbkoz67h {
    justify-content: space-around;
  }
  ._1sbkoz67n {
    justify-content: space-between;
  }
  ._1sbkoz67t {
    justify-items: flex-start;
  }
  ._1sbkoz67z {
    justify-items: center;
  }
  ._1sbkoz685 {
    justify-items: start;
  }
  ._1sbkoz68b {
    justify-items: flex-end;
  }
  ._1sbkoz68h {
    justify-items: stretch;
  }
  ._1sbkoz68n {
    justify-self: flex-start;
  }
  ._1sbkoz68t {
    justify-self: center;
  }
  ._1sbkoz68z {
    justify-self: start;
  }
  ._1sbkoz695 {
    justify-self: flex-end;
  }
  ._1sbkoz69b {
    justify-self: stretch;
  }
  ._1sbkoz69h {
    inset: var(--space-0);
  }
  ._1sbkoz69n {
    inset: var(--space-1);
  }
  ._1sbkoz69t {
    inset: var(--space-2);
  }
  ._1sbkoz69z {
    inset: var(--space-3);
  }
  ._1sbkoz6a5 {
    inset: var(--space-4);
  }
  ._1sbkoz6ab {
    inset: var(--space-5);
  }
  ._1sbkoz6ah {
    inset: var(--space-6);
  }
  ._1sbkoz6an {
    inset: var(--space-7);
  }
  ._1sbkoz6at {
    inset: var(--space-px);
  }
  ._1sbkoz6az {
    inset: var(--space-4px);
  }
  ._1sbkoz6b5 {
    inset: var(--space-8px);
  }
  ._1sbkoz6bb {
    inset: var(--space-12px);
  }
  ._1sbkoz6bh {
    inset: var(--space-16px);
  }
  ._1sbkoz6bn {
    inset: var(--space-24px);
  }
  ._1sbkoz6bt {
    inset: var(--space-32px);
  }
  ._1sbkoz6bz {
    inset: var(--space-48px);
  }
  ._1sbkoz6c5 {
    inset: var(--space-56px);
  }
  ._1sbkoz6cb {
    inset: var(--space-64px);
  }
  ._1sbkoz6ch {
    inset: calc(var(--space-px) * -1);
  }
  ._1sbkoz6cn {
    inset: calc(var(--space-1) * -1);
  }
  ._1sbkoz6ct {
    inset: calc(var(--space-2) * -1);
  }
  ._1sbkoz6cz {
    inset: calc(var(--space-3) * -1);
  }
  ._1sbkoz6d5 {
    inset: calc(var(--space-4) * -1);
  }
  ._1sbkoz6db {
    inset: calc(var(--space-5) * -1);
  }
  ._1sbkoz6dh {
    inset: calc(var(--space-6) * -1);
  }
  ._1sbkoz6dn {
    inset: calc(var(--space-7) * -1);
  }
  ._1sbkoz6dt {
    height: var(--space-0);
  }
  ._1sbkoz6dz {
    height: var(--space-1);
  }
  ._1sbkoz6e5 {
    height: var(--space-2);
  }
  ._1sbkoz6eb {
    height: var(--space-3);
  }
  ._1sbkoz6eh {
    height: var(--space-4);
  }
  ._1sbkoz6en {
    height: var(--space-5);
  }
  ._1sbkoz6et {
    height: var(--space-6);
  }
  ._1sbkoz6ez {
    height: var(--space-7);
  }
  ._1sbkoz6f5 {
    height: var(--space-px);
  }
  ._1sbkoz6fb {
    height: var(--space-4px);
  }
  ._1sbkoz6fh {
    height: var(--space-8px);
  }
  ._1sbkoz6fn {
    height: var(--space-12px);
  }
  ._1sbkoz6ft {
    height: var(--space-16px);
  }
  ._1sbkoz6fz {
    height: var(--space-24px);
  }
  ._1sbkoz6g5 {
    height: var(--space-32px);
  }
  ._1sbkoz6gb {
    height: var(--space-48px);
  }
  ._1sbkoz6gh {
    height: var(--space-56px);
  }
  ._1sbkoz6gn {
    height: var(--space-64px);
  }
  ._1sbkoz6gt {
    height: 100%;
  }
  ._1sbkoz6gz {
    height: 100%;
  }
  ._1sbkoz6h5 {
    height: auto;
  }
  ._1sbkoz6hb {
    height: 576px;
  }
  ._1sbkoz6hh {
    height: 852px;
  }
  ._1sbkoz6hn {
    height: 968px;
  }
  ._1sbkoz6ht {
    height: 1080px;
  }
  ._1sbkoz6hz {
    left: var(--space-0);
  }
  ._1sbkoz6i5 {
    left: var(--space-1);
  }
  ._1sbkoz6ib {
    left: var(--space-2);
  }
  ._1sbkoz6ih {
    left: var(--space-3);
  }
  ._1sbkoz6in {
    left: var(--space-4);
  }
  ._1sbkoz6it {
    left: var(--space-5);
  }
  ._1sbkoz6iz {
    left: var(--space-6);
  }
  ._1sbkoz6j5 {
    left: var(--space-7);
  }
  ._1sbkoz6jb {
    left: var(--space-px);
  }
  ._1sbkoz6jh {
    left: var(--space-4px);
  }
  ._1sbkoz6jn {
    left: var(--space-8px);
  }
  ._1sbkoz6jt {
    left: var(--space-12px);
  }
  ._1sbkoz6jz {
    left: var(--space-16px);
  }
  ._1sbkoz6k5 {
    left: var(--space-24px);
  }
  ._1sbkoz6kb {
    left: var(--space-32px);
  }
  ._1sbkoz6kh {
    left: var(--space-48px);
  }
  ._1sbkoz6kn {
    left: var(--space-56px);
  }
  ._1sbkoz6kt {
    left: var(--space-64px);
  }
  ._1sbkoz6kz {
    left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6l5 {
    left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6lb {
    left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6lh {
    left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6ln {
    left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6lt {
    left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6lz {
    left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6m5 {
    left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6mb {
    margin-bottom: var(--space-0);
  }
  ._1sbkoz6mh {
    margin-bottom: var(--space-1);
  }
  ._1sbkoz6mn {
    margin-bottom: var(--space-2);
  }
  ._1sbkoz6mt {
    margin-bottom: var(--space-3);
  }
  ._1sbkoz6mz {
    margin-bottom: var(--space-4);
  }
  ._1sbkoz6n5 {
    margin-bottom: var(--space-5);
  }
  ._1sbkoz6nb {
    margin-bottom: var(--space-6);
  }
  ._1sbkoz6nh {
    margin-bottom: var(--space-7);
  }
  ._1sbkoz6nn {
    margin-bottom: var(--space-px);
  }
  ._1sbkoz6nt {
    margin-bottom: var(--space-4px);
  }
  ._1sbkoz6nz {
    margin-bottom: var(--space-8px);
  }
  ._1sbkoz6o5 {
    margin-bottom: var(--space-12px);
  }
  ._1sbkoz6ob {
    margin-bottom: var(--space-16px);
  }
  ._1sbkoz6oh {
    margin-bottom: var(--space-24px);
  }
  ._1sbkoz6on {
    margin-bottom: var(--space-32px);
  }
  ._1sbkoz6ot {
    margin-bottom: var(--space-48px);
  }
  ._1sbkoz6oz {
    margin-bottom: var(--space-56px);
  }
  ._1sbkoz6p5 {
    margin-bottom: var(--space-64px);
  }
  ._1sbkoz6pb {
    margin-bottom: auto;
  }
  ._1sbkoz6ph {
    margin-bottom: calc(var(--space-px) * -1);
  }
  ._1sbkoz6pn {
    margin-bottom: calc(var(--space-1) * -1);
  }
  ._1sbkoz6pt {
    margin-bottom: calc(var(--space-2) * -1);
  }
  ._1sbkoz6pz {
    margin-bottom: calc(var(--space-3) * -1);
  }
  ._1sbkoz6q5 {
    margin-bottom: calc(var(--space-4) * -1);
  }
  ._1sbkoz6qb {
    margin-bottom: calc(var(--space-5) * -1);
  }
  ._1sbkoz6qh {
    margin-bottom: calc(var(--space-6) * -1);
  }
  ._1sbkoz6qn {
    margin-bottom: calc(var(--space-7) * -1);
  }
  ._1sbkoz6qt {
    margin-left: var(--space-0);
  }
  ._1sbkoz6qz {
    margin-left: var(--space-1);
  }
  ._1sbkoz6r5 {
    margin-left: var(--space-2);
  }
  ._1sbkoz6rb {
    margin-left: var(--space-3);
  }
  ._1sbkoz6rh {
    margin-left: var(--space-4);
  }
  ._1sbkoz6rn {
    margin-left: var(--space-5);
  }
  ._1sbkoz6rt {
    margin-left: var(--space-6);
  }
  ._1sbkoz6rz {
    margin-left: var(--space-7);
  }
  ._1sbkoz6s5 {
    margin-left: var(--space-px);
  }
  ._1sbkoz6sb {
    margin-left: var(--space-4px);
  }
  ._1sbkoz6sh {
    margin-left: var(--space-8px);
  }
  ._1sbkoz6sn {
    margin-left: var(--space-12px);
  }
  ._1sbkoz6st {
    margin-left: var(--space-16px);
  }
  ._1sbkoz6sz {
    margin-left: var(--space-24px);
  }
  ._1sbkoz6t5 {
    margin-left: var(--space-32px);
  }
  ._1sbkoz6tb {
    margin-left: var(--space-48px);
  }
  ._1sbkoz6th {
    margin-left: var(--space-56px);
  }
  ._1sbkoz6tn {
    margin-left: var(--space-64px);
  }
  ._1sbkoz6tt {
    margin-left: auto;
  }
  ._1sbkoz6tz {
    margin-left: calc(var(--space-px) * -1);
  }
  ._1sbkoz6u5 {
    margin-left: calc(var(--space-1) * -1);
  }
  ._1sbkoz6ub {
    margin-left: calc(var(--space-2) * -1);
  }
  ._1sbkoz6uh {
    margin-left: calc(var(--space-3) * -1);
  }
  ._1sbkoz6un {
    margin-left: calc(var(--space-4) * -1);
  }
  ._1sbkoz6ut {
    margin-left: calc(var(--space-5) * -1);
  }
  ._1sbkoz6uz {
    margin-left: calc(var(--space-6) * -1);
  }
  ._1sbkoz6v5 {
    margin-left: calc(var(--space-7) * -1);
  }
  ._1sbkoz6vb {
    margin-right: var(--space-0);
  }
  ._1sbkoz6vh {
    margin-right: var(--space-1);
  }
  ._1sbkoz6vn {
    margin-right: var(--space-2);
  }
  ._1sbkoz6vt {
    margin-right: var(--space-3);
  }
  ._1sbkoz6vz {
    margin-right: var(--space-4);
  }
  ._1sbkoz6w5 {
    margin-right: var(--space-5);
  }
  ._1sbkoz6wb {
    margin-right: var(--space-6);
  }
  ._1sbkoz6wh {
    margin-right: var(--space-7);
  }
  ._1sbkoz6wn {
    margin-right: var(--space-px);
  }
  ._1sbkoz6wt {
    margin-right: var(--space-4px);
  }
  ._1sbkoz6wz {
    margin-right: var(--space-8px);
  }
  ._1sbkoz6x5 {
    margin-right: var(--space-12px);
  }
  ._1sbkoz6xb {
    margin-right: var(--space-16px);
  }
  ._1sbkoz6xh {
    margin-right: var(--space-24px);
  }
  ._1sbkoz6xn {
    margin-right: var(--space-32px);
  }
  ._1sbkoz6xt {
    margin-right: var(--space-48px);
  }
  ._1sbkoz6xz {
    margin-right: var(--space-56px);
  }
  ._1sbkoz6y5 {
    margin-right: var(--space-64px);
  }
  ._1sbkoz6yb {
    margin-right: auto;
  }
  ._1sbkoz6yh {
    margin-right: calc(var(--space-px) * -1);
  }
  ._1sbkoz6yn {
    margin-right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6yt {
    margin-right: calc(var(--space-2) * -1);
  }
  ._1sbkoz6yz {
    margin-right: calc(var(--space-3) * -1);
  }
  ._1sbkoz6z5 {
    margin-right: calc(var(--space-4) * -1);
  }
  ._1sbkoz6zb {
    margin-right: calc(var(--space-5) * -1);
  }
  ._1sbkoz6zh {
    margin-right: calc(var(--space-6) * -1);
  }
  ._1sbkoz6zn {
    margin-right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6zt {
    margin-top: var(--space-0);
  }
  ._1sbkoz6zz {
    margin-top: var(--space-1);
  }
  ._1sbkoz6105 {
    margin-top: var(--space-2);
  }
  ._1sbkoz610b {
    margin-top: var(--space-3);
  }
  ._1sbkoz610h {
    margin-top: var(--space-4);
  }
  ._1sbkoz610n {
    margin-top: var(--space-5);
  }
  ._1sbkoz610t {
    margin-top: var(--space-6);
  }
  ._1sbkoz610z {
    margin-top: var(--space-7);
  }
  ._1sbkoz6115 {
    margin-top: var(--space-px);
  }
  ._1sbkoz611b {
    margin-top: var(--space-4px);
  }
  ._1sbkoz611h {
    margin-top: var(--space-8px);
  }
  ._1sbkoz611n {
    margin-top: var(--space-12px);
  }
  ._1sbkoz611t {
    margin-top: var(--space-16px);
  }
  ._1sbkoz611z {
    margin-top: var(--space-24px);
  }
  ._1sbkoz6125 {
    margin-top: var(--space-32px);
  }
  ._1sbkoz612b {
    margin-top: var(--space-48px);
  }
  ._1sbkoz612h {
    margin-top: var(--space-56px);
  }
  ._1sbkoz612n {
    margin-top: var(--space-64px);
  }
  ._1sbkoz612t {
    margin-top: auto;
  }
  ._1sbkoz612z {
    margin-top: calc(var(--space-px) * -1);
  }
  ._1sbkoz6135 {
    margin-top: calc(var(--space-1) * -1);
  }
  ._1sbkoz613b {
    margin-top: calc(var(--space-2) * -1);
  }
  ._1sbkoz613h {
    margin-top: calc(var(--space-3) * -1);
  }
  ._1sbkoz613n {
    margin-top: calc(var(--space-4) * -1);
  }
  ._1sbkoz613t {
    margin-top: calc(var(--space-5) * -1);
  }
  ._1sbkoz613z {
    margin-top: calc(var(--space-6) * -1);
  }
  ._1sbkoz6145 {
    margin-top: calc(var(--space-7) * -1);
  }
  ._1sbkoz614b {
    margin: var(--space-0);
  }
  ._1sbkoz614h {
    margin: var(--space-1);
  }
  ._1sbkoz614n {
    margin: var(--space-2);
  }
  ._1sbkoz614t {
    margin: var(--space-3);
  }
  ._1sbkoz614z {
    margin: var(--space-4);
  }
  ._1sbkoz6155 {
    margin: var(--space-5);
  }
  ._1sbkoz615b {
    margin: var(--space-6);
  }
  ._1sbkoz615h {
    margin: var(--space-7);
  }
  ._1sbkoz615n {
    margin: var(--space-px);
  }
  ._1sbkoz615t {
    margin: var(--space-4px);
  }
  ._1sbkoz615z {
    margin: var(--space-8px);
  }
  ._1sbkoz6165 {
    margin: var(--space-12px);
  }
  ._1sbkoz616b {
    margin: var(--space-16px);
  }
  ._1sbkoz616h {
    margin: var(--space-24px);
  }
  ._1sbkoz616n {
    margin: var(--space-32px);
  }
  ._1sbkoz616t {
    margin: var(--space-48px);
  }
  ._1sbkoz616z {
    margin: var(--space-56px);
  }
  ._1sbkoz6175 {
    margin: var(--space-64px);
  }
  ._1sbkoz617b {
    margin: auto;
  }
  ._1sbkoz617h {
    margin: calc(var(--space-px) * -1);
  }
  ._1sbkoz617n {
    margin: calc(var(--space-1) * -1);
  }
  ._1sbkoz617t {
    margin: calc(var(--space-2) * -1);
  }
  ._1sbkoz617z {
    margin: calc(var(--space-3) * -1);
  }
  ._1sbkoz6185 {
    margin: calc(var(--space-4) * -1);
  }
  ._1sbkoz618b {
    margin: calc(var(--space-5) * -1);
  }
  ._1sbkoz618h {
    margin: calc(var(--space-6) * -1);
  }
  ._1sbkoz618n {
    margin: calc(var(--space-7) * -1);
  }
  ._1sbkoz618t {
    padding: var(--space-0);
  }
  ._1sbkoz618z {
    padding: var(--space-1);
  }
  ._1sbkoz6195 {
    padding: var(--space-2);
  }
  ._1sbkoz619b {
    padding: var(--space-3);
  }
  ._1sbkoz619h {
    padding: var(--space-4);
  }
  ._1sbkoz619n {
    padding: var(--space-5);
  }
  ._1sbkoz619t {
    padding: var(--space-6);
  }
  ._1sbkoz619z {
    padding: var(--space-7);
  }
  ._1sbkoz61a5 {
    padding: var(--space-px);
  }
  ._1sbkoz61ab {
    padding: var(--space-4px);
  }
  ._1sbkoz61ah {
    padding: var(--space-8px);
  }
  ._1sbkoz61an {
    padding: var(--space-12px);
  }
  ._1sbkoz61at {
    padding: var(--space-16px);
  }
  ._1sbkoz61az {
    padding: var(--space-24px);
  }
  ._1sbkoz61b5 {
    padding: var(--space-32px);
  }
  ._1sbkoz61bb {
    padding: var(--space-48px);
  }
  ._1sbkoz61bh {
    padding: var(--space-56px);
  }
  ._1sbkoz61bn {
    padding: var(--space-64px);
  }
  ._1sbkoz61bt {
    padding: auto;
  }
  ._1sbkoz61bz {
    padding: calc(var(--space-px) * -1);
  }
  ._1sbkoz61c5 {
    padding: calc(var(--space-1) * -1);
  }
  ._1sbkoz61cb {
    padding: calc(var(--space-2) * -1);
  }
  ._1sbkoz61ch {
    padding: calc(var(--space-3) * -1);
  }
  ._1sbkoz61cn {
    padding: calc(var(--space-4) * -1);
  }
  ._1sbkoz61ct {
    padding: calc(var(--space-5) * -1);
  }
  ._1sbkoz61cz {
    padding: calc(var(--space-6) * -1);
  }
  ._1sbkoz61d5 {
    padding: calc(var(--space-7) * -1);
  }
  ._1sbkoz61db {
    max-height: var(--space-0);
  }
  ._1sbkoz61dh {
    max-height: var(--space-1);
  }
  ._1sbkoz61dn {
    max-height: var(--space-2);
  }
  ._1sbkoz61dt {
    max-height: var(--space-3);
  }
  ._1sbkoz61dz {
    max-height: var(--space-4);
  }
  ._1sbkoz61e5 {
    max-height: var(--space-5);
  }
  ._1sbkoz61eb {
    max-height: var(--space-6);
  }
  ._1sbkoz61eh {
    max-height: var(--space-7);
  }
  ._1sbkoz61en {
    max-height: var(--space-px);
  }
  ._1sbkoz61et {
    max-height: var(--space-4px);
  }
  ._1sbkoz61ez {
    max-height: var(--space-8px);
  }
  ._1sbkoz61f5 {
    max-height: var(--space-12px);
  }
  ._1sbkoz61fb {
    max-height: var(--space-16px);
  }
  ._1sbkoz61fh {
    max-height: var(--space-24px);
  }
  ._1sbkoz61fn {
    max-height: var(--space-32px);
  }
  ._1sbkoz61ft {
    max-height: var(--space-48px);
  }
  ._1sbkoz61fz {
    max-height: var(--space-56px);
  }
  ._1sbkoz61g5 {
    max-height: var(--space-64px);
  }
  ._1sbkoz61gb {
    max-width: var(--space-0);
  }
  ._1sbkoz61gh {
    max-width: var(--space-1);
  }
  ._1sbkoz61gn {
    max-width: var(--space-2);
  }
  ._1sbkoz61gt {
    max-width: var(--space-3);
  }
  ._1sbkoz61gz {
    max-width: var(--space-4);
  }
  ._1sbkoz61h5 {
    max-width: var(--space-5);
  }
  ._1sbkoz61hb {
    max-width: var(--space-6);
  }
  ._1sbkoz61hh {
    max-width: var(--space-7);
  }
  ._1sbkoz61hn {
    max-width: var(--space-px);
  }
  ._1sbkoz61ht {
    max-width: var(--space-4px);
  }
  ._1sbkoz61hz {
    max-width: var(--space-8px);
  }
  ._1sbkoz61i5 {
    max-width: var(--space-12px);
  }
  ._1sbkoz61ib {
    max-width: var(--space-16px);
  }
  ._1sbkoz61ih {
    max-width: var(--space-24px);
  }
  ._1sbkoz61in {
    max-width: var(--space-32px);
  }
  ._1sbkoz61it {
    max-width: var(--space-48px);
  }
  ._1sbkoz61iz {
    max-width: var(--space-56px);
  }
  ._1sbkoz61j5 {
    max-width: var(--space-64px);
  }
  ._1sbkoz61jb {
    max-width: 100%;
  }
  ._1sbkoz61jh {
    max-width: 100%;
  }
  ._1sbkoz61jn {
    max-width: auto;
  }
  ._1sbkoz61jt {
    max-width: 576px;
  }
  ._1sbkoz61jz {
    max-width: 852px;
  }
  ._1sbkoz61k5 {
    max-width: 968px;
  }
  ._1sbkoz61kb {
    max-width: 1080px;
  }
  ._1sbkoz61kh {
    max-width: none;
  }
  ._1sbkoz61kn {
    min-height: var(--space-0);
  }
  ._1sbkoz61kt {
    min-height: var(--space-1);
  }
  ._1sbkoz61kz {
    min-height: var(--space-2);
  }
  ._1sbkoz61l5 {
    min-height: var(--space-3);
  }
  ._1sbkoz61lb {
    min-height: var(--space-4);
  }
  ._1sbkoz61lh {
    min-height: var(--space-5);
  }
  ._1sbkoz61ln {
    min-height: var(--space-6);
  }
  ._1sbkoz61lt {
    min-height: var(--space-7);
  }
  ._1sbkoz61lz {
    min-height: var(--space-px);
  }
  ._1sbkoz61m5 {
    min-height: var(--space-4px);
  }
  ._1sbkoz61mb {
    min-height: var(--space-8px);
  }
  ._1sbkoz61mh {
    min-height: var(--space-12px);
  }
  ._1sbkoz61mn {
    min-height: var(--space-16px);
  }
  ._1sbkoz61mt {
    min-height: var(--space-24px);
  }
  ._1sbkoz61mz {
    min-height: var(--space-32px);
  }
  ._1sbkoz61n5 {
    min-height: var(--space-48px);
  }
  ._1sbkoz61nb {
    min-height: var(--space-56px);
  }
  ._1sbkoz61nh {
    min-height: var(--space-64px);
  }
  ._1sbkoz61nn {
    min-width: var(--space-0);
  }
  ._1sbkoz61nt {
    min-width: var(--space-1);
  }
  ._1sbkoz61nz {
    min-width: var(--space-2);
  }
  ._1sbkoz61o5 {
    min-width: var(--space-3);
  }
  ._1sbkoz61ob {
    min-width: var(--space-4);
  }
  ._1sbkoz61oh {
    min-width: var(--space-5);
  }
  ._1sbkoz61on {
    min-width: var(--space-6);
  }
  ._1sbkoz61ot {
    min-width: var(--space-7);
  }
  ._1sbkoz61oz {
    min-width: var(--space-px);
  }
  ._1sbkoz61p5 {
    min-width: var(--space-4px);
  }
  ._1sbkoz61pb {
    min-width: var(--space-8px);
  }
  ._1sbkoz61ph {
    min-width: var(--space-12px);
  }
  ._1sbkoz61pn {
    min-width: var(--space-16px);
  }
  ._1sbkoz61pt {
    min-width: var(--space-24px);
  }
  ._1sbkoz61pz {
    min-width: var(--space-32px);
  }
  ._1sbkoz61q5 {
    min-width: var(--space-48px);
  }
  ._1sbkoz61qb {
    min-width: var(--space-56px);
  }
  ._1sbkoz61qh {
    min-width: var(--space-64px);
  }
  ._1sbkoz61qn {
    padding-bottom: var(--space-0);
  }
  ._1sbkoz61qt {
    padding-bottom: var(--space-1);
  }
  ._1sbkoz61qz {
    padding-bottom: var(--space-2);
  }
  ._1sbkoz61r5 {
    padding-bottom: var(--space-3);
  }
  ._1sbkoz61rb {
    padding-bottom: var(--space-4);
  }
  ._1sbkoz61rh {
    padding-bottom: var(--space-5);
  }
  ._1sbkoz61rn {
    padding-bottom: var(--space-6);
  }
  ._1sbkoz61rt {
    padding-bottom: var(--space-7);
  }
  ._1sbkoz61rz {
    padding-bottom: var(--space-px);
  }
  ._1sbkoz61s5 {
    padding-bottom: var(--space-4px);
  }
  ._1sbkoz61sb {
    padding-bottom: var(--space-8px);
  }
  ._1sbkoz61sh {
    padding-bottom: var(--space-12px);
  }
  ._1sbkoz61sn {
    padding-bottom: var(--space-16px);
  }
  ._1sbkoz61st {
    padding-bottom: var(--space-24px);
  }
  ._1sbkoz61sz {
    padding-bottom: var(--space-32px);
  }
  ._1sbkoz61t5 {
    padding-bottom: var(--space-48px);
  }
  ._1sbkoz61tb {
    padding-bottom: var(--space-56px);
  }
  ._1sbkoz61th {
    padding-bottom: var(--space-64px);
  }
  ._1sbkoz61tn {
    padding-left: var(--space-0);
  }
  ._1sbkoz61tt {
    padding-left: var(--space-1);
  }
  ._1sbkoz61tz {
    padding-left: var(--space-2);
  }
  ._1sbkoz61u5 {
    padding-left: var(--space-3);
  }
  ._1sbkoz61ub {
    padding-left: var(--space-4);
  }
  ._1sbkoz61uh {
    padding-left: var(--space-5);
  }
  ._1sbkoz61un {
    padding-left: var(--space-6);
  }
  ._1sbkoz61ut {
    padding-left: var(--space-7);
  }
  ._1sbkoz61uz {
    padding-left: var(--space-px);
  }
  ._1sbkoz61v5 {
    padding-left: var(--space-4px);
  }
  ._1sbkoz61vb {
    padding-left: var(--space-8px);
  }
  ._1sbkoz61vh {
    padding-left: var(--space-12px);
  }
  ._1sbkoz61vn {
    padding-left: var(--space-16px);
  }
  ._1sbkoz61vt {
    padding-left: var(--space-24px);
  }
  ._1sbkoz61vz {
    padding-left: var(--space-32px);
  }
  ._1sbkoz61w5 {
    padding-left: var(--space-48px);
  }
  ._1sbkoz61wb {
    padding-left: var(--space-56px);
  }
  ._1sbkoz61wh {
    padding-left: var(--space-64px);
  }
  ._1sbkoz61wn {
    padding-right: var(--space-0);
  }
  ._1sbkoz61wt {
    padding-right: var(--space-1);
  }
  ._1sbkoz61wz {
    padding-right: var(--space-2);
  }
  ._1sbkoz61x5 {
    padding-right: var(--space-3);
  }
  ._1sbkoz61xb {
    padding-right: var(--space-4);
  }
  ._1sbkoz61xh {
    padding-right: var(--space-5);
  }
  ._1sbkoz61xn {
    padding-right: var(--space-6);
  }
  ._1sbkoz61xt {
    padding-right: var(--space-7);
  }
  ._1sbkoz61xz {
    padding-right: var(--space-px);
  }
  ._1sbkoz61y5 {
    padding-right: var(--space-4px);
  }
  ._1sbkoz61yb {
    padding-right: var(--space-8px);
  }
  ._1sbkoz61yh {
    padding-right: var(--space-12px);
  }
  ._1sbkoz61yn {
    padding-right: var(--space-16px);
  }
  ._1sbkoz61yt {
    padding-right: var(--space-24px);
  }
  ._1sbkoz61yz {
    padding-right: var(--space-32px);
  }
  ._1sbkoz61z5 {
    padding-right: var(--space-48px);
  }
  ._1sbkoz61zb {
    padding-right: var(--space-56px);
  }
  ._1sbkoz61zh {
    padding-right: var(--space-64px);
  }
  ._1sbkoz61zn {
    padding-top: var(--space-0);
  }
  ._1sbkoz61zt {
    padding-top: var(--space-1);
  }
  ._1sbkoz61zz {
    padding-top: var(--space-2);
  }
  ._1sbkoz6205 {
    padding-top: var(--space-3);
  }
  ._1sbkoz620b {
    padding-top: var(--space-4);
  }
  ._1sbkoz620h {
    padding-top: var(--space-5);
  }
  ._1sbkoz620n {
    padding-top: var(--space-6);
  }
  ._1sbkoz620t {
    padding-top: var(--space-7);
  }
  ._1sbkoz620z {
    padding-top: var(--space-px);
  }
  ._1sbkoz6215 {
    padding-top: var(--space-4px);
  }
  ._1sbkoz621b {
    padding-top: var(--space-8px);
  }
  ._1sbkoz621h {
    padding-top: var(--space-12px);
  }
  ._1sbkoz621n {
    padding-top: var(--space-16px);
  }
  ._1sbkoz621t {
    padding-top: var(--space-24px);
  }
  ._1sbkoz621z {
    padding-top: var(--space-32px);
  }
  ._1sbkoz6225 {
    padding-top: var(--space-48px);
  }
  ._1sbkoz622b {
    padding-top: var(--space-56px);
  }
  ._1sbkoz622h {
    padding-top: var(--space-64px);
  }
  ._1sbkoz622n {
    font-size: var(--fontSizes-10px);
  }
  ._1sbkoz622t {
    font-size: var(--fontSizes-12px);
  }
  ._1sbkoz622z {
    font-size: var(--fontSizes-16px);
  }
  ._1sbkoz6235 {
    font-size: var(--fontSizes-14px);
  }
  ._1sbkoz623b {
    font-size: var(--fontSizes-20px);
  }
  ._1sbkoz623h {
    font-size: var(--fontSizes-40px);
  }
  ._1sbkoz623n {
    font-size: inherit;
  }
  ._1sbkoz623t {
    right: var(--space-0);
  }
  ._1sbkoz623z {
    right: var(--space-1);
  }
  ._1sbkoz6245 {
    right: var(--space-2);
  }
  ._1sbkoz624b {
    right: var(--space-3);
  }
  ._1sbkoz624h {
    right: var(--space-4);
  }
  ._1sbkoz624n {
    right: var(--space-5);
  }
  ._1sbkoz624t {
    right: var(--space-6);
  }
  ._1sbkoz624z {
    right: var(--space-7);
  }
  ._1sbkoz6255 {
    right: var(--space-px);
  }
  ._1sbkoz625b {
    right: var(--space-4px);
  }
  ._1sbkoz625h {
    right: var(--space-8px);
  }
  ._1sbkoz625n {
    right: var(--space-12px);
  }
  ._1sbkoz625t {
    right: var(--space-16px);
  }
  ._1sbkoz625z {
    right: var(--space-24px);
  }
  ._1sbkoz6265 {
    right: var(--space-32px);
  }
  ._1sbkoz626b {
    right: var(--space-48px);
  }
  ._1sbkoz626h {
    right: var(--space-56px);
  }
  ._1sbkoz626n {
    right: var(--space-64px);
  }
  ._1sbkoz626t {
    right: calc(var(--space-px) * -1);
  }
  ._1sbkoz626z {
    right: calc(var(--space-1) * -1);
  }
  ._1sbkoz6275 {
    right: calc(var(--space-2) * -1);
  }
  ._1sbkoz627b {
    right: calc(var(--space-3) * -1);
  }
  ._1sbkoz627h {
    right: calc(var(--space-4) * -1);
  }
  ._1sbkoz627n {
    right: calc(var(--space-5) * -1);
  }
  ._1sbkoz627t {
    right: calc(var(--space-6) * -1);
  }
  ._1sbkoz627z {
    right: calc(var(--space-7) * -1);
  }
  ._1sbkoz6285 {
    top: var(--space-0);
  }
  ._1sbkoz628b {
    top: var(--space-1);
  }
  ._1sbkoz628h {
    top: var(--space-2);
  }
  ._1sbkoz628n {
    top: var(--space-3);
  }
  ._1sbkoz628t {
    top: var(--space-4);
  }
  ._1sbkoz628z {
    top: var(--space-5);
  }
  ._1sbkoz6295 {
    top: var(--space-6);
  }
  ._1sbkoz629b {
    top: var(--space-7);
  }
  ._1sbkoz629h {
    top: var(--space-px);
  }
  ._1sbkoz629n {
    top: var(--space-4px);
  }
  ._1sbkoz629t {
    top: var(--space-8px);
  }
  ._1sbkoz629z {
    top: var(--space-12px);
  }
  ._1sbkoz62a5 {
    top: var(--space-16px);
  }
  ._1sbkoz62ab {
    top: var(--space-24px);
  }
  ._1sbkoz62ah {
    top: var(--space-32px);
  }
  ._1sbkoz62an {
    top: var(--space-48px);
  }
  ._1sbkoz62at {
    top: var(--space-56px);
  }
  ._1sbkoz62az {
    top: var(--space-64px);
  }
  ._1sbkoz62b5 {
    top: calc(var(--space-px) * -1);
  }
  ._1sbkoz62bb {
    top: calc(var(--space-1) * -1);
  }
  ._1sbkoz62bh {
    top: calc(var(--space-2) * -1);
  }
  ._1sbkoz62bn {
    top: calc(var(--space-3) * -1);
  }
  ._1sbkoz62bt {
    top: calc(var(--space-4) * -1);
  }
  ._1sbkoz62bz {
    top: calc(var(--space-5) * -1);
  }
  ._1sbkoz62c5 {
    top: calc(var(--space-6) * -1);
  }
  ._1sbkoz62cb {
    top: calc(var(--space-7) * -1);
  }
  ._1sbkoz62ch {
    flex: 1 1;
  }
  ._1sbkoz62cn {
    flex: 1 1 auto;
  }
  ._1sbkoz62ct {
    flex: 0 1 auto;
  }
  ._1sbkoz62cz {
    flex: none;
  }
  ._1sbkoz62d5 {
    width: var(--space-0);
  }
  ._1sbkoz62db {
    width: var(--space-1);
  }
  ._1sbkoz62dh {
    width: var(--space-2);
  }
  ._1sbkoz62dn {
    width: var(--space-3);
  }
  ._1sbkoz62dt {
    width: var(--space-4);
  }
  ._1sbkoz62dz {
    width: var(--space-5);
  }
  ._1sbkoz62e5 {
    width: var(--space-6);
  }
  ._1sbkoz62eb {
    width: var(--space-7);
  }
  ._1sbkoz62eh {
    width: var(--space-px);
  }
  ._1sbkoz62en {
    width: var(--space-4px);
  }
  ._1sbkoz62et {
    width: var(--space-8px);
  }
  ._1sbkoz62ez {
    width: var(--space-12px);
  }
  ._1sbkoz62f5 {
    width: var(--space-16px);
  }
  ._1sbkoz62fb {
    width: var(--space-24px);
  }
  ._1sbkoz62fh {
    width: var(--space-32px);
  }
  ._1sbkoz62fn {
    width: var(--space-48px);
  }
  ._1sbkoz62ft {
    width: var(--space-56px);
  }
  ._1sbkoz62fz {
    width: var(--space-64px);
  }
  ._1sbkoz62g5 {
    width: 100%;
  }
  ._1sbkoz62gb {
    width: 100%;
  }
  ._1sbkoz62gh {
    width: auto;
  }
  ._1sbkoz62gn {
    width: 576px;
  }
  ._1sbkoz62gt {
    width: 852px;
  }
  ._1sbkoz62gz {
    width: 968px;
  }
  ._1sbkoz62h5 {
    width: 1080px;
  }
  ._1sbkoz62hb {
    z-index: 0;
  }
  ._1sbkoz62hh {
    z-index: 10;
  }
  ._1sbkoz62hn {
    z-index: 20;
  }
  ._1sbkoz62ht {
    z-index: 30;
  }
  ._1sbkoz62hz {
    z-index: 40;
  }
  ._1sbkoz62i5 {
    z-index: 50;
  }
  ._1sbkoz62ib {
    z-index: 75;
  }
  ._1sbkoz62ih {
    z-index: 99;
  }
  ._1sbkoz62in {
    z-index: 100;
  }
  ._1sbkoz62it {
    z-index: 9;
  }
  ._1sbkoz62iz {
    z-index: 10;
  }
  ._1sbkoz62j5 {
    z-index: 100;
  }
  ._1sbkoz62jb {
    z-index: auto;
  }
  ._1sbkoz62jh {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1sbkoz62jn {
    border-radius: var(--radii-0);
  }
  ._1sbkoz62jt {
    border-radius: var(--radii-small);
  }
  ._1sbkoz62jz {
    border-radius: var(--radii-default);
  }
  ._1sbkoz62k5 {
    border-radius: var(--radii-card);
  }
  ._1sbkoz62kb {
    border-radius: var(--radii-circle);
  }
  ._1sbkoz62kh {
    border-top-left-radius: var(--radii-0);
  }
  ._1sbkoz62kn {
    border-top-left-radius: var(--radii-small);
  }
  ._1sbkoz62kt {
    border-top-left-radius: var(--radii-default);
  }
  ._1sbkoz62kz {
    border-top-left-radius: var(--radii-card);
  }
  ._1sbkoz62l5 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1sbkoz62lb {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1sbkoz62lh {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1sbkoz62ln {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1sbkoz62lt {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1sbkoz62lz {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1sbkoz62m5 {
    border-top-right-radius: var(--radii-0);
  }
  ._1sbkoz62mb {
    border-top-right-radius: var(--radii-small);
  }
  ._1sbkoz62mh {
    border-top-right-radius: var(--radii-default);
  }
  ._1sbkoz62mn {
    border-top-right-radius: var(--radii-card);
  }
  ._1sbkoz62mt {
    border-top-right-radius: var(--radii-circle);
  }
  ._1sbkoz62mz {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1sbkoz62n5 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1sbkoz62nb {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1sbkoz62nh {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1sbkoz62nn {
    border-bottom-left-radius: var(--radii-circle);
  }
}
.theme-dark:root {
    --tv-color-platform-background: #212121;
    --tv-color-pane-background: #212121;
    --tv-color-pane-background-secondary: #3a3b3c;
    --tv-color-toolbar-button-background-hover: #3a3b3c;
    --tv-color-toolbar-button-background-secondary-hover: #acacac;
    --tv-color-toolbar-button-background-expanded: #3a3b3c;
    --tv-color-toolbar-button-text: #acacac;
    --tv-color-toolbar-button-text-hover: rgb(248, 248, 248);
    --tv-color-toolbar-button-text-active: rgb(255, 255, 255);
    --tv-color-toolbar-button-text-active-hover: #acacac;
    --tv-color-item-active-text: rgb(255, 255, 255);
    --tv-color-toolbar-toggle-button-background-active: #acacac;
    --tv-color-toolbar-toggle-button-background-active-hover: #acacac;
}

.bg-green-900 {
    background-color: #625900 !important;
}

.bg-black1 {
    background-color: unset !important;
}

.bg-blackgrond {
    background-color: #ccc !important;
}

.manage_page {
    width: 90%;
    margin: 100px auto;
}

.dflex {
    display: flex;
}

.div_navbar {
    justify-content: center;
}

.h3_page {
    background-color: transparent;
    height: 45px;
    letter-spacing: 0px;
    mix-blend-mode: normal;
    text-align: center;
    top: 0px;
    white-space: nowrap;
    margin-left: 50px;
    cursor: pointer;
}

.h6_font {
    color: var(--colors-primary);
    font-family: 'Inter', Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}

.top_tab {
    margin-top: 150px;
}

.tab_active {
    color: #ffc600;
    font-family: 'Inter', Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    border-bottom: 4px solid;
}

.test {
    background-color: unset !important;
    background-image: url(/img/Group.png);
    background-repeat: round;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
}

.imgbutton {
    background-color: unset !important;
    background-image: url(/img/Button40.png);
    background-repeat: round;
    border-radius: 0px !important;
    align-items: center;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    height: 32px;
    padding: 13px 20px;
    height: 40px;
    font-family: var(--font-family-karla);
}

.imgbutton:hover {
    text-decoration: none !important;
}

.tranform {
    -webkit-transform: translate3d(-10px, 70px, 0px) !important;
            transform: translate3d(-10px, 70px, 0px) !important;
    -webkit-transform: translate(-10px, 70px) !important;
            transform: translate(-10px, 70px) !important;
}

.test1 {
    background-color: unset !important;
    background-image: url(/img/Group11.png);
    background-repeat: round;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
}

.bgr-page {
    background-color: unset !important;
    background-image: url(/img/Swap.svg);
    background-repeat: round;
    padding: 50px !important;
    max-width: 1569px;
    margin: auto;
}

.corner-c5ghsZ {
    align-items: center;
    background-color: transparent;
    border: 1px none;
    display: flex;
    height: auto;
    left: -2px;
    /* position: absolute; */
    top: -3px;
    width: 209px;
}

.button-40-oOy9LC {
    align-items: flex-start;
    background-color: transparent;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
    padding: 3px;
    /* position: relative; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.button-system-rGgXPE {
    align-items: center;
    background-color: #463701;
    border-bottom-style: none;
    border-left-style: none;
    border-radius: 1px 2px 1px 1px;
    border-right-style: none;
    border-style: none;
    border-top-style: none;
    display: flex;
    gap: 8px;
    height: auto;
    justify-content: center;
    mix-blend-mode: normal;
    padding: 9px 14px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.launch-app-c5ghsZ {
    background-color: transparent;
    color: var(--alto);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxs);
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.28px;
    line-height: normal;
    mix-blend-mode: normal;
    position: relative;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.subtract-yRvGai {
    background-color: transparent;
    height: 40px;
    margin-left: -1px;
    min-width: 6px;
    position: relative;
}

.frame-53-yRvGai {
    align-items: flex-start;
    background-color: transparent;
    border: 1px none;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 40px;
    justify-content: space-between;
    position: relative;
}

.subtract-aL6Qvn {
    background-color: transparent;
    height: 40.00244140625px;
    margin-right: -1px;
    min-width: 17px;
    position: relative;
}

.frame-53-yRvGai {
    align-items: flex-start;
    background-color: transparent;
    border: 1px none;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 40px;
    justify-content: space-between;
    position: relative;
}

.rectangle-18-jRKCgG {
    align-self: stretch;
    background-color: #ffc600;
    border: 1px none;
    height: 2px;
    min-width: 160.00003051757812px;
    mix-blend-mode: normal;
    position: relative;
}

.rectangle-19-jRKCgG {
    align-self: stretch;
    background-color: #ffc600;
    border: 1px none;
    height: 2px;
    min-width: 160.00003051757812px;
    mix-blend-mode: normal;
    position: relative;
}

.frame-65-86hVJD {
    align-items: center;
    background-color: transparent;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 64px;
    height: auto;
    left: 27px;
    top: 493px;
    margin-top: 100px;
}

.text-block-oOy9LC {
    align-items: center;
    background-color: transparent;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: auto;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.title-JgtX10 {
    background-color: transparent;
    color: #dddddd;
    font-family: 'Inter', Helvetica;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: -1.2px;
    line-height: 72px;
    margin-top: -1px;
    mix-blend-mode: normal;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 1160px;
}

.or-learn-all-about-how-to-build-on-onix-JgtX10 {
    background-color: transparent;
    color: transparent;
    font-family: 'Inter', Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.48px;
    line-height: 36px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 816px;
}

.span0-4xxzQG {
    color: #808080;
    font-style: normal;
}

.span1-4xxzQG {
    color: #ffc600;
    font-style: normal;
}

.frame_footer {
    align-items: center;
    align-self: stretch;
    background-color: transparent;
    border: 1px none;
    display: flex;
    height: auto;
    justify-content: space-between;
    position: relative;
    width: 90%;
    max-width: 1569px;
    margin: 50px auto;
}

.group-iEyiFd {
    background-color: transparent;
    min-width: 273px;
    position: relative;
}

.menu-items-iEyiFd {
    align-items: flex-start;
    background-color: transparent;
    border: 1px none;
    display: flex;
    gap: 48px;
    height: auto;
    position: relative;
}

.community {
    background-color: transparent;
    letter-spacing: -0.32px;
    line-height: normal;
    margin-top: -1px;
    mix-blend-mode: normal;
    position: relative;
    text-align: right;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
}

.turbo-progress-bar {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 3px;
    background: #0076ff;
    z-index: 9999;
    transition: width 300ms ease-out, opacity 150ms 150ms ease-in;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.border-2 {
    border: solid !important;
    border-color: #34e3a2 !important;
}

.home-style {
    /* background-image: url('./images/home/hero\ images.svg');
    background-size: cover; */
    width: 100%;
    margin: auto;
    color: white;
    max-width: 1560px;
}

.intro-swap {
    /* width: fit-content; */
    font-family: var(--font-family-karla);
    font-weight: 800;
    color: var(--gray);
    font-size: var(--font-size-m);
}

.h3-home {
    color: var(--colors-text);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxl);
    font-weight: 900;
    font-style: normal;
    align-self: stretch;
    margin-top: -1px;
    text-align: center;
    -webkit-letter-spacing: -1.2px;
    -moz-letter-spacing: -1.2px;
    -ms-letter-spacing: -1.2px;
    letter-spacing: -1.2px;
    line-height: 72px;
}

.comunioner {
    font-family: var(--font-family-inter);
    font-weight: 900;
    color: var(--colors-text);
    font-size: var(--font-size-xxl);
    gap: 32px;
}

.line-height-home {
    line-height: 65px;
}

.trade-earn {
    color: var(--gray);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-l);
    font-weight: 200;
    font-style: normal;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    line-height: 30px;
    padding-top: 10px;
}

.div_millions {
    font-family: var(--font-family-inter) !important;
    color: var(--colors-text);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxl);
    font-weight: 900;
    font-style: normal;
    align-self: stretch;
    margin-top: -1px;
    text-align: center;
    line-height: 40px;
}

.txt-banner {
    align-self: center;
    /* position: absolute; */
    width: 60%;
    max-width: 900px;
    grid-gap: 32px;
    gap: 32px;
    display: grid;
}

.txt-banner-tt {
    align-self: center;
    margin-left: 100px;
    position: absolute;
    width: 37%;
    max-width: 900px;
    grid-gap: 20px;
    gap: 20px;
    display: grid;
}

.txt-banner-trade {
    align-self: center;
    right: 0;
    /* position: absolute; */
    width: 50%;
    max-width: 900px;
    grid-gap: 32px;
    gap: 32px;
    display: grid;
}

.txtmillionuser {
    position: absolute;
    width: 80%;
    height: 205px;
    bottom: 19px;
    left: 10%;
    background-color: var(--alto);
    border: 1px none;
    box-shadow: 0px 20px 32px #00000040;
}

.txtmillionother {
    position: absolute;
    width: 80%;
    height: 205px;
    bottom: 9px;
    left: 10%;
    background-color: var(--alto);
    border: 1px none;
    box-shadow: 0px 20px 32px #00000040;
}

.txtmillionofark {
    position: absolute;
    width: 80%;
    height: 205px;
    bottom: 14px;
    left: 10%;
    background-color: var(--alto);
    border: 1px none;
    box-shadow: 0px 20px 32px #00000040;
}

.use-by {
    display: flex;
    margin-top: -80px;
    flex-direction: column;
    align-items: center;
    gap: 150px;
    padding: 0px 80px;
    border: 1px none;
    padding: 0px 50px 80px;
    position: relative;
}


/* ------------------------------------------------------------------------------------------------ */

.div-millions-user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    border: 1px none;
    gap: 20px;
}

.div-millions-colum {
    display: grid;
    align-items: flex-start;
    grid-gap: 40px;
    gap: 40px;
    border: 1px none;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
}

.bEQugO {
    align-self: stretch;
    height: 120px;
    border: 1px none;
}

.image-million {
    position: relative;
    width: 100%;
    height: 712px;
    top: -170px;
}

.eAGDqQ {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 177px;
    right: 8px;
    background-color: var(--blue-marguerite);
    border-radius: 23px;
    border: 1px none;
}

.color_trade {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 177px;
    right: 8px;
    background-color: var(--porsche);
    border-radius: 23px;
    border: 1px none;
}

.color_staked {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 177px;
    right: 8px;
    background-color: var(--inchworm);
    border-radius: 23px;
    border: 1px none;
}

.ldtVzV {
    position: absolute;
    width: 420px;
    height: 542px;
    top: 173px;
    left: 0;
}

.gdNydd {
    position: absolute;
    width: 524px;
    height: 390px;
    top: 173px;
    left: -1px;
}

.boWQZJ {
    position: absolute;
    width: 86%;
    height: 160px;
    top: 173px;
    background-color: var(--shark);
    border-top-left-radius: 37px;
}

.gIUHqg {
    position: absolute;
    height: 340px;
    top: 0;
    left: 10%;
    display: flex;
    align-items: flex-start;
    min-width: 340px;
    border: 1px none;
}

.eojyCN {
    display: flex;
    align-items: flex-start;
    min-width: 340px;
    border-radius: 64px;
    border: 1px none;
}

.jKblIE {
    width: 340px;
    height: 340px;
    position: relative;
}

.ctwTPq {
    position: absolute;
    width: 340px;
    height: 340px;
    top: 0;
    left: 0;
    border: 1px none;
    background-size: cover;
    background-position: 50% 50%;
    background-image: url(http://localhost:1234/img/crystal-shadow-1@2x.png);
}

.imhWGR {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 20px;
    left: 20px;
    object-fit: cover;
}

.kAIfLV {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 0px 40px;
    align-self: stretch;
    border: 1px none;
    z-index: 9;
}

.hkipsh {
    position: absolute;
    width: 300px;
    right: 0px;
    object-fit: cover;
    z-index: 10;
}

.footer-img {
    position: absolute;
    width: 300px;
    left: 55px;
    object-fit: cover;
    z-index: 10;
    top: 150px;
}

.bkuLtm {
    background-color: unset !important;
    color: var(--colors-text) !important;
}

.bZXvp {
    position: absolute;
    width: 100px;
    left: 0px;
    z-index: 10;
    bottom: 200px;
}

.hpiEQ {
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl);
    font-weight: 900;
    font-style: normal;
    margin-top: -1px;
    letter-spacing: 0px;
    line-height: 38.4px;
}

.hWpUlW {
    font-family: var(--font-family-inter);
    font-weight: 900;
    color: var(--blue-marguerite);
    font-size: var(--font-size-xl);
}

.gltMei {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
    width: 340px;
    letter-spacing: 0;
    line-height: 27px;
}

.dfbVyw {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 70px 40px 40px;
    align-self: stretch;
    border-radius: 0px 0px 24px 24px;
    overflow: hidden;
    border: 1px none;
    z-index: 9;
}

.gfrNDX {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -1px;
    font-family: var(--font-family-karla);
    font-weight: 500;
    color: var(--blue-marguerite);
    font-size: var(--font-size-m);
    letter-spacing: -0.36px;
    line-height: 27px;
    white-space: nowrap;
}

.img-qr {
    position: absolute;
    top: 25px;
}

.cLuDOM {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding: 8px 12px;
    background-color: var(--inchworm-2);
    border-radius: 119px;
    border: 1px none;
}

.cHSmpO {
    min-width: 26px;
    height: 11.0001220703125px;
}

.bquLPr {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: flex-start;
    gap: 8px;
    padding: 3px;
    position: relative;
    border: 1px none;
}

.btn-connect {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 20px;
    position: relative;
    border-radius: 1px 2px 1px 1px;
    border-style: none;
    background-color: var(--deep-bronze);
}

.txt-connect {
    color: var(--alto);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-xs);
    font-weight: 800;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    letter-spacing: -0.28px;
    cursor: pointer;
}

.lhhHgp {
    display: flex;
    width: 127px;
    align-items: center;
    position: absolute;
    top: -3px;
    left: -2px;
    border: 1px none;
}

.imgLeft-btn-connect {
    min-width: 6px;
    height: 42px;
    margin-left: -1px;
}

.border-connect {
    display: flex;
    flex-direction: column;
    height: 42px;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1;
    border: 1px none;
}

.button-css {
    background-color: unset !important;
    /* background-image: url(/img/Button40.png); */
    background-repeat: round;
    background-size: cover;
}

.button-css-connect {
    border-radius: unset !important;
    border: 2px solid var(--supernova) !important;
    border-top-right-radius: 10px !important;
    box-shadow: unset !important;
}

.border-btn-connect {
    align-self: stretch;
    min-width: 150px;
    height: 2px;
    background-color: var(--supernova);
    border: 1px none;
}

.border-btn-connect-trade {
    align-self: stretch;
    min-width: 90px;
    height: 2px;
    background-color: var(--supernova);
    border: 1px none;
}

.img-btn-coonect {
    min-width: 17px;
    height: 42px;
    margin-right: -1px;
}

.imwMnF {
    color: var(--supernova);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    letter-spacing: 0;
    line-height: 42px;
    white-space: nowrap;
}

.color-trade {
    font-family: var(--font-family-inter);
    font-weight: 900;
    color: var(--porsche);
    font-size: var(--font-size-xxl);
}

.color-earn {
    font-family: var(--font-family-inter);
    font-weight: 900;
    color: var(--blue-marguerite);
    font-size: var(--font-size-xxl);
}

.statistical {
    display: flex;
    position: relative;
    margin-top: 143px;
    margin-right: 2px;
    flex-direction: column;
    width: 95%;
    margin: auto;
    height: 192px;
    align-items: center;
    border: 1px none;
    margin-bottom: 100px;
    margin-top: 200px;
}

.statistical-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 55px;
    padding: 40px 135px;
    position: relative;
    align-self: stretch;
    background-color: var(--shark);
    border-radius: 16px;
    border: 1px none;
}

.statistical-div-info {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: flex-start;
    border: 1px none;
}

.statistical-div-info-2 {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: flex-start;
    border: 1px none;
}

.txt-title {
    color: var(--alto);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl);
    font-weight: 900;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -1px;
    letter-spacing: -0.64px;
    line-height: 38.4px;
    white-space: nowrap;
}

.txt-span {
    color: var(--shuttle-gray);
    font-family: var(--font-family-karla);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    letter-spacing: -0.36px;
    line-height: 27px;
    white-space: nowrap;
}

.txt-title2 {
    color: var(--alto);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl);
    font-weight: 900;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -1px;
    letter-spacing: -0.64px;
    line-height: 38.4px;
    white-space: nowrap;
}

.txt-span2 {
    color: var(--shuttle-gray);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    letter-spacing: -0.36px;
    line-height: 27px;
    white-space: nowrap;
}

.img-statistical {
    position: absolute;
    width: 400px;
    left: -140px;
    object-fit: cover;
    margin-top: -40px;
}

.margin-top {
    margin-top: 50px;
}

.mt-screen {
    margin: 0 50px;
}

.size-hearder {
    min-height: 780px;
}

.gXBlaY {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -1px;
    font-family: var(--font-family-karla);
    font-weight: 800;
    color: var(--shuttle-gray);
    font-size: var(--font-size-m);
    letter-spacing: 0.9px;
}

.w-6 {
    width: 35px;
    height: 35px;
    margin-left: 33px;
    cursor: pointer;
}

.group-iEyiFds {}

.group-9-7oP3YL {
    text-align-last: auto;
}

.icon_footer {
    justify-content: flex-end;
    display: flex;
}

.mt-5 {
    margin-top: 20px;
}

.mb-5s {}

.involved {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    border: 1px none;
    width: 90%;
    margin: 100px auto;
    height: 500px;
}

.involved-div {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    border: 1px none;
    margin-top: 290px;
}

.involved-txt {
    color: var(--colors-text);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxl);
    font-weight: 900;
    font-style: normal;
    margin-top: -1px;
    text-align: center;
    line-height: 120px;
    white-space: nowrap;
}

.involved-involved {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    border: 1px none;
    width: 90%;
    margin: 100px auto;
    height: 500px;
}

.involved-txt-involved {
    color: var(--colors-text);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xl);
    font-weight: 900;
    font-style: normal;
    margin-top: -1px;
    text-align: center;
    line-height: 60px;
    white-space: nowrap;
}

.involved-div-span {
    font-family: var(--font-family-karla);
    font-weight: 400;
    color: transparent;
    font-size: var(--font-size-l);
    text-align: center;
    letter-spacing: 0px;
    white-space: nowrap;
}

.involved-div-involved {
    font-family: var(--font-family-karla);
    font-weight: 400;
    color: transparent;
    font-size: var(--font-size-m);
    text-align: center;
    white-space: nowrap;
}

.color-span {
    color: var(--supernova);
}

.background-involved-swap {
    /* background-color: unset !important;
    background-repeat: round; */
    height: 665px;
    margin-top: -230px;
    margin-bottom: -160px;
    position: -webkit-sticky;
    position: sticky;
    z-index: -1;
}

.involved-div-swap {
    display: flex;
    flex-direction: column;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    align-items: center;
    border: 1px none;
    margin-top: 270px;
}

.involved-btn {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: flex-start;
    gap: 8px;
    padding: 3px;
    position: relative;
    border: 1px none;
}

.desktop-icon {
    display: flex;
}

.mobile-icon {
    display: block;
}

.div-join {
    align-self: stretch;
    height: 700px;
    overflow: hidden;
    border: 1px none;
}

.div-join-txt {
    position: relative;
    height: 1214px;
    top: -257px;
    left: 353px;
}

.background-involved {
    background-color: unset !important;
    background-repeat: round;
    background-size: cover;
    height: 800px;
    margin-top: -270px;
    margin-bottom: -130px;
    position: -webkit-sticky;
    position: sticky;
    z-index: -1;
}

.width-screen {
    width: 40%;
}

.justifyContent {
    justify-content: left;
}

.txtAlign {
    text-align: left;
}

.style-img-home {
    width: 70%;
    float: right;
}

.mt-Used {
    margin-top: 0px;
}

.background-page {
    min-height: 1200px;
    background-position-y: 150px;
    background-repeat: no-repeat;
    background-size: cover !important;
}

.padding-min-swap {
    padding: 40px 35px;
}


/* ------------------------------------------------------------------------------------------ */

@media screen and (min-width: 300px) and (max-width: 600px) {
    .txtmillionuser {
        bottom: 18px;
    }
    .style-img-home {
        width: 100%;
        float: unset;
        margin-top: -30px;
    }
    .mt-Used {
        margin-top: 70px !important;
    }
    .txtAlign {
        text-align: center;
    }
    .justifyContent {
        justify-content: center;
    }
    .mt-screen {
        display: block;
        margin: 0;
    }
    .gXBlaY {
        line-height: 15px;
        font-size: var(--font-size-xs);
    }
    .h3-home {
        font-size: var(--font-size-l);
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0px;
    }
    .background-involved {
        visibility: hidden;
        display: none;
    }
    .desktop-icon {
        display: block;
    }
    .mobile-icon {
        display: flex;
    }
    .involved-txt {
        font-size: var(--font-size-m);
    }
    .involved-div-span {
        font-size: var(--font-size-xs);
    }
    .size-hearder {
        min-height: 350px;
    }
    .intro-swap {
        font-weight: 800;
        font-size: 12px;
        letter-spacing: 0.9px;
    }
    .comunioner {
        font-size: var(--font-size-l);
        line-height: 30px;
    }
    .trade-earn {
        font-size: 12px;
        line-height: 15px;
    }
    .txt-banner {
        margin-left: 5%;
        width: 90%;
        gap: 15px;
        text-align: center;
    }
    .txt-banner-tt {
        margin-left: 5%;
        width: 90%;
        gap: 10px;
        text-align: center;
        margin-top: 350px;
    }
    .txt-banner-trade {
        margin-left: 5%;
        width: 60%;
    }
    .use-by {
        margin-top: 0px;
        gap: 100px;
        border: 1px none;
        padding: 0px 30px 0px;
        height: unset;
    }
    .gdNydd {
        height: 350px;
    }
    .div_millions {
        font-size: var(--font-size-s);
        font-weight: 900;
        margin-top: 30px;
        letter-spacing: 1px;
        line-height: 30px;
        margin-bottom: -50px;
    }
    .div-millions-colum {
        grid-template-columns: repeat(1, 1fr);
    }
    .image-million {
        width: 100%;
        margin: auto;
    }
    .dfbVyw {
        width: 100%;
        padding: 55px 25px 40px;
        margin: auto;
    }
    .color-trade {
        font-size: var(--font-size-l);
    }
    .txt-connect {
        font-size: 12px;
    }
    .border-btn-connect {
        min-width: 110px;
    }
    .border-connect {
        height: 42px;
    }
    .imwMnF {
        font-size: 12px;
    }
    .margin-top {
        margin-top: 110px;
    }
    .statistical {
        display: none;
    }
    .color-earn {
        font-size: var(--font-size-l);
    }
    .imhWGR {
        /* position: absolute; */
        width: 250px;
        height: 250px;
        top: 66px;
        left: 50px;
        object-fit: cover;
    }
    .hkipsh {
        width: 200px;
        margin-top: 70px;
        display: none;
    }
    .footer-img {
        display: none;
    }
    .txtmillionother {
        bottom: 21px;
    }
    .txtmillionofark {
        bottom: 28px;
    }
    .gltMei {
        width: unset;
        font-size: var(--font-size-xs);
    }
    .hpiEQ {
        font-size: var(--font-size-l);
        font-weight: 900;
        font-style: normal;
        margin-top: -1px;
        line-height: 30px;
    }
    .inter-black-alto-32px {
        font-size: var(--font-size-l) !important;
    }
    .kAIfLV {
        width: 88%;
        margin: auto;
        padding: 0;
    }
    .gIUHqg {
        left: 0;
    }
    .hWpUlW {
        font-size: var(--font-size-l);
    }
    .icon_footer {
        justify-content: inherit;
        display: flex;
        height: 150px;
        margin-left: 0;
        margin: auto;
    }
    .frame_footer {
        display: block;
    }
    .group-9-7oP3YL {
        text-align-last: center;
    }
    .menu-items-iEyiFd {
        justify-content: center;
        display: grid;
        grid-template-columns: auto auto auto;
        margin-top: 50px;
    }
    .group-iEyiFds {
        position: absolute;
        bottom: -10px;
        width: 100%;
        text-align: center;
    }
    .w-6 {
        width: 30px;
        height: 30px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .imgbutton {
        height: 25px;
        padding: 7px 10px;
        font-size: 11px;
    }
    .line-height-home {
        line-height: 25px;
        margin-top: 10px;
    }
    .width-screen {
        width: 80%;
        margin: auto;
    }
    .background-involved-swap {
        visibility: hidden;
        display: none;
    }
    .involved {
        gap: 20px;
    }
    .div-scroll {
        visibility: hidden;
    }
    .padding-min-swap {
        padding: 30px 25px;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .involved {
        gap: 30px;
        border: 1px none;
        width: 90%;
        margin: 100px auto;
        height: 500px;
    }
    .involved-div {
        display: flex;
        flex-direction: column;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        align-items: center;
        border: 1px none;
        margin-top: 330px;
    }
    .involved-txt {
        font-size: var(--font-size-xl);
        line-height: 60px;
    }
    .involved-div-span {
        font-size: var(--font-size-m);
        text-align: center;
        letter-spacing: 0px;
        white-space: nowrap;
    }
    .div-scroll {
        position: unset !important;
        top: 0 !important;
        margin: -50px 0px 90px 0px;
    }
    .txt-banner-tt {
        margin-left: 50px;
        width: 55%;
    }
    .line-height-home {
        line-height: 50px;
    }
    .h3-home {
        font-size: var(--font-size-xl);
        font-weight: 900;
        line-height: 52px;
    }
    .background-involved {
        /* width: 85%; */
        position: -webkit-sticky;
        position: sticky;
        margin: auto;
        margin-top: -400px;
        margin-bottom: -250px;
    }
    .w-6 {
        width: 40px;
        height: 40px;
        margin-left: 50px;
    }
    .mb-5s {
        margin-bottom: 40px;
    }
    .icon_footer {
        justify-content: flex-end;
        display: flex;
    }
    .frame_footer {
        display: block;
    }
    .frame_footers {
        display: flex;
    }
    .group-9-7oP3YL {
        text-align-last: center;
    }
    .menu-items-iEyiFd {
        justify-content: center;
        margin-top: 30px;
    }
    .size-hearder {
        min-height: 500px;
    }
    .intro-swap {
        font-weight: 800;
        font-size: var(--font-size-xs);
        letter-spacing: 0.9px;
    }
    .comunioner {
        font-size: var(--font-size-xl);
        line-height: 50px;
    }
    .use-by {
        margin-top: -20px;
        gap: 100px;
        border: 1px none;
        height: unset;
        padding: 0px 50px 10px;
    }
    .div_millions {
        font-size: var(--font-size-xl);
        font-weight: 900;
        margin-top: -1px;
        line-height: 40px;
    }
    .div-millions-colum {
        display: contents;
    }
    .statistical-div {
        display: grid;
        grid-template-columns: 33% 33% 33%;
    }
    .mt-screen {
        margin-top: 100px;
    }
    .statistical {
        margin-top: 120px;
    }
    .color-earn {
        font-size: var(--font-size-xl);
    }
    .color-trade {
        font-size: var(--font-size-xl);
    }
    .image-million {
        width: 400px;
        margin: auto;
    }
    .hpiEQ {
        font-size: 22px;
    }
    .kAIfLV {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 0;
        align-self: stretch;
        border: 1px none;
        z-index: 9;
        width: 90%;
        margin-left: 5%;
    }
    .txtmillionuser {
        bottom: 21px;
    }
    .txtmillionother {
        bottom: 8px;
    }
    .footer-img {
        position: absolute;
        width: 150px;
        left: 22px;
        object-fit: cover;
        z-index: 10;
        top: 268px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    .txtmillionother {
        bottom: 8px;
    }
    .div-scroll {
        position: unset !important;
        top: 0 !important;
        margin: -130px 0px 90px 0px;
    }
    .txtmillionuser {
        bottom: 21px;
    }
    .txt-banner-tt {
        margin-left: 50px;
        width: 50%;
    }
    .line-height-home {
        line-height: 50px;
    }
    .h3-home {
        font-size: var(--font-size-xl);
        font-weight: 900;
        line-height: 52px;
    }
    .background-involved {
        width: 85%;
        position: -webkit-sticky;
        position: sticky;
        margin: auto;
        margin-top: -400px;
        margin-bottom: -200px;
    }
    .w-6 {
        width: 40px;
        height: 40px;
        margin-left: 50px;
    }
    .mb-5s {
        margin-bottom: 40px;
    }
    .group-9-7oP3YL {
        text-align-last: center;
    }
    .menu-items-iEyiFd {
        justify-content: center;
        margin-top: 30px;
    }
    .size-hearder {
        min-height: 500px;
    }
    .intro-swap {
        font-weight: 800;
        font-size: var(--font-size-xs);
        letter-spacing: 0.9px;
    }
    .comunioner {
        font-size: var(--font-size-xl);
        line-height: 50px;
    }
    .use-by {
        margin-top: -20px;
        gap: 100px;
        border: 1px none;
        height: unset;
        padding: 0px 50px 10px;
    }
    .div_millions {
        font-size: var(--font-size-xl);
        font-weight: 900;
        margin-top: -1px;
        line-height: 40px;
    }
    .div-millions-colum {
        display: contents;
    }
    .statistical-div {
        display: grid;
        grid-template-columns: 33% 33% 33%;
    }
    .mt-screen {
        margin-top: 100px;
    }
    .statistical {
        margin-top: 120px;
    }
    .color-earn {
        font-size: var(--font-size-xl);
    }
    .color-trade {
        font-size: var(--font-size-xl);
    }
    .image-million {
        width: 400px;
        margin: auto;
    }
    .hpiEQ {
        font-size: 22px;
    }
    .kAIfLV {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 0;
        align-self: stretch;
        border: 1px none;
        z-index: 9;
        width: 90%;
        margin-left: 5%;
    }
    .footer-img {
        position: absolute;
        width: 150px;
        left: 22px;
        object-fit: cover;
        z-index: 10;
        top: 268px;
    }
}


/*------------------css button-----------------------*/

.arrowBg {
    background-color: var(--supernova);
    padding: 2px;
    -webkit-clip-path: polygon( 0px 0%, calc(100% - 15px) 0%, 100% 12px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px));
    clip-path: polygon( 0px 0%, calc(100% - 15px) 0%, 100% 12px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px));
    border-radius: 5px;
    cursor: pointer;
    border-radius: 11px;
}

.arrowLeft {
    background: var(--colors-backgroundBtn);
    padding: 10px 15px;
    /* clip-path: polygon( 0px 0%, calc(100% - 15px) 0%, 100% 12px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px)); */
    border-radius: 3px;
    display: block;
    color: var(--colors-text);
    font-weight: 600;
    border-radius: 11px;
    color: black;

}

.arrowBg-close {
    background-color: var(--supernova);
    padding: 2px;
    -webkit-clip-path: polygon( 0px 0%, calc(100% - 10px) 0%, 100% 5px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px));
    clip-path: polygon( calc(36% - 0px) 0%, 100% 0px, calc(100% - 0px) 0%, 100% 0px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0px 100%, -15px calc(100% - 0px));
    border-radius: 4px;
    cursor: pointer;
}

.arrowLeft-close {
    background: var(--colors-backgroundBtn);
    padding: 2px 5px;
    -webkit-clip-path: polygon( calc(36% - 0px) 0%, 100% 0px, calc(100% - 0px) 0%, 100% 0px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0px 100%, -12px calc(100% - 0px));
            clip-path: polygon( calc(36% - 0px) 0%, 100% 0px, calc(100% - 0px) 0%, 100% 0px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 0px 100%, -12px calc(100% - 0px));
    border-radius: 2px;
    display: block;
    color: var(--colors-text);
    font-weight: 600;
    font-size: 12px;
}

.dzZDKb {
    background-color: var(--supernova);
    padding: 2px;
    -webkit-clip-path: polygon( 0px 0%, calc(100% - 15px) 0%, 100% 12px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px));
    clip-path: polygon( 0px 0%, calc(100% - 15px) 0%, 100% 12px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px));
    border-radius: 5px;
    cursor: pointer;
}

.efayAw {
    background: var(--colors-backgroundBtn);
    -webkit-clip-path: polygon( 0px 0%, calc(100% - 15px) 0%, 100% 12px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px));
            clip-path: polygon( 0px 0%, calc(100% - 15px) 0%, 100% 12px, 100% calc(100% - 0px), calc(100% - 0px) 100%, 20px 100%, 0 calc(100% - 0px));
    border-radius: 3px !important;
    display: block;
    color: var(--colors-text);
    font-weight: 600;
}

.fqCwAG {
    border-radius: 3px 0px 0px 3px !important;
}

.dnCYXW {
    border-radius: 3px 0px 0px 3px !important;
}

.iuGqpY {
    border-radius: 3px 0px 0px 3px !important;
}


/*--------------------scroll explore--------------------*/

.txt-scroll {
    text-align: center;
    margin-top: 20px;
}

.div-scroll {
    position: relative;
    top: -200px;
}

.scroll-down {
    height: 62px;
    padding: 10px;
    align-items: flex-start;
    min-width: 38px;
    background-color: var(--licorice);
    border-radius: 10px;
    border: 7px solid;
    border-color: #1d181d;
    width: 40px;
    margin: auto;
}

.scroll-down::before {
    -webkit-animation: scrollDownAnimation 3s infinite;
            animation: scrollDownAnimation 3s infinite;
    background-color: var(--supernova);
    border-radius: 10px;
    content: '';
    height: 12px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 6px;
}

@-webkit-keyframes scrollDownAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0;
        -webkit-transform: translate(0, 20px);
                transform: translate(0, 20px);
    }
    100% {
        opacity: 0;
    }
}

@keyframes scrollDownAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0;
        -webkit-transform: translate(0, 20px);
                transform: translate(0, 20px);
    }
    100% {
        opacity: 0;
    }
}

.MuiPaginationItem-root {
    color: white !important;
}

.css-fvipm8 {
    color: unset;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: unset !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: unset !important;
}
